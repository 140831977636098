import React, { Component } from 'react'
import { Modal, message, Select } from 'antd'
import Fetch from '../../../fetch/axios'
import './index.css'
class index extends Component {
  state = {
    templateList: [],
    selectedItems: []
  }
  // 获取未绑定部门的模板
  getTemplateById = () => {
    Fetch.getTemplateListByUnitIdExclude({
      archivingUnitId: this.props.unitId
    })
      .then(res => {
        if (res.code === 0) {
          this.setState({
            templateList: res.data
          })
        } else {
          message.error('获取模板失败')
          this.props.onCancel()
        }
      })
      .catch(err => {
        console.log(err)
        message.error('获取模板失败')
        this.props.onCancel()
      })
  }
  handleChange = items => {
    this.setState({
      selectedItems: items
    })
  }
  componentDidMount() {
    this.getTemplateById()
  }
  render() {
    return (
      <Modal
        visible={this.props.visible}
        onOk={() => {
          this.props.onOk({
            ids: this.state.selectedItems
          })
        }}
        onCancel={this.props.onCancel}
        title='绑定模板'
      >
        <Select
          style={{ width: '100%' }}
          mode='multiple'
          onChange={this.handleChange}
        >
          {this.state.templateList.map(item => (
            <Select.Option value={item.Id} key={item.Name}>
              {item.Name}
            </Select.Option>
          ))}
        </Select>
      </Modal>
    )
  }
}
export default index
