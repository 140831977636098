/*
 * Project: project
 * File Created: 2019-08-06
 * Author: zhoud
 * Last Modified: 2019-08-06
 * Modified By: zhoud
 * Desc:二级路由承载页面
 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import MainMenu from '../components/Index/MainMenu'
import UserInfo from '../components/Index/UserInfo'
import Logo from '../components/Index/Logo'
import NavBar from '../components/Index/NavBar'
import { Spin } from 'antd'
export class Index extends Component {
  render() {
    // 根据菜单是否收起特殊化的菜单宽度样式
    const menuExtractStyle = this.props.menuExpandState
      ? {}
      : { width: '10%', minWidth: '200px' }
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '100%'
        }}
      >
        {/* 用户信息 */}
        {this.props.userinfoDisplayState ? (
          <div style={{ background: '#252627', height: '150px' }}>
            <UserInfo />
          </div>
        ) : null}
        <div
          style={{
            flexGrow: '1',
            display: 'flex',
            width: '100%',
            height: '100%'
          }}
        >
          {/* logo和菜单 */}
          <div
            style={{
              height: '100%',
              overflow: 'auto',
              background: '#001529',
              ...menuExtractStyle
            }}
            className="menu-background"
          >
            <Logo />
            <MainMenu />
          </div>
          {/* 内容区 */}
          <div
            style={{
              background: '#eeeeee',
              flexGrow: '1',
              padding: '20px',
              overflow: 'auto'
            }}
            className="main-content"
          >
            <div className="navbar">
              <NavBar />
            </div>
            <Spin spinning={this.props.spinning}>
              <div>{this.props.children}</div>
            </Spin>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  // 是否显示用户信息
  userinfoDisplayState: state.global.userinfoDisplayState,
  // 是否展开菜单
  menuExpandState: state.global.menuExpandState,
  spinning: state.global.spinning
})

const mapDispatchToProps = {}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Index)
