/*
 * Project: project
 * File Created: 2019-08-06
 * Author: zhoud
 * Last Modified: 2019-08-06
 * Modified By: zhoud
 * Desc: 路由组件
 */
import React, { Component } from 'react'
import { HashRouter, Route, Redirect } from 'react-router-dom'
import Login from './view/Login'
import About from './view/About'
import RequestDetail from './view/RequestDetail'
import Index from './view/Index'
import userMenu from './fetch/mock/userMenu'
export default class router extends Component {
  render() {
    return (
      <HashRouter>
        <Route exact path="/" render={() => <Redirect to="/login" />} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/about" component={About} />
        <Route exact path="/detail" component={RequestDetail} />
        <Route
          path="/index"
          render={() => {
            const menuData = userMenu.data
            const pagePower = sessionStorage.getItem('pagePower') || ''
            const pagePowerArr = pagePower.split(',')
            const filteredMenuData = menuData.filter(
              item => pagePowerArr.indexOf(item.key) !== -1
            )
            return (
              <Index>
                {filteredMenuData.map(item => (
                  <Route
                    exact
                    path={item.key}
                    component={item.component}
                    key={item.key}
                  />
                ))}
              </Index>
            )
          }}
        />
      </HashRouter>
    )
  }
}
