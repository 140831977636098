// 获取地址列表
export const GET_ADDRESS_LIST = 'GET_ADDRESS_LIST'
// 设置地址列表
export const SET_ADDRESS_LIST = 'SET_ADDRESS_LIST'
// 设置当前选择的地点的属性值
export const SET_SELECTED_ADDRESS_INFO = 'SET_SELECTED_ADDRESS_INFO'
// 获取档案架信息
export const GET_SHELF_LIST = 'GET_SHELF_LIST'
// 设置档案架信息列表
export const SET_SHELF_LIST = 'SET_SHELF_LIST'
// 获取当前选择的档案架的信息
export const GET_SELECTED_SHELF_INFO = 'GET_SELECTED_SHELF_INFO'
// 设置当前选择的档案架的信息
export const SET_SELECTED_SHELF_INFO = 'SET_SELECTED_SHELF_INFO'
// 获取档案架单元格信息
export const GET_SHELF_CELL_LIST = 'GET_SHELF_CELL_LIST'
// 设置档案架单元格信息
export const SET_SHELF_CELL_LIST = 'SET_SHELF_CELL_LIST'
// 获取当前的tab的key值
export const GET_TAB_KEY = 'GET_TAB_KEY'
// 设置当前tab的key值
export const SET_TAB_KEY = 'SET_TAB_KEY'
// 单元格编辑模态框是否可见
export const SET_SHELF_CELL_EDIT_MODAL_VISIBLE =
  'SET_SHELF_CELL_EDIT_MODAL_VISIBLE'
// 当前编辑单元格信息
export const SET_EDITING_SHELF_CELL_INFO = 'SET_EDITING_SHELF_CELL_INFO'
//  当前table总页数
export const SET_ROWCOUNT = 'SET_ROWCOUNT'
//  当前table页号
export const SET_PAGEINDEX = 'SET_PAGEINDEX'
export const RESET_PAGEINDEX = 'RESET_PAGEINDEX'
