import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Button, Card, Form, Input, message, Modal, Table, Upload} from 'antd'
import Fetch from '../../../fetch/axios'
import * as docActions from '../../../store/documentinfo/actions'
import * as globalActions from '../../../store/global/actions'
import './index.css'
import BorrowModal from '../BorrowModal'
import HistoryModal from '../HistoryModal'
import DocEditModal from '../DocEditModal'
import moment from 'moment'

export class index extends Component {

  state = {
    // 搜索框
    showSearchModal: false,
    // 调阅请求模态框
    showBorrwowRequestModal: false,
    selectedRowKeys:[],
    // 勾选文档的id数组
    selectedDocIds: [],
    // 打开调阅历史模态框
    showHistoryModal: false,
    // 调阅历史数据
    HistoryListData: [],
    // 调阅历史总页数
    HistoryListPageCount: -1,
    // 调阅历史当前页数
    HistoryListPageIndex: 1,
    // 当前调阅请求文档id
    HistoryListId: '',
    // 档案单条数据编辑模态框
    showDocEditModal: false,
    // 当前编辑的文档的元数据
    itemData: {},
    // 导入模态框
    showImportModal: false,
    // 安全导入模态框
    showSafeImportModal: false,
    // 强制导入模态框
    showForceImportModal: false,
  }

  // 调阅请求模态框
  openBorrowRequestModal = () => {
    this.setState({
      showBorrwowRequestModal: true,
    })
  }

  // 关闭调阅请求模态框
  closeBorrowRequestModal = () => {
    this.setState({
      showBorrwowRequestModal: false,
    })
  }

  // 打开调阅历史模态框
  openHistoryModal = (id) => {
    Fetch.getHistoryList({
      archBaseInfoId: id,
      pageIndex: this.state.HistoryListPageIndex,
    })
      .then((res) => {
        if (res.success === true) {
          const HistoryListData = res.data.rows
          const HistoryListPageCount = res.data.pageCount
          this.setState({
            showHistoryModal: true,
            HistoryListId: id,
            HistoryListData,
            HistoryListPageCount,
          })
        } else {
          message.error('获取调阅历史数据失败')
        }
      })
      .catch((err) => {
        console.log(err)
        message.error('获取调阅历史数据失败')
      })
  }

  // 关闭调阅历史模态框
  closeHistoryModal = () => {
    this.setState({
      showHistoryModal: false,
    })
  }

  // 调阅历史翻页
  historyGoToPage = (page) => {
    Fetch.getHistoryList({
      archBaseInfoId: this.state.HistoryListId,
      pageIndex: Math.min(page, this.state.HistoryListPageCount),
    })
      .then((res) => {
        if (res.success === true) {
          const HistoryListData = res.data.rows
          const HistoryListPageCount = res.data.pageCount
          this.setState({
            HistoryListData,
            HistoryListPageCount,
            HistoryListPageIndex: page,
          })
        } else {
          message.error('获取调阅历史数据失败')
        }
      })
      .catch((err) => {
        console.log(err)
        message.error('获取调阅历史数据失败')
      })
  }

  beforeUpload = (file) => {
    // 限制文件类型为Excel
    const isExcel = file.name.endsWith('.xlsx') || file.name.endsWith('.xls')|| file.name.endsWith('.csv');
    if (!isExcel) {
      message.error('只能导入Excel文件!');
      return isExcel;
    }
    // 限制文件大小不超过100MB
    const isLt100M = file.size / 1024 / 1024 < 100;
    if (!isLt100M) {
      message.error('文件太大，不能超过100MB!');
      return isLt100M;
    }
    const result = isExcel && isLt100M;
    this.props.setSpinning(result);
    return result;
  };

  // 上传完成回调
  onUploadChange = (info) => {
    if (info.file.status === 'done' || info.file.status === 'error')
      this.props.setSpinning(false);
    if (info.file.response && info.file.response.code === 0) {
      this.props.getDocList(this.props.thisTemplateId, this.props.setSpinning);
      message.success(info.file.response.message);
    } else if (info.file.response && info.file.response.code === 500) {
      message.error(info.file.response.message);
    } else if (info.file.response && info.file.response.error) {
      if (info.file.response.message == null)
        info.file.response.message = 'No Message.';
      message.error(`${info.file.response.status} ${info.file.response.error}, ${info.file.response.message}`)
    } else if (info.file.status === 'error') {
      message.error('网络错误, 上传失败。（提示：可能文件超过限制大小）');
    }
  }

  // 删除文档
  deleteDocItem = (id) => {
    const _this = this
    Modal.confirm({
      title: '警告！',
      content: '确定要删除此条文档么？',
      okText: '是',
      okType: 'danger',
      cancelText: '否',
      onOk() {
        Fetch.deleteDocItem([id], _this.props.thisTemplateId).then((res) => {
          _this.props.getDocList({Id: _this.props.thisTemplateId}, this.props.setSpinning)
        })
      },
    })
  }

  // 提交调阅请求
  submitBorrowRequest = (data) => {
    if (this.state.selectedDocIds.length === 0) {
      return message.error('请勾选需要调阅的文档')
    }
    const formData = data
    // 调阅到期时间
    formData['ExpireDate'] = formData['ExpireDate'].format(
      'YYYY-MM-DD HH:mm:ss'
    )
    formData['CreateTime'] = moment().format('YYYY-MM-DD HH:mm:ss')
    formData['Status'] = 0
    formData['UnitId'] = this.props.thisUnitId
    formData['filePath'] = Array.isArray(formData['filePath'])
      ? formData['filePath'].map((item) => {
        if (item && item.response && item.response.code === 0) {
          return item.response.data;
        } else {
          return ''
        }
      })
      : undefined
    formData['archivalIds'] = this.state.selectedDocIds
    formData['templateId'] = this.props.thisTemplateId
    Fetch.createBorrowRequest(formData)
      .then((res) => {
        if (res.code === 0) {
          message.success('调阅请求成功')
          this.setState({
            showBorrwowRequestModal: false,
            selectedRowKeys: []
          })
        } else {
          message.error('调阅请求失败')
        }
      })
      .catch((err) => {
        console.log(err)
        message.error('调阅请求失败')
      })
  }

  // 档案单条数据编辑
  openDocEditModal = (record) => {
    this.setState({
      showDocEditModal: true,
      itemData: record,
    })
  }

  // 关闭编辑模态框
  closeEditModal = () => {
    console.log(123)
    this.setState({
      showDocEditModal: false,
      itemData: {},
    })
  }

  // 提交文档编辑
  submitDocEdit = (data) => {
    const _this = this
    const formData = data
    formData.Plane = data.Plane === 0 ? 'A面' : 'B面'
    Fetch.updateDocItem({
      id: this.state.itemData.Id,
      shelfCellId: this.state.itemData.ShelfCellId,
      templateId: this.props.thisTemplateId,
      ...formData,
    })
      .then((res) => {
        if (res.success === true) {
          message.success('编辑档案成功')
          _this.closeEditModal()
          _this.props.getDocList(_this.props.thisTemplateId, this.props.setSpinning)
        }
      })
      .catch((err) => {
        console.log(err)
        message.error(err.message)
      })
  }

  // 批量删除勾选的文档
  batchDelete = () => {
    const _this = this
    const ids = this.state.selectedDocIds
    Modal.confirm({
      content: '确认删除勾选的文档?',
      onOk: () => {
        Fetch.deleteDocItem(ids, _this.props.thisTemplateId).then((res) => {
          if (res.code === 0) {
            _this.props.getDocList(_this.props.thisTemplateId, this.props.setSpinning)
          }
        })
      },
    })
  }

  render() {
    const _this = this;
    const rowSelection = {
      selectedRowKeys: this.state.selectedRowKeys,
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({
          selectedDocIds: selectedRows.map((item) => item.Id),
          selectedRowKeys: selectedRowKeys
        })
      },
    }
    const columns = [].concat(JSON.parse(JSON.stringify(this.props.columns)))
    const {getFieldDecorator, getFieldsValue} = this.props.form
    columns.push({
      title: '位置',
      dataIndex: 'pName',
      render: (text, record) =>  record.ShelfCellId ? (
        <span>
          {record.pName} {record.gName} {record.adName} {record.sbName}{' '}
          {record.Row}行 {record.Column}列
        </span>
      ) : (<span>{record.Location}</span>),
      align: 'center',
    })
    columns.push({
      title: '占用空间',
      dataIndex: 'TakeUpSpace',
      key: 'TakeUpSpace',
      align: 'center',
    })
    columns.push({
      title: '识别码',
      dataIndex: 'QrCode',
      key: 'QrCode',
      align: 'center',
    })
    columns.push({
      title: '架码',
      dataIndex: 'rackCode',
      key: 'rackCode',
      align: 'center',
    })
    columns.push({
      title: '状态',
      dataIndex: 'Status',
      render: (status, record) => {
        const meanMap = {
          '-7': '已销毁',
          '-6': '作废(删除)',
          '-4': '无法归还',
          '3': '已借出',
          '4': '在库(已上架)',
          '5': '已下架',
        }
        return <span>{meanMap[status + '']}</span>
      },
      align: 'center',
    })
    columns.push({
      title: '操作',
      render: (text, record) => (
        <div>
          {this.props.userType < 2 ? (
            <span style={{padding: '5px', color: 'blue', cursor: 'pointer'}}
                  onClick={() => this.openDocEditModal(record)}>
              编辑
            </span>) : null}
          {this.props.userType < 2 ? (
            <span style={{padding: '5px', color: 'blue', cursor: 'pointer'}}
                  onClick={() => this.deleteDocItem(record.Id)}>
              删除
            </span>) : null}
          <span style={{padding: '5px', color: 'blue', cursor: 'pointer'}}
                onClick={() => this.openHistoryModal(record.ArchivesBaseId)}>
            调阅历史
          </span>
        </div>
      ),
      fixed: 'right',
      align: 'center',
    })
    return (
      <div style={{margin: '10px'}}>
        <Card title={this.props.thisUnitName}>
          <Card.Meta title={this.props.thisTemplateName}/>
          <div className="toolbar" style={{margin: '15px 0'}}>
            <Input.Search placeholder="请输入关键字"
                          onSearch={(value) => {
                            const setSpinning = this.props.setSpinning;
                            this.props.setSearchForm({
                              Id: this.props.thisTemplateId,
                              keyword: value,
                              form: {},
                              setSpinning
                            })
                          }}
                          style={{width: 250, margin: '0px 10px 0 0'}}
                          enterButton />
            <Button type="primary"
                    onClick={() => { this.setState({showSearchModal: true}) }}>
              高级检索
            </Button>
            {this.props.userType < 2 ? (
            <Button className="btn"
                    type={this.state.selectedDocIds.length <= 0 ? null : "danger"}
                    disabled={this.state.selectedDocIds.length<=0}
                    style={{margin: '5px 5px', float: 'right'}}
                    onClick={() => { this.batchDelete()}}>
              批量删除
            </Button>) : null}
            <Button className="btn"
                    type="primary"
                    style={{margin: '5px 5px', float: 'right'}}
                    onClick={() =>
                    {
                      console.log('id:', _this.props.thisTemplateId)
                      Fetch.exportDocExcel({templateId: _this.props.thisTemplateId})
                           .then((res) =>
                           {
                              const blob = new Blob([res])
                              const blobUrl = window.URL.createObjectURL(blob)
                              const a = document.createElement('a')
                              a.download = `doc${new Date().getTime()}.xls`
                              a.href = blobUrl
                              a.click()
                           })
                    }}>
              导出
            </Button>
            {this.props.userType < 2 ? (<Button type="primary" style={{margin: '5px 5px', float: 'right'}} onClick={()=>{ this.setState({showImportModal: true}); }}>导入</Button>) : null}
            <Button className="btn"
                    type={this.state.selectedDocIds.length <= 0 ? null : "primary"}
                    disabled={this.state.selectedDocIds.length<=0}
                    style={{margin: '5px 5px', float: 'right'}}
                    onClick={this.openBorrowRequestModal}>
              调阅
            </Button>
            <Button className="btn"
                    type="primary"
                    style={{margin: '5px 5px', float: 'right'}}
                    onClick={() =>
                    {
                      const setSpinning = this.props.setSpinning;
                      this.props.setPageIndex(
                      {
                        pageIndex: this.props.pageIndex,
                        keyword: this.props.docKeyword,
                        form: {
                          keyWord: '',
                          form: {},
                          Id: ''
                        },
                        Id: this.props.thisTemplateId,
                        setSpinning
                      })
                    }}>
              重置搜索
            </Button>
          </div>
          <Table rowSelection={rowSelection}
                 columns={columns}
                 dataSource={this.props.dataSource}
                 bordered={true}
                 rowKey={(item) => item.Id}
                 scroll={{x: 'max-content'}}
                 pagination={{
                   total: this.props.docTableRowCount,
                   pageSize: 20,
                   onChange: (page) => {
                     const setSpinning = this.props.setSpinning;
                     this.props.setPageIndex({
                       pageIndex: page,
                       keyword: this.props.docKeyword,
                       form: this.props.docForm,
                       Id: this.props.thisTemplateId,
                       setSpinning
                     });
                   },
                 }}
          />
        </Card>
        {this.state.showBorrwowRequestModal ? (
          <BorrowModal visible={this.state.showBorrwowRequestModal}
                       onCancel={this.closeBorrowRequestModal}
                       onOk={(data) => this.submitBorrowRequest(data)} />) : null}
        {this.state.showSearchModal ? (
          <Modal visible={this.state.showSearchModal}
                 onOk={() =>
                 {
                    const values = getFieldsValue();
                    const setSpinning = this.props.setSpinning;
                    this.props.setSearchForm({
                      Id: this.props.thisTemplateId,
                      keyWord: '',
                      form: values,
                      setSpinning
                    });
                    this.setState({
                      showSearchModal: false,
                    });
                  }}
                 onCancel={() => {
                   this.setState({showSearchModal: false})
                 }}
                 title="高级搜索">
            <Form>
              {this.props.columns.map((item) => (
                <Form.Item label={item.title}
                          key={item.dataIndex}>
                  {getFieldDecorator(item.dataIndex, { initialValue: this.props.docForm[item.dataIndex] || ''})(<Input allowClear={true} />)}
                </Form.Item>
              ))}
            </Form>
          </Modal>
        ) : null}
        {this.state.showHistoryModal ? (
          <HistoryModal visible={this.state.showHistoryModal}
                        onCancel={this.closeHistoryModal}
                        data={this.state.HistoryListData}
                        goToPage={(page) => {
                          this.historyGoToPage(page)
                        }}
                        totalPage={this.state.HistoryListPageCount} />) : null}
        {this.state.showDocEditModal ? (
          <DocEditModal visible={this.state.showDocEditModal}
                        itemData={this.state.itemData}
                        template={this.props.columns}
                        onCancel={this.closeEditModal}
                        onOk={this.submitDocEdit}/>) : null}
        <Modal visible={this.state.showImportModal} centered={true}
               okButtonProps={{ style: { display: 'none' } }}
               onCancel={() => { this.setState({showImportModal: false}); }}
               title="导入">
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Button onClick={()=>{ this.setState({showImportModal: false, showSafeImportModal: true}); }} style={{background: '#07ba2e', color: '#ffffff', marginRight: '25px'}}>安全导入</Button>
            <Button onClick={()=>{ this.setState({showImportModal: false, showForceImportModal: true}); }} style={{marginLeft: '25px'}} type="danger">强制导入</Button>
          </div>
        </Modal>
        <Modal visible={this.state.showSafeImportModal} centered={true} title="询问?"
               onCancel={() => { this.setState({showSafeImportModal: false}); }}
               footer={[
                <Button onClick={() => { this.setState({showSafeImportModal: false, showImportModal: true}); }}>返回</Button>,
                <Upload action={`${window.serverIp}:${window.serverPort}/api/v1/archival/importExcel?departmentId=162557dc943c4913ad204a9db7975f3a&tableName=${this.props.thisTemplateId}`}
                        withCredentials={true}
                        accept=".xls,.xlsx,.csv"
                        beforeUpload={this.beforeUpload}
                        onChange={this.onUploadChange}>
                  <Button style={{background: '#07ba2e', color: '#ffffff', marginLeft: '15px'}}
                          onClick={()=>{ this.setState({showSafeImportModal: false}); }}>导入</Button>
                </Upload>]}>
            <p style={{color: "#07ba2e"}}>使用安全导入，将验证档案存放位置，位置信息不正确将无法导入。</p>
            <p style={{color: "#07ba2e"}}>确认使用安全导入吗？</p>
        </Modal>
        <Modal visible={this.state.showForceImportModal} centered={true} title="询问?"
               onCancel={() => { this.setState({showForceImportModal: false}); }}
               footer={[
                <Button onClick={() => { this.setState({showForceImportModal: false, showImportModal: true}); }}>返回</Button>,
                <Upload action={`${window.serverIp}:${window.serverPort}/api/v1/archival/importExcel?departmentId=162557dc943c4913ad204a9db7975f3a&forceImport=true&tableName=${this.props.thisTemplateId}`}
                        withCredentials={true}
                        accept=".xls,.xlsx,.csv"
                        beforeUpload={this.beforeUpload}
                        onChange={this.onUploadChange}>
                  <Button style={{background: '#fc0000', color: '#ffffff', marginLeft: '15px'}}
                          onClick={()=>{ this.setState({showForceImportModal: false}); }}>导入</Button>
                </Upload>]}>
                <div style={{color: "#fc0000"}}>
                  <p>使用强制导入，不会验证存放位置，未来无法统计存放空间使用情况。</p>
                  <p>确认使用强制导入吗？</p>
                </div>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    // 当前模板名称
    thisTemplateName: state.unit.templateName,
    // 当前模板id
    thisTemplateId: state.unit.templateId,
    // 当前存档单位名称
    thisUnitName: state.unit.unitName,
    // 当前单位id
    thisUnitId: state.unit.unitId,
    // 表格结构
    columns: state.unit.columns,
    // 表格数据源
    dataSource: state.docinfo.docList,
    // 用户的type
    userType: state.global.userInfo.userRoles[0].Type,
    docTableRowCount: state.docinfo.docTableRowCount,
    docTablePageIndex: state.docinfo.docTablePageIndex,
    docKeyword: state.docinfo.searchForm.keyWord,
    docForm: state.docinfo.searchForm.form,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getDocList: (id, setSpinning) => {
      dispatch(docActions.getDocList({Id: id, keyWord: '', form: {}, setSpinning}))
    },
    setSearchForm: (form) => {
      dispatch(docActions.setSearchForm(form))
    },
    setPageIndex: (index) => {
      dispatch(docActions.setPageIndex(index))
    },
    setSpinning: state => {
      dispatch(globalActions.setSpinning(state))
    },
    clearSearchForm: () => {
      dispatch(docActions.clearSearchForm())
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create('search-form')(index))
