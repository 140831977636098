/*
 * Project: project
 * File Created: 2019-09-23
 * Author: zhoud
 * Last Modified: 2019-09-23
 * Modified By: zhoud
 * Desc: 档案架信息表格
 */
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Table, Card, Icon, Tag, Progress, Button, message, Modal } from 'antd'
import ShelfFormModal from '../ShelfFormModal'
import ShelfCellModal from '../ShelfCellTab'
import ShelfCellEditModal from '../ShelfCellEditModal'
import ShelfMassAddModal from '../ShelfMassAddModal'
import Fetch from '../../../fetch/axios'
import * as addressActions from '../../../store/address/actions'
import './index.css'
export class index extends Component {
  state = {
    // 档案架表单模态框
    showShelfModal: false,
    // 模态框title
    modalTitle: '',
    // 当前选择的档案架条目
    selectedRows: [],
    // 选择的档案架的信息
    selectedObjItem: {},
    // 显示批量添加模态框
    showShelfMassAddModal: false,
    // 当前页数
    currentPageIndex: 1,
    // 当前总页数
    pageCount: -1,
  }
  columns = [
    {
      title: '档案架名称',
      dataIndex: 'Name',
      key: 'name',
      align: 'center',
    },
    {
      title: '档案架AB面',
      render: (record) => <span>{record.Plane === 0 ? 'A面' : 'B面'}</span>,
      align: 'center',
    },
    {
      title: '所属地',
      dataIndex: 'addressName',
      key: 'addressName',
      align: 'center',
    },
    {
      title: '状态',
      render: (record) => (
        <div style={{ textAlign: 'center' }}>
          {record.Status === -1 ? (
            <Tag color="red">禁用</Tag>
          ) : (
            <Tag color="blue">正常</Tag>
          )}
        </div>
      ),
      align: 'center',
    },
    {
      title: '操作',
      render: (record) => (
        <div
          style={{ textAlign: 'center', cursor: 'pointer', color: 'blue' }}
          onClick={() => this.switchToCellInfoTab(record)}
        >
          单元格信息
        </div>
      ),
      align: 'center',
    },
    {
      title: '使用情况',
      render: (record) => (
        <div style={{ alignItems: 'center' }}>
          <div style={{ flexGrow: '1' }}>
            <Progress
              percent={Math.round((record.usageSum / record.capacitySum) * 100)}
              width={Math.round((record.usageSum / record.capacitySum) * 100)}
            />
          </div>
          <div style={{ flexGrow: '1', margin: '5px 0' }}>
            <span>
              {record.usageSum}/{record.capacitySum}
            </span>
          </div>
          {this.props.shelfCellEditModalVisible === true ? (
            <ShelfCellEditModal
              visible={this.props.shelfCellEditModalVisible}
            />
          ) : null}
        </div>
      ),
      align: 'center',
    },
  ]
  // 打开档案架模态框
  openShelfModal = () => {
    this.setState({
      showShelfModal: true,
      modalTitle: '新增档案架',
    })
  }
  // 关闭档案架模态框
  closeShelfModal = () => {
    this.setState({
      showShelfModal: false,
      modalTitle: '',
      selectedObjItem: {},
    })
  }
  // 打开修改模态框
  openEditModal = () => {
    if (this.state.selectedRows.length !== 1) {
      message.error('修改时只能勾选一条密集架信息!')
    } else {
      this.setState({
        showShelfModal: true,
        modalTitle: '修改档案架信息',
        selectedObjItem: this.state.selectedRows[0],
      })
    }
  }
  // 删除档案架信息
  deleteShelfItem = () => {
    const _this = this
    Modal.confirm({
      title: `确定要删除这${this.state.selectedRows.length}条数据么?`,
      onOk() {
        Fetch.deleteShelf(_this.state.selectedRows.map((item) => item.Id)).then(
          (res) => {
            _this.props.getShelfList(
              _this.props.addressInfo['Id'],
              _this.props.addressInfo['Type']
            )
          }
        )
      },
      onCancel() {},
    })
  }
  // 提交数据
  submit = (data) => {
    const { Name = '', Plane = 0, Status = 0 } = data
    const { Id: AddressId, Type } = this.props.addressInfo
    if (Object.keys(this.state.selectedObjItem).length === 0) {
      // 如果是空对象判断新增
      Fetch.addShelf({
        Name,
        Plane,
        AddressId,
        Status,
      }).then((res) => {
        this.props.getShelfList(AddressId, Type)
        this.closeShelfModal()
      })
    } else {
      const { Id } = this.state.selectedObjItem
      // 否则为修改
      Fetch.updateShelf({
        Name,
        Plane,
        AddressId,
        Status,
        Id,
      }).then((res) => {
        this.props.getShelfList(AddressId, Type)
        this.closeShelfModal()
      })
    }
  }
  // 选择框的属性
  rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      this.setState(
        {
          selectedRows,
        },
        function () {
          if (selectedRows.length === 1) {
            this.props.setSelectedShelfInfo(selectedRows[0])
          } else {
            this.props.setSelectedShelfInfo({})
          }
        }
      )
    },
  }
  // 跳转到单元格信息
  switchToCellInfoTab = (record) => {
    this.props.setSelectedShelfInfo(record)
    this.props.getShelfCellList(record.Id)
    this.setState({
      showShelfCellModal: true,
    })
  }
  // 关闭模态框
  closeModal = () => {
    this.setState({
      showShelfCellModal: false,
    })
  }
  // 打开批量添加模态框
  openShelfMassAddModal = () => {
    this.setState({
      showShelfMassAddModal: true,
    })
  }
  // 关闭批量添加模态框
  closeShelfMassAddModal = () => {
    this.setState({
      showShelfMassAddModal: false,
    })
  }
  // 批量添加提交
  submitMassAdd = (data) => {
    const { Id: AddressId, Type } = this.props.addressInfo
    Fetch.massAddShlef({
      addressId: this.props.addressInfo.Id,
      size: data.num,
      col: data.col,
      row: data.row,
      capacity: data.capacity,
      name: data.name || '档案架',
      start: data.start || 1,
    }).then((res) => {
      if (res.success) {
        message.success('批量添加成功')
        this.closeShelfMassAddModal()
        this.props.getShelfList(AddressId, Type)
      }
    })
  }
  render() {
    const { Name = '', Type = '' } = this.props.addressInfo
    const TypeMap = ['逻辑节点', '档案馆', '档案库房']
    const IconMap = ['apartment', 'home', 'shop']
    return (
      <div>
        <Card
          title={
            <Fragment>
              {Type === '' ? null : <Icon type={IconMap[Type]} />}
              <span style={{ padding: '0 10px' }}>{Name}</span>
            </Fragment>
          }
          extra={
            <span style={{ color: '#1890ff', fontSize: '20px' }}>
              {Type !== '' ? TypeMap[Type] : ''}
            </span>
          }
        >
          {Type === '' ? null : (
            <div className="btns">
              <Button
                type="primary"
                style={{ margin: '5px 5px' }}
                disabled={Type !== 2}
                onClick={this.openShelfModal}
              >
                增加
              </Button>
              <Button
                type="primary"
                style={{ margin: '5px 5px' }}
                disabled={Type !== 2}
                onClick={this.openShelfMassAddModal}
              >
                批量增加
              </Button>
              <Button
                type="primary"
                style={{ margin: '5px 5px' }}
                onClick={this.openEditModal}
              >
                修改
              </Button>
              <Button
                type="primary"
                style={{ margin: '5px 5px' }}
                onClick={this.deleteShelfItem}
              >
                删除
              </Button>
            </div>
          )}
          <Table
            dataSource={this.props.shelfList}
            columns={this.columns}
            rowKey={(item) => item.Id}
            rowSelection={this.rowSelection}
            pagination={{
              pageSize: 5,
              current: this.props.pageIndex,
              total: this.props.rowCount,
              onChange: (page) => {
                this.props.setIndex({
                  Id: this.props.addressInfo.Id,
                  Type: this.props.addressInfo.Type,
                  pageIndex: Math.min(page, this.props.rowCount),
                })
              },
            }}
          />
        </Card>
        {this.state.showShelfModal ? (
          <ShelfFormModal
            visible={this.state.showShelfModal}
            title={this.state.modalTitle}
            onCancel={this.closeShelfModal}
            onOk={this.submit}
            data={this.state.selectedObjItem}
          />
        ) : null}
        {this.state.showShelfCellModal ? (
          <ShelfCellModal
            visible={this.state.showShelfCellModal}
            onCancel={this.closeModal}
          />
        ) : null}
        {this.state.showShelfMassAddModal ? (
          <ShelfMassAddModal
            visible={this.state.showShelfMassAddModal}
            onCancel={this.closeShelfMassAddModal}
            onOk={(data) => this.submitMassAdd(data)}
          />
        ) : null}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  // 架体数据
  shelfList: state.address.shelfList,
  // 地点信息
  addressInfo: state.address.selectedAddressInfo,
  // 是否显示单元格编辑模态框
  shelfCellEditModalVisible: state.address.shelfCellEditModalVisible,
  // table的总page数
  rowCount: state.address.shelfTableRowCount,
  // 当前table的页号
  pageIndex: state.address.shelfTablePageIndex,
})

const mapDispatchToProps = (dispatch) => {
  return {
    // 获取档案架信息
    getShelfList: (id, type, pageIndex = 1) => {
      dispatch(addressActions.getShelfList({ Id: id, Type: type, pageIndex }))
    },
    // 获取当前选择的档案架的信息
    setSelectedShelfInfo: (data) => {
      dispatch(addressActions.setSelectedShelfInfo(data))
    },
    // 获取当前档案架的单元格信息
    getShelfCellList: (id) => {
      dispatch(addressActions.getShelfCellList(id))
    },
    // 设置tabkey
    setTabKey: (key) => {
      dispatch(addressActions.setTabKey(key))
    },
    // table翻页
    setIndex: (data) => {
      dispatch(addressActions.setTablePageIndex(data))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(index)
