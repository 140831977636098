import React from 'react'
import { Modal, Form, Input, Select } from 'antd'
const Index = props => {
  const { getFieldDecorator, getFieldsValue } = props.form
  const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 }
  }
  // 提交校验
  const handleSubmit = () => {
    props.form.validateFields((err, values) => {
      if (!err) {
        props.onOk(getFieldsValue())
      }
    })
  }
  return (
    <Modal
      visible={props.visible}
      title="文档编辑"
      onCancel={props.onCancel}
      onOk={() => {
        handleSubmit()
      }}
    >
      <Form {...formItemLayout}>
        <Form.Item label="组织名称">
          {getFieldDecorator('pName', {
            initialValue: props.itemData.pName
          })(<Input />)}
        </Form.Item>
        <Form.Item label="档案馆">
          {getFieldDecorator('gName', {
            initialValue: props.itemData.gName
          })(<Input />)}
        </Form.Item>
        <Form.Item label="库房">
          {getFieldDecorator('adName', {
            initialValue: props.itemData.adName
          })(<Input />)}
        </Form.Item>
        <Form.Item label="档案架">
          {getFieldDecorator('sbName', {
            initialValue: props.itemData.sbName
          })(<Input />)}
        </Form.Item>
        <Form.Item label="面">
          {getFieldDecorator('Plane', {
            initialValue: props.itemData.Plane
          })(
            <Select>
              <Select.Option value={0}>A面</Select.Option>
              <Select.Option value={1}>B面</Select.Option>
            </Select>
          )}
        </Form.Item>
        <Form.Item label="行">
          {getFieldDecorator('Row', {
            initialValue: props.itemData.Row
          })(<Input />)}
        </Form.Item>
        <Form.Item label="列">
          {getFieldDecorator('Column', {
            initialValue: props.itemData.Column
          })(<Input />)}
        </Form.Item>
        <Form.Item label="占用空间">
          {getFieldDecorator('takeUpSpace', {
            initialValue: props.itemData.TakeUpSpace
          })(<Input />)}
        </Form.Item>
        <Form.Item label="识别码">
          {getFieldDecorator('QrCode', {
            rules: [{
              pattern: new RegExp(/^[A].*/),
              message: '识别码必须以A开头',
            }],
            initialValue: props.itemData.QrCode
          })(<Input />)}
        </Form.Item>
        <Form.Item label="架码">
          {getFieldDecorator('rackCode', {
            rules: [{
              pattern: new RegExp(/^[S].*/),
              message: '架码必须以S开头',
            }],
            initialValue: props.itemData.rackCode
          })(<Input />)}
        </Form.Item>
        {props.template.map(item => (
          <Form.Item label={`${item.title}`} key={item.key}>
            {getFieldDecorator(`${item.key}`, {
              initialValue: props.itemData[item.key]
            })(<Input />)}
          </Form.Item>
        ))}
      </Form>
    </Modal>
  )
}
export default Form.create({ name: 'docedit-modal' })(Index)
