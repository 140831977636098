/*
 * Project: project
 * File Created: 2019-10-14
 * Author: zhoud
 * Last Modified: 2019-10-14
 * Modified By: zhoud
 * Desc: 调阅请求模态框
 */
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import * as globalActions from '../../../store/global/actions'
import {
  Modal,
  Form,
  Input,
  DatePicker,
  Select,
  Upload,
  Icon,
  Divider,
  message
} from 'antd'

import './index.css'
import TextArea from 'antd/lib/input/TextArea'
import AddressModal from '../AddressModal'
import Fetch from '../../../fetch/axios'
export class index extends Component {
  state = {
    confirmLoading: false,
    addressList: [],
    showAddressModal: false,
    totalFileSize: 0,
    totalFileCount: 0,
    addressModalTitle: ''
  }

  normFile = e => {
    console.log('Upload event:', e)
    if (Array.isArray(e)) {
      return e
    }
    return e && e.fileList
  }

  // 打开新增寄送地址
  addAddress = () => {
    this.setState({
      showAddressModal: true,
      addressModalTitle: '新增寄送地址'
    })
  }
  // 提交新增寄送地址
  submitAddAddress = data => {
    const formData = data
    formData['UnitId'] = this.props.thisUnitId
    return Fetch.addExpressAddress(formData).then(res => {
      if (res.code === 0) {
        this.getAddressList().then(this.closeAddressModal())
      } else {
        this.closeAddressModal()
      }
    })
  }

  // 关闭地址管理模态框
  closeAddressModal = () => {
    this.setState({
      showAddressModal: false,
      addressModalTitle: ''
    })
  }

  // 获取寄送地址
  getAddressList = () => {
    return Fetch.getExpressAddress({
      unitId: this.props.thisUnitId
    }).then(res => {
      if (res.code === 0) {
        this.setState({
          addressList: res.data
        })
      }
    })
  }

  componentDidMount() {
    this.getAddressList()
  }

  beforeUpload = (file, fileList) => {
    // 限制文件大小不超过50MB
    const isLt50M = file.size / 1024 / 1024 < 50;
    if (!isLt50M) {
      message.error('文件太大，单文件不能超过50MB!');
      return isLt50M;
    }
    let totalSizeM = this.state.totalFileSize;
    for (const f of fileList)
      totalSizeM += (f.size / 1024 / 1024);
    const isLt250M = totalSizeM < 250;
    if (!isLt250M) {
      message.error(`上传文件总大小不能超过250MB! ${ this.state.totalFileSize > 0 ? ` (您已上传${this.state.totalFileSize.toFixed(2)}MB)` : '' }`);
      return isLt250M;
    }
    const totalFileCount = this.state.totalFileCount + fileList.length;
    const isFileCountLimit = totalFileCount <= 20;
    if (!isFileCountLimit) {
      message.error(`文件数量过多，最多上传20个文件！${this.state.totalFileCount > 0 ? ` (您已上传${this.state.totalFileCount}个文件)` : ''}`);
      return isFileCountLimit;
    }
    this.setState({totalFileSize: totalSizeM});
    this.setState({totalFileCount: totalFileCount});
    const result = isLt50M && isLt250M && isFileCountLimit;
    this.props.setSpinning(result);
    return result;
  };

  // 上传完成回调
  onUploadChange = (info) => {
    info.fileList = info.fileList.filter((f) => f.hasOwnProperty("status"));
    if (info.file.status === 'done' || info.file.status === 'error')
      this.props.setSpinning(false);
    if (info.file.response && info.file.response.code === 0) {
      message.success(info.file.response.message);
    } else if (info.file.response && info.file.response.code === 500) {
      message.error(info.file.response.message);
    } else if (info.file.response && info.file.response.error) {
      if (info.file.response.message == null)
        info.file.response.message = 'No Message.';
      message.error(`${info.file.response.status} ${info.file.response.error}, ${info.file.response.message}`)
    } else if (info.file.status === 'error') {
      message.error('网络错误, 上传失败。（提示：可能文件超过限制大小）');
    }
  }

  onRemove = (file) => {
    const totalFileCount = this.state.totalFileCount - 1;
    this.setState({totalFileCount: totalFileCount});
    let totalSizeM = this.state.totalFileSize - (file.size / 1024 / 1024);
    this.setState({totalFileSize: totalSizeM});
    return true;
  }

  render() {
    const { getFieldDecorator, getFieldsValue, getFieldValue } = this.props.form
    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 }
    }
    return (
      <Fragment>
        <Modal title="调阅请求"
               visible={this.props.visible}
               onCancel={this.props.onCancel}
               onOk={() => this.props.onOk(getFieldsValue())}>
          <Form {...formItemLayout}>
          <Form.Item label="标题">{getFieldDecorator('Name', {})(<Input />)}</Form.Item>
            <Form.Item label="调阅人">
              {getFieldDecorator('BorrowUserId', {})(<Input />)}
            </Form.Item>
            <Form.Item label="调阅到期时间">
              {getFieldDecorator('ExpireDate', {})(<DatePicker />)}
            </Form.Item>
            <Form.Item label="调阅方式">
              {getFieldDecorator('Type', {})(
                <Select>
                  <Select.Option value={0}>电子档</Select.Option>
                  <Select.Option value={1}>纸质档</Select.Option>
                </Select>)}
            </Form.Item>
            <Form.Item label="说明">{getFieldDecorator('Remarks', {})(<TextArea />)}</Form.Item>
            {getFieldValue('Type') === 1 ? (
              <Form.Item label="寄送地址">
                {getFieldDecorator(
                  'ExpressInfoId',
                  {}
                )(<Select dropdownRender={menu => (
                      <div>
                        {menu}
                        <Divider style={{ margin: '4px 0' }} />
                        <div style={{padding: '4px 8px', cursor: 'pointer', textAlign: 'center'}}
                             onMouseDown={e => e.preventDefault()}
                             onClick={this.addAddress}>
                          <Icon type="plus" />
                          <span style={{cursor: 'pointer', padding: '0 5px'}}>新增寄送地址</span>
                        </div>
                      </div>)}>
                    {this.state.addressList.map(item => (
                      <Select.Option value={item.Id} key={item.Id}>
                        {item.Address}
                      </Select.Option>
                    ))}
                  </Select>)}
              </Form.Item>
            ) : (
              <Form.Item label="邮箱">
                {getFieldDecorator('TrackingNumber')(<Input placeholder="邮箱地址"/>)}
              </Form.Item>
            )}
            <Form.Item label="附件">
              {getFieldDecorator('filePath', { valuePropName: 'fileList', getValueFromEvent: this.normFile})(
                <Upload.Dragger name="file"
                                action={`${window.serverIp}:${window.serverPort}/api/v1/file/upload`}
                                listType="picture-card"
                                multiple={true}
                                withCredentials={true}
                                beforeUpload={this.beforeUpload}
                                onRemove={this.onRemove}
                                onChange={this.onUploadChange}>
                  <p className="ant-upload-drag-icon">
                    <Icon type="inbox" />
                  </p>
                  <p className="ant-upload-text">点击或者拖拽文件到此上传</p>
                </Upload.Dragger>
              )}
            </Form.Item>
          </Form>
        </Modal>
        <AddressModal
          visible={this.state.showAddressModal}
          title={this.state.addressModalTitle}
          onCancel={this.closeAddressModal}
          onOk={data => this.submitAddAddress(data)}
        />
      </Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    // 当前单位id
    thisUnitId: state.unit.unitId
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setSpinning: state => {
      dispatch(globalActions.setSpinning(state))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create({ name: 'borrow-request-form' })(index))
