/*
 * Project: project
 * File Created: 2019-09-27
 * Author: zhoud
 * Last Modified: 2019-09-27
 * Modified By: zhoud
 * Desc: 单元格组件
 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as addressActions from '../../../store/address/actions'
import './index.css'
export class index extends Component {
  render() {
    return (
      <div
        style={{
          flexGrow: 1,
          minWidth: '100px',
          textAlign: 'center',
          height: '120px',
          lineHeight: '120px',
          background: 'rgba(24, 144, 255,0.8)',
          margin: '0 5px',
          cursor: 'pointer'
        }}
        onClick={() => {
          this.props.openCellEditModal(this.props.cellInfo)
        }}
      >
        <span>容量:{this.props.capacity}</span>
      </div>
    )
  }
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => {
  return {
    openCellEditModal: info => {
      dispatch(addressActions.setShelfCellEditModalVisible(true))
      dispatch(addressActions.setEditingCellInfo(info))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(index)
