/*
 * Project: project
 * File Created: 2019-09-20
 * Author: zhoud
 * Last Modified: 2019-09-23
 * Modified By: zhoud
 * Desc:存档单位模态框
 */
import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Form, Input, Modal, Spin, TreeSelect} from 'antd'
import * as addressActions from '../../../store/address/actions'
import Fetch from '../../../fetch/axios'
import './index.css'

class index extends Component {
    // 渲染树节点的方法
    renderTreeNode = data => {
        if (data) {
            return data.map(item => {
                if (item.treeNode !== []) {
                    return (
                        <TreeSelect.TreeNode
                            title={item.Name}
                            key={item.Id}
                            value={item.Id}
                            dataRef={item}
                            isLeaf={false}
                        >
                            {this.renderTreeNode(item.treeNode)}
                        </TreeSelect.TreeNode>
                    )
                }
                return (
                    <TreeSelect.TreeNode
                        title={item.Name}
                        key={item.Id}
                        value={item.Id}
                        dataRef={item}
                        isLeaf={false}
                    />
                )
            })
        }
        return null
    }
    // 展开子节点的回调函数
    onLoadData = treeNode =>
        new Promise(resolve => {
            if (treeNode.props.children) {
                resolve()
                return
            }
            Fetch.getAddressList({
                nodeId: treeNode.props.dataRef.Id,
                recursion: false
            }).then(res => {
                treeNode.props.dataRef.treeNode = res.data === [] ? null : res.data
                this.setState({
                    treeData: []
                })
                resolve()
            })
        })

    componentDidMount() {
        this.props.getAddressList()
    }

    render() {
        const {getFieldDecorator, getFieldsValue} = this.props.form
        // 表格项布局
        const formItemLayout = {
            labelCol: {
                span: 4
            },
            wrapperCol: {
                span: 18
            }
        }
        const {
            Name = '',
            Description = '',
            Liaison = '',
            ContactNumber = '',
            Email = '',
            Id = '',
            AddressIds = []
        } = this.props.data === null ? {} : this.props.data
        return (
            <Modal
                visible={this.props.show}
                title={this.props.title}
                onCancel={this.props.cancel}
                onOk={() => {
                    this.props.form.validateFields(err => {
                        if (!err) {
                            this.props.submit({...getFieldsValue(), Id})
                        }
                    })
                }}
                confirmLoading={this.props.confirmLoading}
            >
                <Spin spinning={this.props.confirmLoading}>
                    <Form {...formItemLayout}>
                        <Form.Item label="名称">
                            {getFieldDecorator('Name', {
                                rules: [{required: true, message: '单位名称不能为空!'}],
                                initialValue: Name
                            })(<Input placeholder="名称"/>)}
                        </Form.Item>
                        <Form.Item label="描述">
                            {getFieldDecorator('Description', {
                                initialValue: Description
                            })(<Input placeholder="描述"/>)}
                        </Form.Item>
                        <Form.Item label="联络人">
                            {getFieldDecorator('Liaison', {
                                initialValue: Liaison
                            })(<Input placeholder="联络人"/>)}
                        </Form.Item>
                        <Form.Item label="联系电话">
                            {getFieldDecorator('ContactNumber', {
                                initialValue: ContactNumber
                            })(<Input placeholder="联系电话"/>)}
                        </Form.Item>
                        <Form.Item label="联系邮件">
                            {getFieldDecorator('Email', {
                                initialValue: Email
                            })(<Input placeholder="联系邮件"/>)}
                        </Form.Item>
                        <Form.Item label="所属地点">
                            {getFieldDecorator('AddressIds', {
                                initialValue: AddressIds,
                                rules: [
                                    {
                                        required: true,
                                        message: '请选择所属地点'
                                    }
                                ]
                            })(
                                <TreeSelect
                                    treeCheckable={true}
                                    placeholder="所属地点"
                                    multiple
                                    allowClear
                                    loadData={this.onLoadData}
                                >
                                    {this.renderTreeNode(this.props.addressList)}
                                </TreeSelect>
                            )}
                        </Form.Item>
                    </Form>
                </Spin>
            </Modal>
        )
    }
}

const mapStateToProps = state => ({
    // 存档单位列表
    addressList: state.address.addressList
})

const mapDispatchToProps = dispatch => {
    return {
        getAddressList: () => {
            dispatch(addressActions.getAddressList())
        }
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Form.create({name: 'unit-curd-form'})(index))
