import React, { Component } from 'react'
import { connect } from 'react-redux'
import { List, Card, Input } from 'antd'
import * as unitActions from '../../../store/unit/actions'
import * as departmentActions from '../../../store/department/actions'
import * as documentActions from '../../../store/documentinfo/actions'
export class index extends Component {
  state = {
    inputValue: ''
  }
  // 检索存档单位
  keyEnter = e => {
    if (e.keyCode === 13) {
      this.props.getUnitList({
        keyWord: this.state.inputValue
      })
    }
  }
  handleInputChange = e => {
    const value = e.target.value
    this.setState(
      {
        inputValue: value
      },
      function() {
        if (value === '') {
          this.props.getUnitList({})
        }
      }
    )
  }
  render() {
    this.props.setUnitId(null);
    this.props.setUnitName(null);
    this.props.clearTemplateList();
    return (
      <Card style={{ margin: '10px' }}>
        <Input
          value={this.state.inputValue}
          onChange={this.handleInputChange}
          onKeyUp={this.keyEnter}
          allowClear={true}
        />
        <List style={{ maxHeight: '200px', overflow: 'auto' }}>
          {this.props.unitList.rows
            ? this.props.unitList.rows
                .filter(item => item.Name !== '默认')
                .map(item => (
                  <List.Item
                    key={item.Id}
                    style={{
                      cursor: 'pointer'
                    }}
                    onClick={() => {
                      this.props.setUnitId(item.Id)
                      this.props.getTemplateList(item.Id)
                      this.props.setUnitName(item.Name)
                      this.props.getDepartmentList(item.Id)
                      this.props.setDocList([])
                      this.props.setThisTemplateName('')
                    }}
                  >
                    <List.Item.Meta
                      title={<span style={{ color: 'blue' }}>{item.Name}</span>}
                    />
                    <div style={{ fontSize: '12px', marginRight: '5px' }}>
                      {item.Description}
                    </div>
                  </List.Item>
                ))
            : null}
        </List>
      </Card>
    )
  }
}

const mapStateToProps = state => {
  return {
    // 存档单位列表
    unitList: state.unit.unitList
  }
}

const mapDispatchToProps = dispatch => {
  return {
    // 获取存档单位列表
    getUnitList: pageinfo => {
      dispatch(unitActions.getUnitList(pageinfo))
    },
    // 设置当前单位的id
    setUnitId: id => {
      dispatch(unitActions.setUnitId(id))
    },
    // 获取存档单位模板列表
    getTemplateList: id => {
      dispatch(unitActions.getTemplateList({ id }))
    },
    clearTemplateList: ()=>{
      dispatch(unitActions.setTemplateList([]));
    },
    // 设置当前选中的存档单位名称
    setUnitName: name => {
      dispatch(unitActions.setThisUnitName(name))
    },
    // 获取部门列表
    getDepartmentList: id => {
      dispatch(departmentActions.getDepartmentList(id))
    },
    // 设置档案数据表
    setDocList: list => {
      dispatch(documentActions.setDocList(list))
    },
    // 设置当前模板名称
    setThisTemplateName: name => {
      dispatch(unitActions.setTemplateName(name))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(index)
