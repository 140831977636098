import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import './index.css'
import { Button, Modal, Form, InputNumber } from 'antd'
import ShelfCell from '../ShelfCell'
import Fetch from '../../../fetch/axios'
import * as addressActions from '../../../store/address/actions'
export class index extends Component {
  state = {
    // 批量添加的档案架结构模态框显示状态
    regularModalState: false,
    // 单元格数据
    cellList: [],
    // 批量添加时，用户输入的列
    regularAddCol: 1,
    // 批量添加时，用户输入的行
    regularAddRow: 1,
    // 批量添加时,用户输入的默认容量
    regularAddCapacity: 100,
    showSubmit: false
  }
  // 批量添加结构
  regularAdd = () => {
    const list = []
    const [row, col] = [this.state.regularAddRow, this.state.regularAddCol]
    for (let i = 0; i < row; i++) {
      if (!Array.isArray(list[i])) {
        list[i] = []
      }
      for (let j = 0; j < col; j++) {
        list[i][j] = {
          Capacity: this.state.regularAddCapacity,
          Column: j + 1,
          Row: i + 1,
          ShelfId: this.props.shelfInfo['Id'],
          Status: 0
        }
      }
    }
    this.setState(
      {
        cellList: list,
        showSubmit: true
      },
      function() {
        this.closeModal()
      }
    )
  }
  // 关闭模态框
  closeModal = () => {
    this.setState({
      regularModalState: false
    })
  }
  // 打开批量添加模态框
  openRegularModal = () => {
    this.setState({
      regularModalState: true
    })
  }
  // 行数值改变
  onRowChange = e => {
    this.setState({
      regularAddRow: e
    })
  }
  // 列数值改变
  onColChange = e => {
    this.setState({
      regularAddCol: e
    })
  }
  // 提交创建单元格信息
  createShelfCellList = () => {
    Fetch.createShelfCell({
      shelfId: this.props.shelfInfo['Id'],
      rows: this.state.regularAddRow,
      columns: this.state.regularAddCol,
      capacity: this.state.regularAddCapacity
    }).then(res => {
      this.props.getShelfCellList(this.props.shelfInfo['Id'])
      this.props.onCancel()
      this.closeModal()
    })
  }
  // 删除单元格结构
  deleteCells = () => {
    const deleteIds = []
    const _this = this
    this.props.cellList.forEach(row => {
      row.forEach(item => {
        deleteIds.push(item['Id'])
      })
    })
    Modal.confirm({
      title: '警告!',
      content: '删除单元格结构有可能导致已经上架的档案变为未上架',
      onOk() {
        Fetch.deleteShelfCell(deleteIds).then(res => {
          _this.props.onCancel()
        })
      },
      onCancel() {}
    })
  }
  componentDidMount() {
    // 单元格信息副本
    const cellListCopy = [...this.props.cellList]
    // 当前state中保存一份副本
    this.setState({
      cellList: cellListCopy
    })
  }
  render() {
    return (
      <Fragment>
        <Modal
          visible={this.props.visible}
          width="1000px"
          onCancel={this.props.onCancel}
          onOk={this.props.onCancel}
        >
          <div style={{ overflow: 'auto' }}>
            <div className="btns">
              <Button
                type="primary"
                style={{ margin: '5px 10px' }}
                onClick={this.openRegularModal}
                disabled={this.props.cellList && this.props.cellList.length > 0}
              >
                添加结构
              </Button>
              <Button
                type="primary"
                style={{ margin: '5px 10px' }}
                onClick={this.deleteCells}
              >
                删除所有结构
              </Button>
              {/* <Button type="primary" style={{ margin: '5px 10px' }}>
              非规则结构添加层
            </Button> */}
            </div>
            <div
              className="canvas"
              style={{
                width: '700px',
                margin: '50px auto'
              }}
            >
              {this.props.cellList.map((row, indexRow) => (
                <div
                  className="row"
                  style={{
                    display: 'flex',
                    margin: '5px 0',
                    alignItems: 'center'
                  }}
                  key={indexRow}
                >
                  <div
                    style={{
                      margin: '0 10px 0 0',
                      fontSize: '20px',
                      color: 'rgba(24, 144, 255,1)'
                    }}
                  >
                    第0{indexRow + 1}行
                  </div>
                  {row.map((item, indexCol) => (
                    <ShelfCell
                      key={`${indexRow}-${indexCol}`}
                      capacity={item === null ? 0 : item.Capacity}
                      status={item === null ? 0 : item.Status}
                      cellInfo={item}
                    />
                  ))}
                </div>
              ))}
            </div>
          </div>
          <Modal
            title="档案架结构"
            visible={this.state.regularModalState}
            onOk={this.createShelfCellList}
            onCancel={this.closeModal}
          >
            <Form>
              <InputNumber
                value={this.state.regularAddRow}
                min={1}
                type="number"
                style={{
                  display: 'inline-block',
                  width: '70px',
                  margin: '0 10px'
                }}
                onChange={this.onRowChange}
              />
              层
              <InputNumber
                value={this.state.regularAddCol}
                min={1}
                type="number"
                style={{
                  display: 'inline-block',
                  width: '70px',
                  margin: '0 10px'
                }}
                onChange={this.onColChange}
              />
              列
              <InputNumber
                value={this.state.regularAddCapacity}
                min={1}
                type="number"
                style={{
                  display: 'inline-block',
                  width: '70px',
                  margin: '0 10px'
                }}
                onChange={this.onColChange}
              />
              容量
            </Form>
          </Modal>
        </Modal>
      </Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    // 单元格信息
    cellList: state.address.cellList,
    shelfInfo: state.address.selectedShelfInfo
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getShelfCellList: id => {
      dispatch(addressActions.getShelfCellList(id))
    }
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create({ name: 'regular-add-cell-modal' })(index))
