import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {Form, Icon, Input, Modal, TreeSelect} from 'antd'
import Fetch from '../../fetch/axios'
import * as addressActions from '../../store/address/actions'

const CreateSuppliesModal = (props) => {
    // 档案馆列表
    const [archivesList, setArchivesList] = useState([])
    const [treeData, setTreeData] = useState([])
    // 获取档案馆列表
    // 获取地址列表
    useEffect(() => {
        props.getAddressList()
    }, [])
    // 展开子节点的回调函数
    const onLoadData = (treeNode) =>
        new Promise((resolve) => {
            console.log(treeNode)
            if (treeNode.props.children || treeNode.props.dataRef.Type === 1) {
                resolve()
                return
            }
            Fetch.getAddressList({
                nodeId: treeNode.props.dataRef.Id,
                recursion: false,
            }).then((res) => {
                treeNode.props.dataRef.treeNode = res.data === [] ? null : res.data
                setTreeData([])
                resolve()
            })
        })
    // 渲染地址树
    const renderTreeNode = (data) => {
        const iconTypeMap = ['apartment', 'home', 'shop']
        if (data) {
            return data.map((item) => {
                const icon = iconTypeMap[item.Type]
                if (Array.isArray(item.treeNode)) {
                    return (
                        <TreeSelect.TreeNode
                            title={item.Name}
                            key={item.Id}
                            dataRef={item}
                            isLeaf={item.Type === 1}
                            value={item.Id}
                            selectable={item.Type === 1}
                            icon={<Icon type={icon}/>}
                        >
                            {renderTreeNode(item.treeNode)}
                        </TreeSelect.TreeNode>
                    )
                }
                return (
                    <TreeSelect.TreeNode
                        title={item.Name}
                        key={item.Id}
                        value={item.Id}
                        selectable={item.Type === 1}
                        dataRef={item}
                        isLeaf={item.Type === 1}
                    />
                )
            })
        }
        return null
    }
    const {getFieldDecorator, getFieldsValue} = props.form
    const formItemLayout = {
        labelCol: {
            span: 4,
        },
        wrapperCol: {
            span: 18,
        },
    }
    return (
        <Modal
            visible={props.visible}
            onOk={() => {
                props.onOk(getFieldsValue())
            }}
            onCancel={props.onCancel}
            title="创建耗材"
        >
            <Form {...formItemLayout}>
                <Form.Item label="耗材名称">
                    {getFieldDecorator('name')(<Input/>)}
                </Form.Item>
                <Form.Item label="所属馆库">
                    {getFieldDecorator('archivesId')(
                        <TreeSelect loadData={onLoadData}>
                            {renderTreeNode(props.addressList)}
                        </TreeSelect>
                    )}
                </Form.Item>
                <Form.Item label="初始数量">
                    {getFieldDecorator('stock')(<Input type="number"/>)}
                </Form.Item>
            </Form>
        </Modal>
    )
}
const mapStateToProps = (state) => ({
    addressList: state.address.addressList,
})
const mapDispatchToProps = (dispatch) => ({
    // 获取地址列表
    getAddressList: () => {
        dispatch(addressActions.getAddressList())
    },
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Form.create({name: 'create-supplies-form'})(CreateSuppliesModal))
