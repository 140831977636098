/*
 * Project: project
 * File Created: 2019-09-11
 * Author: zhoud
 * Last Modified: 2019-09-11
 * Modified By: zhoud
 * Desc: 地点管理
 */
import React, { Component } from 'react'
import { Col, Row } from 'antd'
import AddressTree from '../components/AddressManage/AddressTree'
import ShelfTable from '../components/AddressManage/ShelfTable'
export class AddressManage extends Component {
  render() {
    return (
      <Row gutter={24}>
        <Col span={6}>
          <AddressTree />
        </Col>
        <Col span={18}>
          <ShelfTable />
        </Col>
      </Row>
    )
  }
}

export default AddressManage