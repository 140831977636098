import React, {useEffect, useState} from 'react'
import {Input, Modal, Table} from 'antd'
import Fetch from '../../../fetch/axios'

const RebackModal = props => {
  const [blList, setBlList] = useState([])
  const [column, setColumn] = useState([])
  const [selectedKeys, setSelectedKeys] = useState([])
  const [text, setText] = useState('')
  const [returnUserName, setReturnUserName] = useState('')
  const [idMap, setIdMap] = useState({})
  const [snMap, setSnMap] = useState({})
  useEffect(() => {
    Fetch.getBorrowList({
      requestId: props.id
    }).then(res => {
      const field = res.data.field
      const NotReturnList = res.data.blList.filter(item => item.isReturn === 1)
      const idMap = {}
      const snMap = {}
      NotReturnList.forEach(item => {
        idMap[item.blId] = item.ArchivesBaseId;
        snMap[item.blId] = item.BorrowSn;
      })
      setIdMap(idMap)
      setSnMap(snMap)
      setBlList(NotReturnList)
      const columns = field.map(item => {
        return {
          title: item.Name,
          key: item.Id,
          dataIndex: item.Id,
          align: 'center'
        }
      });
      columns.unshift({
          title: '序号',
          key: 'BorrowSn',
          dataIndex: 'BorrowSn',
          align: 'center'
      });
      setColumn(columns)
    })
  }, [])
  return (
    <Modal title={props.title}
           width="50%"
           visible={props.visible}
           onCancel={() => { props.onCancel() }}
           onOk={() => { props.onOk(
            {
              reason: text,
              returnUserName: returnUserName,
              list: selectedKeys.map(item => ({id: item, borrowStatus: props.borrowStatus, archivesId: idMap[item], borrowSn: snMap[item]})),
              requestId: props.id
            })}}>
      <Table dataSource={blList}
             columns={column}
             rowKey={item => item.blId}
             rowSelection={{ selectedRowKeys: selectedKeys, onChange: (selectedRowKeys) => { setSelectedKeys(selectedRowKeys) }}}
             pagination={{ pageSize: 5 }}/>
      {(props.showReturnUserName) ? (<Input onChange={(e) => { setReturnUserName(e.target.value) }} placeholder='请输入归还人' visibility={'hidden'} style={{marginBottom: '15px'}} />) : null}
      <Input.TextArea onChange={(e) => { setText(e.target.value) }} placeholder={props.placeholder} />
    </Modal>
  )
}
export default RebackModal
