/*
 * Project: project
 * File Created: 2019-08-28
 * Author: zhoud
 * Last Modified: 2019-08-28
 * Modified By: zhoud
 * Desc: 角色管理页面
 */
import React, { Component, Fragment } from 'react'
import { Button, Table, Modal, Card, message } from 'antd'
import AddEditModal from '../components/RoleManage/AddEditModal'
import PowerModal from '../components/RoleManage/PowerModal'
import Fetch from '../fetch/axios'
export class RoleManage extends Component {
  state = {
    // 当前页面
    currentPage: 1,
    // 总数据条目
    total: 1,
    // 每一页条目数
    pageSize: 10,
    // 角色列表
    roleList: [],
    // 添加和编辑模态框展示状态
    showAddEditModal: false,
    // 模态框标题
    modalTitle: '',
    // 模态框数据
    modalData: null,
    // 当前选中id
    selectedId: '',
    // 当前选中的roid
    selectedRoleId: '',
    // 当前选中角色动作
    selectedRowActions: [],
    // 当前选中角色路由
    selectedRowPages: [],
    // 权限模态框展示状态
    showPowerModal: false
  }
  // 表格结构
  columns = [
    {
      title: '名称',
      dataIndex: 'Name',
      key: 'Name',
      align: 'center'
    },
    {
      title: '类型',
      dataIndex: 'Type',
      key: 'Type',
      align: 'center'
    },
    {
      title: '描述',
      dataIndex: 'Description',
      key: 'Description',
      align: 'center'
    },
    {
      title: '创建时间',
      dataIndex: 'CreateTime',
      key: 'CreateTime',
      align: 'center'
    },
    {
      title: '操作',
      render: record => (
        <div>
          <span
            style={{ padding: '5px', color: 'blue', cursor: 'pointer' }}
            onClick={() => this.OpenModal(record)}
          >
            编辑
          </span>
          <span
            style={{ padding: '5px', color: 'blue', cursor: 'pointer' }}
            onClick={() => {
              Modal.confirm({
                title: '确定删除此角色么?',
                onOk: () => {
                  this.deleteRole(record.Id)
                },
                okType: 'danger'
              })
            }}
          >
            删除
          </span>
          <span
            style={{ padding: '5px', color: 'blue', cursor: 'pointer' }}
            onClick={() => {
              this.openPowerModal(record.Id, record.pId)
            }}
          >
            权限
          </span>
        </div>
      ),
      align: 'center'
    }
  ]
  // 表格勾选checkbox,将勾选用户的key存入state
  rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      this.setState({
        selectedKeys: selectedRows.map(item => item.Id)
      })
    }
  }
  pageChange = (page, pageSize) => {
    this.setState(
      {
        currentPage: page,
        pageSize: pageSize
      },
      function() {
        Fetch.getRoleList({
          page: this.state.currentPage,
          pageSize: this.state.pageSize
        }).then(res => {
          if (res.success === true) {
            this.setState({
              roleList: res.data.rows,
              total: res.data.rowCount
            })
          }
        })
      }
    )
  }
  // 打开模态框
  OpenModal = (data = null) => {
    if (data === null) {
      this.setState({
        showAddEditModal: true,
        modalTitle: '添加角色'
      })
    } else {
      this.setState({
        modalData: data,
        showAddEditModal: true,
        modalTitle: '编辑角色'
      })
    }
  }
  // 关闭模态框
  CloseModal = () => {
    this.setState({
      showAddEditModal: false,
      modalTitle: '',
      modalData: null
    })
  }
  // 提交表单
  submitAddEdit = data => {
    let formData = JSON.parse(JSON.stringify(data))
    if (this.state.modalTitle === '添加角色') {
      formData.Status = 0
      Fetch.createRole(formData)
        .then(res => {
          if (res.success === true) {
            message.success('添加角色成功')
            this.getRoleList()
          }
          this.CloseModal()
        })
        .catch(err => {
          console.log(err)
          this.CloseModal()
        })
    } else {
      formData = Object.assign(this.state.modalData, data)
      Fetch.updateRole(formData)
        .then(res => {
          if (res.success === true) {
            message.success('添加更新成功')
            this.getRoleList()
          }
          this.CloseModal()
        })
        .catch(err => {
          console.log(err)
          this.CloseModal()
        })
    }
  }
  // 获取角色列表
  getRoleList = () => {
    Fetch.getRoleList({
      page: this.state.currentPage,
      pageSize: this.state.pageSize
    }).then(res => {
      if (res.success === true) {
        this.setState({
          roleList: res.data.rows,
          total: res.data.rowCount
        })
      }
    })
  }
  // 删除角色
  deleteRole = id => {
    Fetch.deleteRole({ id })
      .then(res => {
        if (res.code === 0) {
          message.success('删除角色成功')
          this.getRoleList({})
        } else {
          message.error('删除角色失败')
        }
      })
      .catch(err => {
        console.log(err)
        message.error('删除角色失败')
      })
  }
  // 批量删除角色
  deleteRoles = id => {
    Fetch.deleteRoles(id)
      .then(res => {
        if (res.code === 0) {
          message.success('删除角色成功')
          this.getRoleList({})
        } else {
          message.error('删除角色失败')
        }
      })
      .catch(err => {
        console.log(err)
        message.error('删除角色失败')
      })
  }
  // 打开权限模态框
  openPowerModal = (Id, pId) => {
    Fetch.getRolePower({
      roleId: Id
    })
      .then(res => {
        if (res.success === true) {
          const action =
            res.data.action === null ? [] : res.data.action.split(';')
          const page = res.data.page === null ? [] : res.data.page.split(';')
          this.setState({
            showPowerModal: true,
            selectedRowActions: action,
            selectedRowPages: page,
            selectedId: res.data.Id,
            selectedRoleId: res.data.roleId
          })
        } else {
          message.error('获取角色权限失败')
        }
      })
      .catch(err => {
        console.log(err)
        message.error('获取角色权限失败')
      })
  }
  // 关闭权限模态框
  closePowerModal = () => {
    this.setState({
      showPowerModal: false,
      selectedRowActions: [],
      selectedRowPages: [],
      selectedId: '',
      selectedRoleId: ''
    })
  }
  componentDidMount() {
    this.getRoleList()
  }
  render() {
    return (
      <Fragment>
        <div className='buttons'>
          <Button
            type='primary'
            onClick={() => this.OpenModal()}
            style={{ margin: '5px 10px' }}
          >
            添加
          </Button>
          <Button
            type='primary'
            onClick={() => {
              // 删除勾选的用户
              if (this.state.selectedKeys.length > 0) {
                Modal.confirm({
                  title: `确定删除这${this.state.selectedKeys.length}个用户?`,
                  onOk: () => {
                    this.deleteRoles(this.state.selectedKeys)
                  }
                })
              }
            }}
            style={{ margin: '10px' }}
          >
            删除
          </Button>
        </div>
        <div className='table'>
          <Card>
            <Table
              columns={this.columns}
              dataSource={this.state.roleList}
              pagination={{
                onChange: this.pageChange,
                pageSize: this.state.pageSize,
                total: this.state.total
              }}
              rowKey={item => item.Id}
              rowSelection={this.rowSelection}
            />
          </Card>
        </div>
        {this.state.showPowerModal ? (
          <PowerModal
            data={{
              action: this.state.selectedRowActions,
              page: this.state.selectedRowPages
            }}
            visible={this.state.showPowerModal}
            onCancel={this.closePowerModal}
            id={this.state.selectedId}
            role={this.state.selectedRoleId}
          />
        ) : null}
        {this.state.showAddEditModal ? (
          <AddEditModal
            visible={this.state.showAddEditModal}
            title={this.state.modalTitle}
            onCancel={this.CloseModal}
            onOk={data => this.submitAddEdit(data)}
            data={this.state.modalData}
          />
        ) : null}
      </Fragment>
    )
  }
}

export default RoleManage
