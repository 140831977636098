import * as types from './action-types'
// 获取档案数据
export const getDocList = state => {
  return {
    type: types.GET_DOC_LIST,
    data: state
  }
}
// 设置档案数据
export const setDocList = state => {
  return {
    type: types.SET_DOC_LIST,
    data: state
  }
}
// 设置搜索信息
export const setSearchForm = state => {
  return {
    type: types.SET_SEARCH_FORM,
    data: state
  }
}
// 清空搜索条件
export const clearSearchForm = () => {
  return {
    type: types.CLEAR_SEARCHFORM
  }
}
export const setRowCount = data => {
  return {
    type: types.SET_ROWCOUNT,
    data
  }
}
export const resetPageIndex = () => {
  return {
    type: types.RESET_DOCPAGEINDEX
  }
}
export const setPageIndex = data => {
  return {
    type: types.SET_DOCPAGEINDEX,
    data
  }
}
