/*
 * Project: project
 * File Created: 2019-10-22
 * Author: zhoud
 * Last Modified: 2019-10-22
 * Modified By: zhoud
 * Desc: 调阅请求列表
 */
import React, { Component, Fragment } from 'react'
import BorrowRequestTable from '../components/BorrowRequest/BorrowRequestTable'
export class BorrowRequestList extends Component {
  render() {
    return (
      <Fragment>
        <BorrowRequestTable />
      </Fragment>
    )
  }
}

export default BorrowRequestList
