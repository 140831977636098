// 设置用户登录状态
export const SET_LOGIN_STATE = 'SET_LOGIN_STATE'
// 切换菜单展开收起状态
export const TOGGLE_MENU_EXPAND_STATE = 'TOGGLE_MENU_EXPAND_STATE'
// 切换用户信息组件显示状态
export const TOGGLE_USERINFO_DISPLAY_STATE = 'TOGGLE_USERINFO_DISPLAY_STATE'
// 设置用户信息
export const SET_USER_INFO = 'SET_USER_INFO'
export const SET_REQUEST_COUNT = 'SET_REQUEST_COUNT'
export const SET_SPINNING = 'SET_SPINNING'
