/*
 * Project: project
 * File Created: 2019-08-06
 * Author: zhoud
 * Last Modified: 2019-08-06
 * Modified By: zhoud
 * Desc:用户信息组件
 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Img from '../../../assets/img/logo1.png'
import { Button, message } from 'antd'
import Fetch from '../../../fetch/axios'
import * as globalActions from '../../../store/global/actions'
import * as unitActions from '../../../store/unit/actions'
import * as DocActions from '../../../store/documentinfo/actions'
import './index.css'
export class index extends Component {
  // 注销登录
  logOut = () => {
    Fetch.logout().then(res => {
      if (res.code === 0) {
        sessionStorage.clear()
        this.props.toggleUserinfoDisplayState()
        message.success('注销登录成功')
        this.props.history.push('/login')
        this.props.resetDocList()
        this.props.resetTemplateName()
        this.props.resetUnitName()
      }
    })
  }
  render() {
    return (
      <div
        style={{
          display: 'flex',
          height: '100%',
          color: '#ffffff',
          justifyContent: 'space-around'
        }}
        className="user-info"
      >
        <div className="avatar" style={{ flexGrow: 1, position: 'relative' }}>
          <img
            src={Img}
            alt="avatar"
            style={{
              borderRadius: '50%',
              height: '70%',
              position: 'absolute',
              top: '0',
              right: '0',
              bottom: '0',
              left: '0',
              margin: 'auto'
            }}
          />
        </div>
        <div
          className="message"
          style={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            height: '100%'
          }}
        >
          <div className="messageBox">
            <span className="title">用户名:</span>
            <span>{this.props.userInfo['Name']}</span>
          </div>
          <div className="messageBox">
            <span className="title">电话:</span>
            <span>{this.props.userInfo['Telephone']}</span>
          </div>
          <div className="messageBox">
            <span className="title">手机:</span>
            <span>{this.props.userInfo['Phone']}</span>
          </div>
        </div>
        <div
          className="message"
          style={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            height: '100%'
          }}
        >
          <div className="messageBox">
            <span className="title">邮箱:</span>
            <span>{this.props.userInfo['Email']}</span>
          </div>
          <div
            style={{ textAlign: 'left', marginLeft: '60px' }}
            onClick={this.logOut}
          >
            <Button type="primary">注销登录</Button>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  userInfo: state.global.userInfo
})

const mapDispatchToProps = dispatch => {
  return {
    // 切换用户信息的显示和隐藏
    toggleUserinfoDisplayState: () => {
      dispatch(globalActions.toggleUserinfoDisplayState())
    },
    resetUnitName: () => {
      dispatch(unitActions.setThisUnitName(''))
    },
    resetTemplateName: () => {
      dispatch(unitActions.setTemplateName(''))
    },
    resetDocList: () => {
      dispatch(DocActions.setDocList([]))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(index))
