import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Table, Button, message, Modal, Card } from 'antd'
import DepartmentFormModal from '../DepartmentFormModal'
import TemplateBindModal from '../TemplateBindModal'
import Fetch from '../../../fetch/axios'
import * as departmentActions from '../../../store/department/actions'
export class index extends Component {
  state = {
    // 部门模态框显示状态
    departmentModalState: false,
    // 模态框标题
    modalTitle: '',
    // 勾选的行数据
    selectedRows: [],
    // 绑定模板模态框
    showTemplateBindModal: false,
    // 当前绑定模板的部门id
    selectedDepartmentId: '',
    // 编辑行的数据
    itemData: {}
  }
  columns = [
    {
      title: '部门名称',
      dataIndex: 'Name',
      key: 'Name',
      align: 'center'
    },
    {
      title: '备注',
      dataIndex: 'Remark',
      key: 'Remark',
      align: 'center'
    },
    {
      title: '操作',
      render: record => (
        <div>
          <span
            style={{ color: 'blue', cursor: 'pointer', padding: '0 5px' }}
            onClick={() => {
              this.setState({
                departmentModalState: true,
                modalTitle: '修改部门信息',
                itemData: record
              })
            }}
          >
            修改
          </span>
          <span
            style={{ color: 'blue', cursor: 'pointer', padding: '0 5px' }}
            onClick={() => {
              this.setState(
                {
                  selectedRows: [record]
                },
                function() {
                  this.deleteDepartments()
                }
              )
            }}
          >
            删除
          </span>
          <span
            style={{ color: 'blue', cursor: 'pointer', padding: '0 5px' }}
            onClick={() => {
              this.openTemplateBindModal(record.Id)
            }}
          >
            关联模板
          </span>
        </div>
      ),
      align: 'center'
    }
  ]
  // 开启模态框
  openCreateModal = () => {
    this.setState({
      departmentModalState: true,
      modalTitle: '新增部门'
    })
  }
  // 关闭模态框
  closeModal = () => {
    this.setState({
      departmentModalState: false,
      modalTitle: '',
      itemData: {}
    })
  }
  // 打开关联模板
  openTemplateBindModal = id => {
    this.setState({
      showTemplateBindModal: true,
      selectedDepartmentId: id
    })
  }
  // 关闭关联模板
  closeTemplateBindModal = () => {
    this.setState({
      showTemplateBindModal: false
    })
  }
  // 提交表单
  submit = data => {
    // 添加部门时
    if (
      this.state.departmentModalState &&
      this.state.modalTitle === '新增部门'
    ) {
      const formData = {
        ArchivingUnitId: this.props.thisUnitId,
        Status: 0
      }
      delete data.Id
      Fetch.createDepartment(Object.assign(formData, data))
        .then(res => {
          if (res.code === 0) {
            message.success('部门添加成功')
            this.props.getDepartmentList(this.props.thisUnitId)
          }
          this.closeModal()
        })
        .catch(err => {
          console.log(err)
          this.closeModal()
        })
    } else {
      const formData = {
        ArchivingUnitId: this.props.thisUnitId,
        Status: 0
      }
      Fetch.updateDepartment(Object.assign(formData, data))
        .then(res => {
          if (res.code === 0) {
            message.success('修改部门信息成功')
            this.props.getDepartmentList(this.props.thisUnitId)
          } else {
            message.error('修改部门失败')
          }
          this.closeModal()
        })
        .catch(err => {
          console.log(err)
          this.closeModal()
        })
    }
  }
  // 删除部门
  deleteDepartments = () => {
    const _this = this
    Modal.confirm({
      title: '确定要删除这些部门信息么?',
      okType: 'danger',
      onOk() {
        Fetch.deleteDepartments(
          _this.state.selectedRows.map(item => item.Id)
        ).then(res => {
          if (res.code === 0) {
            message.success('删除部门成功')
            _this.props.getDepartmentList(_this.props.thisUnitId)
          }
        })
      }
    })
  }
  submitBindTemplate = ids => {
    Fetch.updateTemplateDepat({
      departmentId: this.state.selectedDepartmentId,
      templateIds: ids
    }).then(res => {
      if (res.code === 0) {
        message.success('模板绑定成功')
        this.closeTemplateBindModal()
      }
    })
    console.log(ids)
  }
  render() {
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({
          selectedRows: selectedRows
        })
      }
    }
    return (
      <div>
        <Card title={this.props.thisUnitName} style={{ margin: '10px' }}>
          <div className="btns">
            <Button
              type="primary"
              style={{ margin: '5px 10px 10px' }}
              onClick={this.openCreateModal}
            >
              添加
            </Button>
            <Button
              type="primary"
              style={{ margin: '5px 10px 10px' }}
              onClick={this.deleteDepartments}
            >
              删除
            </Button>
          </div>
          <Table
            columns={this.columns}
            dataSource={this.props.departmentList}
            rowSelection={rowSelection}
            rowKey={item => item.Id}
          />
        </Card>
        {this.state.departmentModalState ? (
          <DepartmentFormModal
            visible={this.state.departmentModalState}
            title={this.state.modalTitle}
            onCancel={this.closeModal}
            onOk={data => this.submit(data)}
            data={this.state.itemData}
          />
        ) : null}
        {this.state.showTemplateBindModal ? (
          <TemplateBindModal
            visible={this.state.showTemplateBindModal}
            onCancel={this.closeTemplateBindModal}
            unitId={this.props.thisUnitId}
            onOk={({ ids }) => {
              this.submitBindTemplate(ids)
            }}
          />
        ) : null}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  // 当前选择的存档单位的名字
  thisUnitName: state.unit.unitName,
  // 当前单位的Id
  thisUnitId: state.unit.unitId,
  departmentList: state.department.departmentList
})

const mapDispatchToProps = dispatch => {
  return {
    // 获取部门列表
    getDepartmentList: id => {
      dispatch(departmentActions.getDepartmentList(id))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(index)
