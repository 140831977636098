import * as types from './action-types'
export default (
  state = {
    // 存档单位列表
    unitList: { rows: [] },
    // 模板列表
    templateList: [],
    // 模板模态框的显示状态
    showTemplateModal: false,
    // 模板字段数据
    templateDetailList: [],
    // 模板名称
    templateName: '',
    // 存档单位名称
    unitName: '',
    // 模板Id
    templateId: '',
    // 单位表格数据请求状态
    loading: false,
    // 模板数据获取状态
    templateLoading: false,
    // 架体列表信息
    shelfList: [],
    // 当前存档单位id
    unitId: '',
    // 文档表格的columns
    columns: [],
    // unitTable总条目
    unitTableRowCount: -1,
    unitTablePageIndex: 1
  },
  action
) => {
  switch (action.type) {
    // 设置用户列表
    case types.SET_UNIT_LIST: {
      return {
        ...state,
        unitList: action.data
      }
    }
    // 设置模板列表
    case types.SET_TEMPLATE_BY_UNIT: {
      return {
        ...state,
        templateList: action.data
      }
    }
    // 切换显示模板模态框显示状态
    case types.SET_TEMPLATE_MODAL_STATE: {
      return {
        ...state,
        showTemplateModal: action.data
      }
    }
    // 设置当前模板字段的数据
    case types.SET_TEMPLATEFIELD_BY_UNIT: {
      const data = action.data
      const columns = []
      data.forEach(item => {
        columns.push({
          title: item.Name,
          dataIndex: item.Id,
          key: item.Id,
          align: 'center'
        })
      })
      return {
        ...state,
        templateDetailList: action.data,
        columns
      }
    }
    // 设置当前模板名称
    case types.SET_TEMPLATENAME_BY_UNIT: {
      return {
        ...state,
        templateName: action.data
      }
    }
    // 当前单位id
    case types.SET_THIS_UNIT_ID: {
      return {
        ...state,
        unitId: action.data
      }
    }
    // 设置加载状态
    case types.SET_UNIT_TABLE_LOADING_STATE: {
      return {
        ...state,
        loading: action.data
      }
    }
    // 模板id设置
    case types.SET_THIS_TEMPLATE_ID: {
      return {
        ...state,
        templateId: action.data
      }
    }
    // 设置当前选择单位名称
    case types.SET_THIS_UNIT_NAME: {
      return {
        ...state,
        unitName: action.data
      }
    }
    // 模板数据获取状态
    case types.SET_TEMPLATE_LOADING_STATE: {
      return {
        ...state,
        templateLoading: action.data
      }
    }
    case types.SET_UNITTABLE_ROWCOUNT: {
      return {
        ...state,
        unitTableRowCount: action.data
      }
    }
    case types.SET_UNITTABLE_PAGEINDEX: {
      return {
        ...state,
        unitTablePageIndex: action.data.pageIndex
      }
    }
    // 设置表格数据加载状态
    default: {
      return state
    }
  }
}
