import * as types from './action-types'
export default (
  state = {
    // 部门列表
    departmentList: []
  },
  action
) => {
  switch (action.type) {
    // 设置部门数据
    case types.SET_DEPARTMENT_LIST: {
      return {
        ...state,
        departmentList: action.data
      }
    }
    default: {
      return state
    }
  }
}
