import React, { Component } from 'react'
import { connect } from 'react-redux'
import './index.css'
import { Modal, Table, Popover, message } from 'antd'
import Fetch from '../../../fetch/axios'
export class index extends Component {
  statusMap = {
    '-6': '用户终止请求',
    '-5': '管理员终止请求',
    '-4': '无法归还',
    '-3': '快递寄送失败',
    '-2': '无法发送快递',
    '-1': '驳回',
    '0': '借阅发起',
    '1': '借阅请求已通过',
    '2': '已发送快递',
    '3': '调阅完成',
    '4': '已归还'
  }
  columns = [
    {
      title: '调阅人',
      dataIndex: 'BorrowUserId',
      key: 'BorrowUserId',
      align: 'center'
    },
    {
      title: '创建时间',
      dataIndex: 'CreateTime',
      key: 'CreateTime',
      align: 'center'
    },
    {
      title: '到期时间',
      dataIndex: 'ExpireDate',
      key: 'ExpireDate',
      align: 'center'
    },
    {
      title: '调阅档案数',
      render: record => (
        <Popover
          title="调阅档案名"
          content={record.archBaseInfos.map(item => (
            <div key={item.Id}>{item.Name}</div>
          ))}
        >
          <div style={{ cursor: 'pointer' }}>{record.borrowNum}</div>
        </Popover>
      ),
      align: 'center'
    },
    {
      title: '原因',
      dataIndex: 'Name',
      key: 'Name',
      align: 'center'
    },
    {
      title: '状态',
      render: record => <div>{this.statusMap[record.Status + '']}</div>,
      align: 'center'
    },
    {
      title: '附件',
      render: record =>
        record.files.length > 0 ? (
          <Popover
            title="附件列表"
            content={record.files.map(item => (
              <div
                key={item.Id}
                style={{ cursor: 'pointer', color: 'blue' }}
                onClick={() => this.handleFile(item)}
              >
                {item.Name}
              </div>
            ))}
          >
            <div style={{ color: 'blue', cursor: 'pointer' }}>附件</div>
          </Popover>
        ) : null
    }
  ]
  handleFile = file => {
    console.log(file)
    const name = file.Name
    const isImg = name.match(/.(jpg|png|gif|svg)$/)
    Fetch.downloadFile({
      name: file.Path
    }).then(res => message.info(res))
    console.log(file)
    if (isImg) {
    } else {
    }
  }
  render() {
    return (
      <Modal
        visible={this.props.visible}
        title="请求详情"
        onOk={this.props.onCancel}
        onCancel={this.props.onCancel}
        width={1000}
      >
        <Table
          columns={this.columns}
          dataSource={[this.props.data]}
          pagination={{
            hideOnSinglePage: true
          }}
          rowKey={item => item.Id}
        />
      </Modal>
    )
  }
}

const mapStateToProps = state => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(index)
