/*
 * Project: project
 * File Created: 2020-01-08
 * Author: zhoudailin
 * Last Modified: 2020-01-08
 * Modified By: zhoudailin
 * Desc: 批量添加档案架模态框
 */
import React, { Component } from 'react'
import { Modal, Form, Input } from 'antd'
import { connect } from 'react-redux'
class index extends Component {
  render() {
    const { getFieldDecorator } = this.props.form
    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
    }
    return (
      <Modal
        title="批量添加档案架结构"
        visible={this.props.visible}
        onCancel={this.props.onCancel}
        onOk={() => {
          this.props.onOk(this.props.form.getFieldsValue())
        }}
      >
        <Form {...formItemLayout}>
          <Form.Item label="档案架数量">
            {getFieldDecorator('num')(<Input type="number" />)}
          </Form.Item>
          <Form.Item label="档案架行数">
            {getFieldDecorator('row')(<Input type="number" />)}
          </Form.Item>
          <Form.Item label="档案架列数">
            {getFieldDecorator('col')(<Input type="number" />)}
          </Form.Item>
          <Form.Item label="档案架名称前缀">
            {getFieldDecorator('name')(<Input />)}
          </Form.Item>
          <Form.Item label="单元格容量">
            {getFieldDecorator('capacity')(<Input type="number" />)}
          </Form.Item>
          <Form.Item label="起始编号">
            {getFieldDecorator('start')(<Input type="number" />)}
          </Form.Item>
        </Form>
      </Modal>
    )
  }
}
const mapStateToProps = (state) => ({
  thisAddressId: state.address.selectedAddressInfo.Id,
})

const mapDispatchToProps = (dispatch) => {
  return {}
}

export default Form.create({ name: 'shelf-mass-add-form' })(
  connect(mapStateToProps, mapDispatchToProps)(index)
)
