/*
 * Project: project
 * File Created: 2019-09-19
 * Author: zhoud
 * Last Modified: 2019-09-23
 * Modified By: zhoud
 * Desc: 地点增加和编辑的模态框
 */
import React, { Component } from 'react'
import { Form, Modal, Input, Radio } from 'antd'
import './index.css'
class index extends Component {
  componentDidMount() {
    console.log('data: ', this.props.data)
  }
  render() {
    const { getFieldDecorator, getFieldsValue } = this.props.form
    const { parentId = '', Name = '', Type = null, FullName = '', Id = '' } =
      this.props.data === null ? {} : this.props.data
    // 表格项布局
    const formItemLayout = {
      labelCol: {
        span: 4
      },
      wrapperCol: {
        span: 18
      }
    }
    return (
      <Modal
        title={this.props.title}
        visible={this.props.visible}
        onCancel={this.props.onCancel}
        onOk={() =>
          this.props.onOk({ Id, ParentId: parentId, ...getFieldsValue() })
        }
      >
        <Form {...formItemLayout}>
          <Form.Item label="名称">
            {getFieldDecorator('Name', {
              initialValue: Name
            })(<Input placeholder="地点名称" />)}
          </Form.Item>
          <Form.Item label="类型">
            {getFieldDecorator('Type', {
              initialValue: Type
            })(
              <Radio.Group>
                {
                  this.props.type === 1 ? <>
                    {this.props.data && this.props.data.type === 0 ? (
                        <Radio value={1}>档案馆</Radio>
                    ) : null}
                    {this.props.data && this.props.data.type === 1 ? (
                        <Radio value={2}>档案库房</Radio>
                    ) : null}
                    {!this.props.data ? (
                        <Radio value={0}>逻辑节点</Radio>
                    ) : null}
                  </>:<>
                    {this.props.data && this.props.data.Type === 0 ? (<span>逻辑节点</span>):null}
                    {this.props.data && this.props.data.Type === 1 ? (<span>档案馆</span>):null}
                    {this.props.data && this.props.data.Type === 2 ? (<span>档案库房</span>):null}
                  </>
                }

              </Radio.Group>
            )}
          </Form.Item>

        </Form>
      </Modal>
    )
  }
}
export default Form.create({ name: 'address-form' })(index)
