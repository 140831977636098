import React, { useState, useEffect } from 'react'
import { Button, Card, Table, message, Modal } from 'antd'
import DocAddModal from '../components/DocUp/DocAddModal'
import Fetch from '../fetch/axios'

import DocUploadModal from '../components/DocUp/DocUploadModal'
import DocUpDetailModal from '../components/DocUp/DocUpDetailModal'
const DocUp = (props) => {
  const [showDocaddModal, setShowDocAddModal] = useState(false)
  const [showDocUploadModal, setShowDocUploadModal] = useState(false)
  const [showDocUpDetailModal, setShowDocUpDetialModal] = useState(false)
  const [selectedObj, setSelectedObj] = useState({})
  const [dataSource, setDataSource] = useState([])
  const [reFetch, setRefetch] = useState(true)
  const [total, setTotal] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  useEffect(() => {
    Fetch.getWarehousList({
      currentPage: currentPage
    }).then((res) => {
      if (res.code === 0) {
        const count = res.data.rowCount
        setDataSource(res.data.rows)
        setTotal(count)
      }
    })
  }, [currentPage, reFetch])
  const columns = [
    { title: '单位', dataIndex: 'unitName', key: 'unitName', align: 'center' },
    { title: '部门', dataIndex: 'deptName', key: 'deptName', align: 'center' },
    { title: '数量', dataIndex: 'numStr', key: 'numStr', align: 'center' },
    {
      title: '入库位置', dataIndex: 'archiveName', key: 'archiveName', align: 'center'
    },
    {
      title: '接收日期',
      dataIndex: 'createTime',
      key: 'createTime',
      align: 'center',
    },
    {
      title: '操作',
      render: (record) => (
        <div style={{ display: 'flex' }}>
          {record.status === 0 ? (
            <span
              style={{ flexGrow: '1', cursor: 'pointer', color: 'blue' }}
              onClick={() => {
                openDocUploadModal(record)
              }}
            >
              上架
            </span>
          ) : null}
          <span
            style={{ flexGrow: '1', cursor: 'pointer', color: 'blue' }}
            onClick={() => {
              openDocUpDetailModal(record)
            }}
          >
            详情
          </span>
          {record.status === 0 ? (
            <span
              style={{ flexGrow: '1', cursor: 'pointer', color: 'blue' }}
              onClick={() => {
                uploadDocDone(record)
              }}
            >
              上架完成
            </span>
          ) : null}
          <span
            style={{ flexGrow: '1', cursor: 'pointer', color: 'blue' }}
            onClick={() => {
              Modal.confirm({
                title: '确认删除?',
                onOk: () => {
                  Fetch.deleteWarehousItem({
                    id: record.Id,
                  }).then((res) => {
                    if (res.code === 0) {
                      message.success('删除成功')
                      setRefetch(!reFetch)
                    }
                  })
                },
              })
            }}
          >
            删除
          </span>
        </div>
      ),
      width: 250,
      align: 'center',
    },
  ]
  // 创建上架模态框开起
  const openAddDocModal = () => {
    setShowDocAddModal(true)
  }
  // 创建上架模态框关闭
  const closeAddDocModal = () => {
    setShowDocAddModal(false)
  }
  // 提交上架模态框
  const submitCreateDocUp = (data) => {
    Fetch.createWarehous(data)
      .then((res) => {
        if (res.code === 0) {
          message.success('添加上架信息成功')
          closeAddDocModal()
          setRefetch(!reFetch)
        } else {
          message.error('添加上架信息失败')
          closeAddDocModal()
        }
      })
      .catch((err) => {
        console.log(err)
        message.error('添加上架信息失败')
      })
  }
  // 打开上架模态框
  const openDocUploadModal = (data) => {
    setShowDocUploadModal(true)
    setSelectedObj(data)
  }
  // 关闭上架模态框
  const closeDocUploadModal = () => {
    setShowDocUploadModal(false)
    setSelectedObj({})
  }
  // 上架提交
  const submitDocUpload = (data) => {
    const { upTime } = data
    const postData = Object.assign(data, {
      upTime: upTime.format('YYYY-MM-DD hh:mm:ss'),
      warehousId: selectedObj.Id,
    })
    Fetch.createWarehousUP(postData)
      .then((res) => {
        if (res.code === 0) {
          message.success('上架成功')
          closeDocUploadModal()
        } else {
          message.error('上架失败')
        }
      })
      .catch((err) => {
        console.log(err)
        closeDocUploadModal()
      })
  }
  // 完成上架
  const uploadDocDone = (data) => {
    Modal.confirm({
      title: '完成上架?',
      onOk: () => {
        Fetch.WarehousUpDone({
          status: 1,
          Id: data.Id,
        })
          .then((res) => {
            if (res.code === 0) {
              message.success('完成上架成功')
              setRefetch(!reFetch)
            } else {
              message.error('完成上架请求失败')
            }
          })
          .catch((err) => {
            console.log(err)
            message.success('请求错误')
          })
      },
    })
  }
  // 打开上架详情模态框
  const openDocUpDetailModal = (data) => {
    setSelectedObj(data)
    setShowDocUpDetialModal(true)
  }
  const closeDocDetailModal = () => {
    setSelectedObj({})
    setShowDocUpDetialModal(false)
  }
  // 翻页
  const pageChange = (page) => {
    setCurrentPage(page)
    setRefetch(!reFetch)
  }
  return (
    <div>
      <div className="toolbar" style={{ margin: '20px 0', padding: '20px 0' }}>
        <Button
          type="primary"
          style={{ float: 'right' }}
          onClick={openAddDocModal}
        >
          新增入库
        </Button>
      </div>
      <div className="table">
        <Card>
          <Table
            dataSource={dataSource}
            rowKey={(item) => item.Id}
            columns={columns}
            pagination={{
              pageSize: 20,
              current: currentPage,
              total: total,
              onChange: pageChange
            }}
          />
        </Card>
      </div>
      {showDocaddModal ? (
        <DocAddModal
          onOk={submitCreateDocUp}
          visible={showDocaddModal}
          onCancel={closeAddDocModal}
        />
      ) : null}
      {showDocUploadModal ? (
        <DocUploadModal
          visible={showDocUploadModal}
          data={selectedObj}
          onOk={submitDocUpload}
          onCancel={closeDocUploadModal}
        />
      ) : null}
      {showDocUpDetailModal ? (
        <DocUpDetailModal
          data={selectedObj}
          visible={showDocUpDetailModal}
          onCancel={closeDocDetailModal}
        />
      ) : null}
    </div>
  )
}
export default DocUp
