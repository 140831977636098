import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Modal, message, Form, Select, Icon, TreeSelect, Input } from 'antd'
import Fetch from '../../fetch/axios'
import * as addressActions from '../../store/address/actions'
const DocAddModal = (props) => {
  // 单位列表
  const [unitList, setUnitList] = useState([])
  // 当前选择的存档单位id
  const [thisSelectedUnitId, setThisSelectedUnitId] = useState('')
  // 部门列表
  const [departmentList, setDepartmentList] = useState([])
  const [treeData, setTreeData] = useState([])
  // 选择档案馆的id
  const [parentId, setParentId] = useState(null)
  // 获取单位列表
  useEffect(() => {
    getUnitList(parentId)
  }, [parentId])
  // 监听选择单位的id
  useEffect(() => {
    const id = props.form.getFieldValue('unitId')
    if (id !== thisSelectedUnitId) {
      setThisSelectedUnitId(id)
      props.form.setFieldsValue({ deptId: '' })
    }
  }, [props.form.getFieldValue('unitId')])
  // 获取部门列表
  useEffect(() => {
    console.log(thisSelectedUnitId)
    Fetch.getDepartmentList({ unitId: thisSelectedUnitId })
      .then((res) => {
        if (res.code === 0) {
          setDepartmentList(res.data)
        } else {
          message.error('获取部门列表失败')
          props.close()
        }
      })
      .catch((err) => {
        console.log(err)
        message.error('获取部门列表失败')
        props.close()
      })
  }, [thisSelectedUnitId])
  // 获取地址列表
  useEffect(() => {
    props.getAddressList()
  }, [])
  // 获取单位列表
  const getUnitList = (addressIds = null) => {
    Fetch.getUnitList({ addressIds })
      .then((res) => {
        if (res.code === 0) {
          setUnitList(res.data.rows)
        } else {
          message.error('请求单位列表失败')
        }
      })
      .catch((err) => {
        console.log(err)
        message.error('请求单位列表失败')
      })
  }
  const handelNodeSelect = (value, label, extra) => {
    const parentId = extra.triggerNode.props.dataRef.Id
    setParentId([parentId])
  }
  // 渲染地址树
  const renderTreeNode = (data) => {
    const iconTypeMap = ['apartment', 'home', 'shop']
    if (data) {
      return data.map((item) => {
        const icon = iconTypeMap[item.Type]
        if (Array.isArray(item.treeNode)) {
          return (
            <TreeSelect.TreeNode
              title={item.Name}
              key={item.Id}
              dataRef={item}
              isLeaf={item.Type === 1}
              value={item.Id}
              selectable={item.Type === 1}
              icon={<Icon type={icon} />}
            >
              {renderTreeNode(item.treeNode)}
            </TreeSelect.TreeNode>
          )
        }
        return (
          <TreeSelect.TreeNode
            title={item.Name}
            key={item.Id}
            value={item.Id}
            dataRef={item}
            isLeaf={item.Type === 1}
            selectable={item.Type === 1}
          />
        )
      })
    }
    return null
  }
  // 展开子节点的回调函数
  const onLoadData = (treeNode) =>
    new Promise((resolve) => {
      if (treeNode.props.children) {
        resolve()
        return
      }
      Fetch.getAddressList({
        nodeId: treeNode.props.dataRef.Id,
        recursion: false,
      }).then((res) => {
        treeNode.props.dataRef.treeNode = res.data === [] ? null : res.data
        setTreeData([])
        resolve()
      })
    })
  const { getFieldDecorator, getFieldsValue } = props.form
  const formItemLayout = {
    labelCol: {
      span: 4,
    },
    wrapperCol: {
      span: 18,
    },
  }
  return (
    <Modal
      visible={true}
      title="新增入库"
      onOk={() => {
        props.onOk(getFieldsValue())
      }}
      onCancel={props.onCancel}
    >
      <Form {...formItemLayout}>
        <Form.Item label="所属馆库">
          {getFieldDecorator('archiveId')(
            <TreeSelect loadData={onLoadData} onChange={handelNodeSelect}>
              {renderTreeNode(props.addressList)}
            </TreeSelect>
          )}
        </Form.Item>
        <Form.Item label="所属单位">
          {getFieldDecorator('unitId')(
            <Select placeholder="所属单位" allowClear>
              {unitList.map((item) => (
                <Select.Option value={item.Id} key={item.Id}>
                  {item.Name}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item label="所属部门">
          {getFieldDecorator('deptId')(
            <Select placeholder="所属部门">
              {departmentList.map((item) => (
                <Select.Option value={item.Id} key={item.Id}>
                  {item.Name}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item label="数量">
          {getFieldDecorator('numStr')(<Input />)}
        </Form.Item>
      </Form>
    </Modal>
  )
}
const mapStateToProps = (state) => ({
  addressList: state.address.addressList,
})
const mapDispatchToProps = (dispatch) => ({
  // 获取地址列表
  getAddressList: () => {
    dispatch(addressActions.getAddressList())
  },
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create({ name: 'doc-upload-form' })(DocAddModal))
