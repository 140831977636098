import * as types from './action-types'
// 设置用户登录状态
export const setUserLoginState = (state) => {
  return {
    type: types.SET_LOGIN_STATE,
    data: state,
  }
}
// 切换用户信息显示状态
export const toggleUserinfoDisplayState = () => {
  return {
    type: types.TOGGLE_USERINFO_DISPLAY_STATE,
  }
}
// 切换菜单展开和收起的状态
export const toggleMenuExpandState = () => {
  return {
    type: types.TOGGLE_MENU_EXPAND_STATE,
  }
}
// 设置用户信息
export const setUserInfo = (data) => {
  return {
    type: types.SET_USER_INFO,
    data,
  }
}
export const setRequestCount = (count) => {
  return {
    type: types.SET_REQUEST_COUNT,
    data: count,
  }
}
export const setSpinning = state => {
  return {
    type: types.SET_SPINNING,
    data: state,
  }
}