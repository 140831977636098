import * as types from './action-types'
// 获取地址列表数据
export const getAddressList = () => {
  return {
    type: types.GET_ADDRESS_LIST
  }
}
// 设置地址列表数据
export const setAddressList = data => {
  return {
    type: types.SET_ADDRESS_LIST,
    data
  }
}
// 设置当前选择的地点的id值
export const setAddressInfo = payload => {
  return {
    type: types.SET_SELECTED_ADDRESS_INFO,
    data: payload
  }
}
// 获取档案架信息
export const getShelfList = payload => {
  return {
    type: types.GET_SHELF_LIST,
    data: payload
  }
}
// 设置档案架信息
export const setShelfList = data => {
  return {
    type: types.SET_SHELF_LIST,
    data
  }
}
// 获取档案架单元格信息
export const getShelfCellList = data => {
  return {
    type: types.GET_SHELF_CELL_LIST,
    data
  }
}
// 设置档案架单元格信息
export const setShelfCellList = data => {
  return {
    type: types.SET_SHELF_CELL_LIST,
    data
  }
}
// 获取选择档案架的信息
export const getSelectedShelfInfo = () => {
  return {
    type: types.GET_SELECTED_SHELF_INFO
  }
}
// 设置选择档案架的信息
export const setSelectedShelfInfo = data => {
  return {
    type: types.SET_SELECTED_SHELF_INFO,
    data
  }
}
// 获取tab的key值
export const getTabKey = () => {
  return {
    type: types.GET_TAB_KEY
  }
}
// 设置tab的key值
export const setTabKey = data => {
  return {
    type: types.SET_TAB_KEY,
    data
  }
}
// 设置架子单元格容量编辑模态框的显示状态
export const setShelfCellEditModalVisible = data => {
  return {
    type: types.SET_SHELF_CELL_EDIT_MODAL_VISIBLE,
    data
  }
}
// 设置当前正在编辑的单元格的信息
export const setEditingCellInfo = data => {
  return {
    type: types.SET_EDITING_SHELF_CELL_INFO,
    data
  }
}
// 设置page的数据数
export const setTableRowCount = page => {
  return {
    type: types.SET_ROWCOUNT,
    data: page
  }
}
// 设置当前页号
export const setTablePageIndex = payload => {
  return {
    type: types.SET_PAGEINDEX,
    data: payload
  }
}
export const resetPageIndex = () => {
  return {
    type: types.RESET_PAGEINDEX
  }
}
