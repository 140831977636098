import React from 'react'
import { connect } from 'react-redux'
import { Modal, Form, Input } from 'antd'
import * as addressActions from '../../../store/address/actions'
import Fetch from '../../../fetch/axios'
const Index = props => {
  const { getFieldDecorator } = props.form
  const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 }
  }
  return (
    <Modal
      visible={props.visible}
      title={'修改容量'}
      width="300px"
      onCancel={props.closeModal}
      onOk={() => {
        const num = props.form.getFieldValue('num')
        const form = JSON.parse(JSON.stringify(props.editingCellInfo))
        form.Capacity = parseInt(num)
        Fetch.updateShelfCell(form).then(res => {
          if (res.code === 0) {
            props.closeModal()
            props.getShelfCellList(props.selectedShelfInfo.Id)
          }
        })
      }}
    >
      <Form {...formItemLayout}>
        <Form.Item label="容量">
          {getFieldDecorator('num', {
            rules: [{ required: true, message: '容量不能为空' }]
          })(<Input type="number" />)}
        </Form.Item>
      </Form>
    </Modal>
  )
}
const mapStateToProps = state => ({
  // 当前编辑的单元格的信息
  editingCellInfo: state.address.editCellInfo,
  // 当前选择档案架的信息
  selectedShelfInfo: state.address.selectedShelfInfo
})

const mapDispatchToProps = dispatch => {
  return {
    // 关闭模态框
    closeModal: () => {
      dispatch(addressActions.setShelfCellEditModalVisible(false))
      dispatch(addressActions.setEditingCellInfo({}))
    },
    // 刷新当前单元格信息
    getShelfCellList: id => {
      dispatch(addressActions.getShelfCellList(id))
    }
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create({ name: 'shelfcell-edit-modal' })(Index))
