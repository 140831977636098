import React, { useState, useEffect } from 'react'
import { Modal, Form, Input, DatePicker, message, Select } from 'antd'

import Fetch from '../../fetch/axios'
const DocUploadModal = (props) => {
  // 档案架列表
  const [shelfList, setShelfList] = useState([])
  // 库房列表
  const [storeList, setStoreList] = useState([])
  // 所选库房id
  const [storeId, setStoreId] = useState(null)
  // 获取库房列表
  useEffect(() => {
    Fetch.getAddressList({
      nodeId: props.data.archiveId,
      recursion: false,
    })
      .then((res) => {
        if (res.code === 0) {
          setStoreList(res.data)
        } else {
          message.error('获取仓库地址失败')
        }
      })
      .catch((err) => {
        console.log(err)
        message.error('获取仓库地址失败')
      })
  }, [props.data])
  // 获取档案架数据
  useEffect(() => {
    Fetch.getShelfListById({
      addressId: storeId,
    })
      .then((res) => {
        if (res.code === 0) {
          setShelfList(res.data)
        } else {
          message.error('获取档案架数据失败')
        }
      })
      .catch((err) => {
        console.log(err)
        message.error('请求错误')
      })
  }, [storeId])
  // 处理库房选择
  const handleStoreChange = (value) => {
    setStoreId(value)
  }
  const { getFieldDecorator, getFieldsValue } = props.form
  const formItemLayout = {
    labelCol: {
      span: 4,
    },
    wrapperCol: {
      span: 18,
    },
  }
  return (
    <Modal
      visible={props.visible}
      title="上架"
      onOk={() => {
        props.onOk(getFieldsValue())
      }}
      onCancel={props.onCancel}
    >
      <Form {...formItemLayout}>
        <Form.Item label="上架人">
          {getFieldDecorator('upUser')(<Input />)}
        </Form.Item>
        <Form.Item label="上架时间">
          {getFieldDecorator('upTime')(<DatePicker />)}
        </Form.Item>
        <Form.Item label="上架库房">
          {getFieldDecorator('storeId')(
            <Select onChange={handleStoreChange}>
              {storeList.map((item) => (
                <Select.Option key={item.Id} value={item.Id}>
                  {item.Name}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item label="上架位置">
          {getFieldDecorator('shelfBaseId')(
            <Select>
              {shelfList.map((item) => (
                <Select.Option key={item.Id} value={item.Id}>
                  {`${item.Plane === 1 ? 'B面' : 'A面'}-${item.Name}`}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item label="占用空间">
          {getFieldDecorator('useSpace')(<Input />)}
        </Form.Item>
      </Form>
    </Modal>
  )
}
export default Form.create({ name: 'doc-upload-modal' })(DocUploadModal)
