import React, { useState, useEffect } from 'react'
import { Modal, Table } from 'antd'
import Fetch from '../../fetch/axios'
const DocUpDetailModal = (props) => {
  const [detailList, setDetailList] = useState([])
  // 当前页码
  const [currentPage, setCurrentPage] = useState(1)
  // 总数据条数
  const [total, setTotal] = useState(1)
  //  获取数据的标志位
  const [reFetchData, setReFetchData] = useState(true)
  const columns = [
    {
      title: '操作人',
      dataIndex: 'upUser',
      key: 'upUser',
      align: 'center',
    },
    {
      title: '日期',
      dataIndex: 'upTime',
      key: 'upTime',
      align: 'center',
    },
    {
      title: '上架位置',
      dataIndex: 'shelfBaseName',
      key: 'shelfBaseName',
      align: 'center',
    },
    {
      title: '占用空间',
      dataIndex: 'useSpace',
      key: 'useSpace',
      align: 'center',
    },
  ]
  useEffect(() => {
    Fetch.getWarehousUPList({
      warehousId: props.data.Id,
      pageIndex: currentPage,
    }).then((res) => {
      if (res.code === 0) {
        setTotal(res.data.rowCount)
        setDetailList(res.data.rows)
      }
    })
  }, [reFetchData])
  // 处理表格的翻页
  const handleTableChange = (page, pageSize) => {
    setCurrentPage(page)
    setReFetchData(!reFetchData)
  }
  return (
    <Modal
      visible={props.visible}
      title="上架详情"
      onOk={props.onCancel}
      onCancel={props.onCancel}
    >
      <Table
        dataSource={detailList}
        columns={columns}
        rowKey={(item) => item.Id}
        pagination={{
          total: total,
          current: currentPage,
          onChange: handleTableChange,
        }}
      />
    </Modal>
  )
}
export default DocUpDetailModal
