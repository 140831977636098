import UserManage from '../../view/UserManage'
import RoleManage from '../../view/RoleManage'
import AddressManage from '../../view/AddressManage'
import StorageUnitManage from '../../view/StorageUnitManage'
import DocumentInfo from '../../view/DocumentInfo'
import BorrowRequestList from '../../view/BorrowRequestList'
import DepartmentManage from '../../view/DepartmentManage'
import DocUp from '../../view/DocUp'
import SystemConfig from '../../view/SystemConfig'
import ASD from '../../view/ASD'
export default {
  data: [
    {
      title: '档案架管理',
      key: '/index/addressmanage',
      icon: 'environment',
      component: AddressManage
    },
    {
      title: '存档单位管理',
      key: '/index/storageunitmanage',
      icon: 'solution',
      component: StorageUnitManage
    },
    {
      title: '档案信息管理',
      key: '/index/docstatic',
      icon: 'database',
      component: DocumentInfo
    },
    {
      title: '用户管理',
      key: '/index/usermanage',
      icon: 'user',
      component: UserManage
    },
    {
      title: '角色管理',
      key: '/index/rolemanage',
      icon: 'team',
      component: RoleManage
    },
    {
      title: '调阅请求',
      key: '/index/borrowRequestList',
      icon: 'file-protect',
      component: BorrowRequestList
    },
    {
      title: '部门管理',
      key: '/index/departmentManage',
      icon: 'team',
      component: DepartmentManage
    },
    {
      title: '物料管理',
      key: '/index/asd',
      icon: 'inbox',
      component: ASD
    },
    {
      title: '档案上架',
      key: '/index/docup',
      icon: 'bars',
      component: DocUp
    },
    {
      title: '系统设置',
      key: '/index/systemConfig',
      icon: 'tool',
      component: SystemConfig
    }
  ]
}
