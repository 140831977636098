import React, { useState, useEffect } from 'react'
import { Modal, message, Table } from 'antd'
import Fetch from '../../fetch/axios'
const SuppliesLogs = props => {
  // 日志数据
  const [logList, setLogList] = useState([])
  // 当前页码
  const [currentPage, setCurrentPage] = useState(1)
  // 总数据条数
  const [total, setTotal] = useState(1)
  //  获取数据的标志位
  const [reFetchData, setReFetchData] = useState(true)
  // 获取日志数据
  useEffect(() => {
    getLogs()
  }, [props.id, reFetchData])
  const columns = [
    {
      title: '操作人',
      dataIndex: 'createUserName',
      key: 'createUserName',
      align: 'center'
    },
    {
      title: '日期',
      dataIndex: 'createTime',
      key: 'createTime',
      align: 'center'
    },
    {
      title: '类型',
      dataIndex: 'type',
      key: 'type',
      align: 'center'
    },
    {
      title: '数量',
      dataIndex: 'used',
      key: 'used',
      align: 'center'
    },
    {
      title: '原因',
      dataIndex: 'reason',
      key: 'reason',
      align: 'center'
    }
  ]
  // 获取数据
  const getLogs = () => {
    Fetch.suppliesLogs(
      { materielId: props.data.Id },
      {
        pageIndex: currentPage
      }
    ).then(res => {
      if (res.code === 0) {
        setTotal(res.data.rowCount)
        setLogList(res.data.rows)
      } else {
        message.error('获取信息失败')
        props.onCancel()
      }
    })
  }
  // 处理表格的翻页
  const handleTableChange = (page, pageSize) => {
    setCurrentPage(page)
    setReFetchData(!reFetchData)
  }
  return (
    <Modal visible={props.visible} title="出入库记录" onCancel={props.onCancel}>
      <Table
        dataSource={logList}
        columns={columns}
        rowKey={item => item.Id}
        pagination={{
          total: total,
          current: currentPage,
          onChange: handleTableChange
        }}
      />
    </Modal>
  )
}
export default SuppliesLogs
