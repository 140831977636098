import React, { Component } from 'react'
import { Modal, Form, Input, Select, message } from 'antd'
import './index.css'
import Fetch from '../../../fetch/axios'
class index extends Component {
  // 当前选择的角色类型和单位列表
  state = {
    Type: '',
    unitList: []
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const type = this.props.form.getFieldValue('Type')
    this.setState({
      Type: type
    })
  }
  componentDidMount() {
    Fetch.getUnitList({})
      .then(res => {
        if (res.success === true) {
          this.setState({
            unitList: res.data.rows
          })
        } else {
          message.error('请求单位列表失败')
        }
      })
      .catch(err => {
        console.log(err)
        message.error('请求单位列表失败')
      })
  }
  render() {
    const { getFieldDecorator, getFieldsValue } = this.props.form
    const formData = this.props.data === null ? {} : this.props.data
    const { Name = '', Description = '', Type = 0, unitId = '' } = formData
    // 表格项布局
    const formItemLayout = {
      labelCol: {
        span: 4
      },
      wrapperCol: {
        span: 18
      }
    }
    return (
      <Modal
        visible={this.props.visible}
        title={this.props.title}
        onCancel={this.props.onCancel}
        onOk={() => this.props.onOk(getFieldsValue())}
      >
        <Form {...formItemLayout}>
          <Form.Item label='角色名'>
            {getFieldDecorator('Name', {
              initialValue: Name
            })(<Input />)}
          </Form.Item>
          <Form.Item label='描述'>
            {getFieldDecorator('Description', {
              initialValue: Description
            })(<Input />)}
          </Form.Item>
          <Form.Item label='类型'>
            {getFieldDecorator('Type', {
              initialValue: Type
            })(
              <Select>
                <Select.Option value={0}>系统管理员</Select.Option>
                <Select.Option value={1}>档案管理员</Select.Option>
                <Select.Option value={2}>存档单位管理员</Select.Option>
                <Select.Option value={3}>存档单位普通用户</Select.Option>
              </Select>
            )}
          </Form.Item>
          {this.state.Type === 2 || this.state.Type === 3 ? (
            <Form.Item label='单位'>
              {getFieldDecorator('unitId', {
                initialValue: unitId
              })(
                <Select placeholder='所属单位'>
                  {this.state.unitList.map(item => (
                    <Select.Option value={item.Id} key={item.Id}>
                      {item.Name}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          ) : null}
        </Form>
      </Modal>
    )
  }
}
export default Form.create({ name: 'role-modal' })(index)
