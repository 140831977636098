import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Col, Card, Table, Input, Button, message } from 'antd'
import InputColor from 'react-input-color'
import colorTable from '../assets/scripts/colorTable.json'
import Fetch from '../fetch/axios'
import ChangePassForm from '../components/SystemConfig/ChangePassForm'
export class SystemConfig extends Component {
  state = {
    dataSource: [],
    pass: '',
    repeat: '',
  }
  columns = [
    {
      title: '名称',
      key: 'name',
      dataIndex: 'name',
      align: 'center',
    },
    {
      title: '颜色',
      render: (record) => (
        <InputColor
          initialHexColor={record.value}
          onChange={(color) => this.handleChange(color, record)}
        />
      ),
      align: 'center',
    },
  ]
  handleChange = (color, record) => {
    const key = '--' + record['key']
    console.log(key, color.hex)
    document.body.style.setProperty(key, color.hex)
  }
  changePass = () => {
    if (this.state.pass === this.state.repeat) {
      Fetch.ChangePassword({
        id: this.props.userId,
        password: this.state.pass,
        oldpassword: this.state.oldpassword,
      }).then((res) => {
        console.log(res)
      })
    } else {
      message.error('密码不一致')
    }
  }
  componentDidMount() {
    this.setState({
      dataSource: colorTable.arr,
    })
  }
  render() {
    return (
      <div>
        {/*<Col span={12}>*/}
        {/*  <Card title="颜色配置">*/}
        {/*    <Table*/}
        {/*      dataSource={this.state.dataSource}*/}
        {/*      columns={this.columns}*/}
        {/*      pagination={{*/}
        {/*        defaultPageSize: 5,*/}
        {/*      }}*/}
        {/*    />*/}
        {/*  </Card>*/}
        {/*</Col>*/}
        <Col span={12}>
          <Card title="密码修改">
            <ChangePassForm />
          </Card>
        </Col>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  userId: state.global.userInfo.Id,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(SystemConfig)
