/*
 * Project: project
 * File Created: 2019-10-15
 * Author: zhoud
 * Last Modified: 2019-10-15
 * Modified By: zhoud
 * Desc: 地址管理模态框
 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import './index.css'
import { Modal, Form, Input } from 'antd'
export class index extends Component {
  state = {
    loading: false
  }
  render() {
    const { getFieldDecorator, getFieldsValue } = this.props.form
    const formItemLayout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 20 }
    }
    return (
      <Modal
        visible={this.props.visible}
        title={this.props.title}
        onCancel={this.props.onCancel}
        onOk={() => {
          this.setState({
            loading: true
          })
          this.props.onOk(getFieldsValue()).then(res =>
            this.setState({
              loading: false
            })
          )
        }}
        confirmLoading={this.state.loading}
      >
        <Form {...formItemLayout}>
          <Form.Item label="收件人">
            {getFieldDecorator('Consignee', {})(<Input />)}
          </Form.Item>
          <Form.Item label="地址">
            {getFieldDecorator('Address', {})(<Input />)}
          </Form.Item>
          <Form.Item label="联系方式">
            {getFieldDecorator('Contact', {})(<Input />)}
          </Form.Item>
        </Form>
      </Modal>
    )
  }
}

const mapStateToProps = state => ({})

const mapDispatchToProps = {}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create({ name: 'send-address-modal' })(index))
