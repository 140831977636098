/*
 * Project: project
 * File Created: 2019-08-06
 * Author: zhoud
 * Last Modified: 2019-08-06
 * Modified By: zhoud
 * Desc: logo组件
 */
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import './index.css'
import { Icon } from 'antd'
import * as globalActions from '../../../store/global/actions'
export class index extends Component {
  render() {
    return (
      <div className="logo">
        <Icon type="menu" onClick={this.props.toggleMenuExpandState} style={{fontSize:'15px'}}></Icon>
        {!this.props.menuToggleState ? (
          <Fragment>
            <span
              onClick={this.props.toggleUserinfoDisplayState}
              style={{ cursor: 'pointer' }}
            >
              江苏润银档案
            </span>
          </Fragment>
        ) : null}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  menuToggleState: state.global.menuExpandState
})

const mapDispatchToProps = dispatch => ({
  // 切换用户信息的显示和隐藏
  toggleUserinfoDisplayState: () => {
    dispatch(globalActions.toggleUserinfoDisplayState())
  },
  // 切换菜单的展开和折叠
  toggleMenuExpandState: () => {
    dispatch(globalActions.toggleMenuExpandState())
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(index)
