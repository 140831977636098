import React, { useState, useEffect } from 'react'
import Fetch from '../fetch/axios'
import { List, Card, Table, Descriptions, Button, Modal } from 'antd'
const RequestDetail = (props) => {
  const [data, setData] = useState({ fields: [] })
  const [historyList, setHistoryList] = useState([])
  const [showAttachments, setShowAttachments] = useState(false)
  const [attachmentsList, setAttachmentsList] = useState([])
  // 获取请求的详情
  useEffect(() => {
    let search = props.history.location.search
    search = search.substr(1, search.length)
    const searchObj = JSON.parse(decodeURI(search))
    const id = searchObj
    Fetch.getBorrowRequestDetail({
      id,
    }).then((res) => {
      if (res.success === true) {
        setData(res.data)
      }
    })
  }, [])
  // 获取请求的日志
  useEffect(() => {
    let search = props.history.location.search
    search = search.substr(1, search.length)
    const searchObj = JSON.parse(decodeURI(search))
    const id = searchObj
    Fetch.getBorrowRequestHistory(
      { requestId: id },
      {
        pageIndex: 1,
        pageSize: 2000,
      }
    ).then((res) => {
      if (res.code === 0) {
        setHistoryList(res.data.rows)
      }
    })
  }, [])
  const exportExcel = () => {
    let search = props.history.location.search
    search = search.substr(1, search.length)
    const searchObj = JSON.parse(decodeURI(search))
    const id = searchObj
    Fetch.exportReqExcel({
      id,
    }).then((res) => {
      const blob = new Blob([res]);
      const blobUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.download = `档案借阅详情_${new Date().getTime()}.xls`;
      a.href = blobUrl;
      a.click();
    })
  }
  return (
    <div style={{ width: '100%', height: '100%', overflow: 'auto' }}>
      <div style={{ margin: '10vh auto', maxWidth: '50%' }}>
        <Descriptions bordered column={2}>
          <Descriptions.Item label="创建人">
            {data.createUserName}
          </Descriptions.Item>
          <Descriptions.Item label="借阅人">
            {data.BorrowUserId}
          </Descriptions.Item>
          <Descriptions.Item label="单位名称" span={2}>
            {data.unitName}
          </Descriptions.Item>
          <Descriptions.Item label="创建时间">
            {data.CreateTime}
          </Descriptions.Item>
          <Descriptions.Item label="完结时间">
            {data.ReturnedTime}
          </Descriptions.Item>
        </Descriptions>
        <Card title="请求列表"
              style={{ width: '100%' }}
              extra={ <Button type="primary" onClick={exportExcel}>导出列表</Button> }>
          <Table
            dataSource={data.archDetailedInfos}
            columns={[
              {
                title: '序号',
                dataIndex: 'BorrowSn',
                key: 'BorrowSn',
                align: 'center',
              },
              {
                title: '位置',
                dataIndex: 'cellFullName',
                key: 'cellFullName',
                align: 'center',
              },
              {
                title: '借阅状态',
                render: record => (
                  <span style={record.isReturn === 0 ? {color: '#1890ff'} : record.isReturn === 1 ? {color: '#ff9900'} : record.isReturn === -1 ? {color: '#fc0000'} : record.isReturn === 4 ? {color: '#07ba2e'} : {color: '#fc0000'}} >{record.isReturn === 0 ? '审核中' : record.isReturn === 1 ? '借阅中' : record.isReturn === -1 ? '已驳回' : record.isReturn === 4 ? '已归还' : '丢失'}</span>
                ),
                align: 'center',
              },
              ...data.fields.map((item) => ({
                title: item.Name,
                dataIndex: item.Id,
                key: item.Id,
                align: 'center',
              })),
            ]}
            rowKey={(item) => item.Id}
            pagination={{ pageSize: 2000, hideOnSinglePage: true }}
            scroll={{ x: 'max-content' }}
          />
        </Card>
        <Card title="请求详情" style={{ width: '100%' }}>
          <List itemLayout="horizontal"
                dataSource={historyList}
                renderItem={(item) => (
                  <List.Item>
                    <List.Item.Meta title={item.Content} />
                    {(item.Attachments && item.Attachments.length > 0) ? (<Button type="link" onClick={ ()=> {setAttachmentsList(item.Attachments); setShowAttachments(true);} } >查看附件</Button>) : null}
                    <div style={{ marginLeft: '5px' }}>{item.Time}</div>
                  </List.Item>)} />
        </Card>
        {showAttachments ? (
          <Modal visible={showAttachments} centered={true}
                 title="附件详情"
                 cancelButtonProps={{ style: { display: 'none' } }}
                 onOk={()=>{ setShowAttachments(false); }}
                 onCancel={()=>{ setShowAttachments(false); }}>
            <List itemLayout="horizontal"
                  dataSource={attachmentsList}
                  renderItem={(item) => (
              <List.Item>
                <a href={`${window.serverIp}:${window.serverPort}/api/v1/file/download?id=${item.Id}&disposition=inline`} target={'_blank'}>{item.Name}</a>
                <a href={`${window.serverIp}:${window.serverPort}/api/v1/file/download?id=${item.Id}`}>下载</a>
              </List.Item>)} />
          </Modal>
        ) : null}
      </div>
    </div>
  )
}
export default RequestDetail
