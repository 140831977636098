/*
 * Project: project
 * File Created: 2019-08-06
 * Author: zhoud
 * Last Modified: 2019-08-06
 * Modified By: zhoud
 * Desc: 登录页面组件
 */
import React, {Component} from 'react'
import LoginForm from '../components/Login/LoginForm'
import Logo from '../assets/img/logoicon.png'
import backgroundImg from '../assets/img/background.jpg'

export default class Login extends Component {
    render() {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    height: '100%',
                    position: 'relative',
                    background: `url(${backgroundImg}) 0 / cover fixed`,
                    border: '.1px solid transparent',
                }}
            >
                <div style={{width: '50%'}}>
                    <div
                        style={{
                            margin: '15vh auto',
                            display: 'block',
                            textAlign: 'center',
                        }}
                    >
                        <img
                            src={Logo}
                            alt=""
                            style={{borderRadius: '5px', width: '8vw'}}
                        />
                        <div
                            style={{
                                fontFamily: 'KaiTi',
                                marginTop: '5vh',
                                fontSize: '50px',
                                color: 'black',
                                fontWeight: 'bold',
                            }}
                        >
                            江苏润银档案管理咨询有限公司
                        </div>
                        <div
                            style={{
                                fontFamily: 'KaiTi',
                                marginTop: '1vh',
                                fontSize: '40px',
                                color: 'black',
                            }}
                        >
                            档案云端管理系统
                        </div>
                    </div>
                </div>
                <div style={{width: '50%', position: 'relative'}}>
                    <LoginForm/>
                </div>
            </div>
        )
    }
}
