/*
 * Project: project
 * File Created: 2019-09-20
 * Author: zhoud
 * Last Modified: 2019-09-23
 * Modified By: zhoud
 * Desc: 存档单位管理页面
 */
import React, {Component, Fragment} from 'react'
import {connect} from 'react-redux'
import {Button, Card, message, Modal, Table, Tag} from 'antd'
import * as unitActions from '../store/unit/actions'
import UnitForm from '../components/StorageUnit/UnitForm'
import TemplateModal from '../components/StorageUnit/TemplateForm'
import Fetch from '../fetch/axios'

export class StorageUnitManage extends Component {
    state = {
        // 显示新增/编辑存档单位的模态框
        showModal: false,
        // 新增/编辑存档单位模态框标题
        modalTitle: '',
        // 当前选择单位的数据对象
        unitObj: null,
        // 勾选单位的信息列表
        selectedItems: [],
        selectedRowKeys: [],
        // 模态框的loading状态
        modalLoading: false,
        // 当前页面
        currentPage: 1,
        // 总数据条目
        total: 1,
        // 每一页条目数
        pageSize: 20
    }

    // 打开新增模态框
    openAddForm = () => {
        this.setState({
            showModal: true,
            modalTitle: '新增存档单位'
        })
    }

    // 打开编辑模态框
    openEditForm = () => {
        const selectedItems = this.state.selectedItems
        const data = JSON.parse(JSON.stringify(selectedItems[0]))
        try {
            data['AddressIds'] = data['AddressIds'].split(',')
        } catch (e) {
            message.warn('当前单位没有绑定地点')
        }
        if (selectedItems.length === 0) {
            message.error('请选择一个单位进行编辑')
        } else if (selectedItems.length > 1) {
            message.error('只能选择一个单位进行编辑')
        } else {
            this.setState({
                showModal: true,
                modalTitle: '编辑存档单位',
                unitObj: data
            })
        }
    }

    // 关闭模态框
    closeModal = () => {
        this.setState({
            showModal: false,
            modalTitle: '',
            modalLoading: false,
            unitObj: null
        })
    }

    // 提交表单
    submitForm = data => {
        console.log("data: ", data)
        this.setState({
            modalLoading: true
        })
        const ids = data['AddressIds']
        data['AddressIds'] = ids.join(',')
        if (this.state.unitObj === null) {
            // 新增
            Fetch.addUnit({Status: 0, ...data}).then(() => {
                this.props.getUnitList({
                    page: this.state.currentPage,
                    pageSize: this.state.pageSize
                })
                this.closeModal()
            })
        } else {
            // 更新
            Fetch.updateUnit(data).then(() => {
                this.setState(
                    {
                        unitObj: null,
                        selectedItems: [],
                        selectedRowKeys: []
                    },
                    function () {
                        this.props.getUnitList({
                            page: this.state.currentPage,
                            pageSize: this.state.pageSize
                        })
                        this.closeModal()
                    }
                )
            })
        }
    }

    // 表格勾选checkbox的事件
    onChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectedRowKeys,
            selectedItems: selectedRows
        })
    }

    // 翻页事件回调
    pageChange = (page, pageSize) => {
        this.props.setUnitTablePageIndex({pageIndex: page})
    }

    // 打开模板列表模态框
    openTemplateModal = record => {
        this.props.getTemplateList(record.Id)
        this.props.setUnitName(record.Name)
        this.props.setTemplateModalState(true)
        this.props.setUnitId(record.Id)
    }

    columns = [
        {
            title: '名称',
            dataIndex: 'Name',
            key: 'Name',
            align: 'center'
        },
        {
            title: '描述',
            dataIndex: 'Description',
            key: 'Description',
            align: 'center'
        },
        {
            title: '联络人',
            dataIndex: 'Liaison',
            key: 'Liaison',
            align: 'center'
        },
        {
            title: '联系电话',
            dataIndex: 'ContactNumber',
            key: 'ContactNumber',
            align: 'center'
        },
        {
            title: '联系邮件',
            dataIndex: 'Email',
            key: 'Email',
            align: 'center'
        },
        {
            title: '所属地点',
            key: 'addressIdNames',
            render: record => (
                <div>
                    {record['addressIdNames']
                        ? record['addressIdNames']
                            .split(',')
                            .map(item => <Tag key={item}>{item}</Tag>)
                        : null}
                </div>
            ),
            align: 'center'
        },
        {
            title: '状态',
            key: 'Status',
            render: record => (
                <div>
                    {record.Status === 0 ? (
                        <Tag color='geekblue' style={{margin: '0 auto'}}>
                            正常
                        </Tag>
                    ) : (
                        <Tag color='red' style={{margin: '0 auto'}}>
                            禁用
                        </Tag>
                    )}
                </div>
            ),
            align: 'center'
        },
        {
            title: '操作',
            render: record => (
                <div>
                    <Button
                        type='primary'
                        onClick={() => this.openTemplateModal(record)}
                    >
                        管理模板
                    </Button>
                </div>
            ),
            align: 'center'
        }
    ]

    componentDidMount() {
        this.props.getUnitList({})
        this.setState({
            total: this.props.unitList.rowCount
        })
    }

    render() {
        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onChange
        }
        return (
            <Fragment>
                <div className='buttons'>
                    <Button
                        type='primary'
                        style={{margin: '5px 10px'}}
                        onClick={this.openAddForm}
                    >
                        添加
                    </Button>
                    <Button
                        type='primary'
                        style={{margin: '5px 10px'}}
                        onClick={this.openEditForm}
                    >
                        编辑
                    </Button>
                    <Button
                        type='primary'
                        style={{margin: '10px'}}
                        onClick={() => {
                            // 删除勾选的用户
                            if (this.state.selectedItems.length > 0) {
                                Modal.confirm({
                                    title: `确定删除这${this.state.selectedItems.length}个单位?`,
                                    onOk: () => {
                                        Fetch.deleteUnit(
                                            this.state.selectedItems.map(item => item.Id)
                                        ).then(() => {
                                            this.props.getUnitList({
                                                page: this.currentPage,
                                                pageSize: this.state.pageSize
                                            })
                                        })
                                    }
                                })
                            }
                        }}
                    >
                        删除
                    </Button>
                </div>
                <Card>
                    <Table
                        columns={this.columns}
                        dataSource={this.props.unitList.rows.filter(
                            item => item.Name !== '默认'
                        )}
                        rowSelection={rowSelection}
                        rowKey={item => item.Id}
                        loading={this.props.loading}
                        pagination={{
                            onChange: this.pageChange,
                            pageSize: 20,
                            total: this.props.unitTableRowCount
                        }}
                    />
                </Card>
                {this.state.showModal ? (
                    <UnitForm
                        show={this.state.showModal}
                        title={this.state.modalTitle}
                        cancel={this.closeModal}
                        submit={data => this.submitForm(data)}
                        data={this.state.unitObj}
                        confirmLoading={this.state.modalLoading}
                    />
                ) : null}
                <TemplateModal/>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    // 存档单位列表
    unitList: state.unit.unitList,
    // 数据加载状态
    loading: state.unit.loading,
    unitTableRowCount: state.unit.unitTableRowCount,
    unitTablePageIndex: state.unit.unitTablePageIndex
})

const mapDispatchToProps = dispatch => {
    return {
        // 获取存档单位列表
        getUnitList: pageinfo => {
            dispatch(unitActions.getUnitList(pageinfo))
        },
        // 获取模板列表
        getTemplateList: id => {
            dispatch(unitActions.getTemplateList({id}))
        },
        // 切换模板模态框显示状态
        setTemplateModalState: state => {
            dispatch(unitActions.setTemplateModalState(state))
        },
        // 设置当前单位的id
        setUnitId: id => {
            dispatch(unitActions.setUnitId(id))
        },
        setUnitTablePageIndex: data => {
            dispatch(unitActions.setUnitTablePageIndex(data))
        },
        // 设置当前选中的存档单位名称
        setUnitName: name => {
            dispatch(unitActions.setThisUnitName(name))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StorageUnitManage)
