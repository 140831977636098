import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Col } from 'antd'
import UnitList from '../components/DocumentInfo/UnitList'
import DepartmentTable from '../components/DepartmentManage/DepartmentTable'
import * as unitActions from '../store/unit/actions'
export class DepartmentManage extends Component {
  componentDidMount() {
    this.props.getUnitList({})
  }
  render() {
    return (
      <div>
        <Col span={6}>
          <UnitList />
        </Col>
        <Col span={18}>
          <DepartmentTable />
        </Col>
      </div>
    )
  }
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => {
  return {
    // 获取存档单位列表
    getUnitList: pageinfo => {
      dispatch(unitActions.getUnitList(pageinfo))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DepartmentManage)
