/*
 * Project: project
 * File Created: 2019-09-20
 * Author: zhoud
 * Last Modified: 2019-09-23
 * Modified By: zhoud
 * Desc: 存档单位模板列表
 */
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Modal, Button, List, Spin } from 'antd'
import * as departmentActions from '../../../store/department/actions'
import * as unitActions from '../../../store/unit/actions'
import * as documentActions from '../../../store/documentinfo/actions'
import * as docActions from '../../../store/documentinfo/actions'
import TemplateFieldModal from '../TemplateFieldForm'
import Fetch from '../../../fetch/axios'
import { withRouter } from 'react-router-dom'
import './index.css'
export class index extends Component {
  state = {
    showTemplateFieldModal: false,
    modalTitle: '',
  }
  // 打开新建模板模态框
  openNewTemplateFieldModal = () => {
    this.setState({
      showTemplateFieldModal: true,
      modalTitle: '新增模板',
    })
  }
  // 修改模板
  openEditModal = (id, name) => {
    this.props.getTemplateDetail(id)
    this.props.setTemplateId(id)
    this.props.setThisTemplateName(name)
    setTimeout(() => {
      this.setState({
        showTemplateFieldModal: true,
        modalTitle: '修改模板',
      })
    }, 1000)
  }
  // 关闭模态框
  onCancel = () => {
    this.setState({
      showTemplateFieldModal: false,
    }, function () {
      this.props.setThisTemplateId('')
    })
  }
  // 删除模板
  deleteItem = (id) => {
    Fetch.deleteTemplate([id]).then((res) => {
      if (res.code === 0) {
        this.props.getTemplateList(this.props.unitId)
      }
    })
  }
  // 跳转
  goToDocPage = (item) => {
    this.props.setUnitId(item.ArchivingUnitId)
    this.props.getTemplateList(item.ArchivingUnitId)
    this.props.getDepartmentList(item.ArchivingUnitId)
    this.props.setDocList([])
    this.props.setThisTemplateName(item.Name)
    this.props.getDocList(item.Id)
    this.props.getTemplateDetail(item.Id)
    this.props.setTemplateId(item.Id)
    this.props.history.push('/index/docstatic')
    this.props.setThisUnitId('')
    this.props.setThisTemplateId('')
    this.props.setTemplateState(false)
  }
  render() {
    return (
      <Fragment>
        <Modal
          visible={this.props.showModal}
          title="模板管理"
          onCancel={() => {
            this.props.setTemplateState(false)
            this.props.setThisUnitId('')
            this.props.setUnitName('')
          }}
          onOk={() => this.props.setTemplateState(false)}
        >
          <Button type="primary" onClick={this.openNewTemplateFieldModal}>
            新增模板
          </Button>
          <Spin spinning={this.props.templateLoadingState} tip="数据获取中">
            <List
              renderItem={(item) => (
                <List.Item
                  actions={[
                    <span
                      style={{ color: 'blue' }}
                      onClick={() => this.openEditModal(item.Id, item.Name)}
                    >
                      编辑
                    </span>,
                    <span
                      style={{ color: 'blue' }}
                      onClick={() => this.deleteItem(item.Id)}
                    >
                      删除
                    </span>,
                    <span
                      style={{ color: 'blue' }}
                      onClick={() => this.goToDocPage(item)}
                    >
                      跳转
                    </span>,
                  ]}
                >
                  <List.Item.Meta
                    title={item.Name}
                    description={
                      <div style={{ display: 'flex' }}>
                        <div className="content" style={{ width: '90%' }}></div>
                      </div>
                    }
                  />
                </List.Item>
              )}
              dataSource={this.props.templateList}
            ></List>
          </Spin>
        </Modal>
        {this.state.showTemplateFieldModal ? (
          <TemplateFieldModal
            visible={this.state.showTemplateFieldModal}
            title={this.state.modalTitle}
            onCancel={this.onCancel}
          />
        ) : null}
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  // 存档单位列表
  showModal: state.unit.showTemplateModal,
  // 模板数据
  templateList: state.unit.templateList,
  // 单位id
  unitId: state.unit.unitId,
  templateLoadingState: state.unit.templateLoading,
})

const mapDispatchToProps = (dispatch) => {
  return {
    // 切换模态框显示状态
    setTemplateState: (state) => {
      dispatch(unitActions.setTemplateModalState(state))
    },
    // 获取模板详情
    getTemplateDetail: (id) => {
      dispatch(unitActions.getTemplateDetail(id))
    },
    // 设置当前模板id
    setTemplateId: (id) => {
      dispatch(unitActions.setTemplateId(id))
    },
    // 当前存档单位id
    setThisUnitId: (id) => {
      dispatch(unitActions.setUnitId(id))
    },
    // 当前模板id
    setThisTemplateId: (id) => {
      dispatch(unitActions.setTemplateId(id))
    },
    // 获取存档单位列表
    getUnitList: pageinfo => {
      dispatch(unitActions.getUnitList(pageinfo))
    },
    // 设置当前单位的id
    setUnitId: id => {
      dispatch(unitActions.setUnitId(id))
    },
    // 获取存档单位模板列表
    getTemplateList: id => {
      dispatch(unitActions.getTemplateList({ id }))
    },
    // 设置当前选中的存档单位名称
    setUnitName: name => {
      dispatch(unitActions.setThisUnitName(name))
    },
    // 获取部门列表
    getDepartmentList: id => {
      dispatch(departmentActions.getDepartmentList(id))
    },
    // 设置档案数据表
    setDocList: list => {
      dispatch(documentActions.setDocList(list))
    },
    // 设置当前模板名称
    setThisTemplateName: name => {
      dispatch(unitActions.setTemplateName(name))
    },
    // 获取档案数据
    getDocList: id => {
      dispatch(docActions.getDocList({ Id: id, keyWord: '', form: {} }))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(index))
