/*
 * Project: project
 * File Created: 2019-10-09
 * Author: zhoud
 * Last Modified: 2019-10-09
 * Modified By: zhoud
 * Desc: 档案信息管理页面
 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Col, Icon } from 'antd'
import InfoTable from '../components/DocumentInfo/InfoTable'
import TemplateList from '../components/DocumentInfo/TemplateList'
import UnitList from '../components/DocumentInfo/UnitList'
import * as unitActions from '../store/unit/actions'
export class DocumentInfo extends Component {
  state = {
    showLeftModal: true,
  }
  componentDidMount() {
    this.props.getUnitList({})
  }
  UNSAFE_componentWillReceiveProps(newProps, oldProps) {
    if (
      Object.keys(oldProps).length === 0 &&
      newProps.unitList &&
      newProps.unitList.rows.length > 0
    ) {
      // 如果第一次属性变化，且存档单位的数据已经发生了变化,获取第一个存档单位的模板
      // const firstUnit = newProps.unitList.rows[0]
      // this.props.getTemplateList(firstUnit.Id)
    }
  }
  changeLeftModal = (state = false) => {
    this.setState({
      showLeftModal: state,
    })
  }
  render() {
    return (
      <div>
        {this.state.showLeftModal ? (
          <Col span={6}>
            {this.props.unitList.rows ? (
              <div style={{ position: 'relative' }}>
                <div
                  style={{
                    width: '10px',
                    height: '100%',
                    position: 'absolute',
                    right: '0',
                    cursor: 'pointer',
                    background: '#999',
                  }}
                  onClick={() => {
                    this.changeLeftModal()
                  }}
                ></div>
                <UnitList />
                {(this.props.templateList && this.props.templateList.length > 0) ? (<TemplateList />) : null }
              </div>
            ) : null}
          </Col>
        ) : null}
        {this.state.showLeftModal ? null : (
          <Col span={1}>
            <Icon
              type="right-square"
              style={{ fontSize: '30px', cursor: 'pointer' }}
              onClick={() => {
                this.changeLeftModal(true)
              }}
            />
          </Col>
        )}
        <Col span={this.state.showLeftModal ? 18 : 23}>
          <InfoTable />
        </Col>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    // 存档单位列表
    unitList: state.unit.unitList,
    templateList: state.unit.templateList,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    // 获取存档单位列表
    getUnitList: (pageinfo) => {
      dispatch(unitActions.getUnitList(pageinfo))
    },
    // 获取模板列表
    getTemplateList: (id) => {
      dispatch(unitActions.getTemplateList({ id }))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentInfo)
