/*
 * Project: project
 * File Created: 2019-10-23
 * Author: zhoud
 * Last Modified: 2019-10-23
 * Modified By: zhoud
 * Desc:部门增加和删除的模态框
 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Modal, Form, Input } from 'antd'
export class index extends Component {
  render() {
    const { getFieldDecorator, getFieldsValue } = this.props.form
    const { Name = '', Remark = '', Id = '' } = this.props.data
    return (
      <Modal
        visible={this.props.visible}
        onOk={() =>
          this.props.onOk(Object.assign({}, getFieldsValue(), { Id }))
        }
        onCancel={this.props.onCancel}
        title={this.props.title}
      >
        <Form>
          <Form.Item label="部门名称">
            {getFieldDecorator('Name', {
              initialValue: Name
            })(<Input />)}
          </Form.Item>
          <Form.Item label="备注">
            {getFieldDecorator('Remark', {
              initialValue: Remark
            })(<Input.TextArea />)}
          </Form.Item>
        </Form>
      </Modal>
    )
  }
}

const mapStateToProps = state => ({})

const mapDispatchToProps = {}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create({ name: 'department-form-modal' })(index))
