// 获取存档单位列表
export const GET_UNIT_LIST = 'GET_UNIT_LIST'
// 设置存档单位列表
export const SET_UNIT_LIST = 'SET_UNIT_LIST'
// 获取存档单位的模板
export const GET_TEMPLATE_BY_UNIT = 'GET_TEMPLATE_BY_UNIT'
// 设置存档单位模板列表
export const SET_TEMPLATE_BY_UNIT = 'SET_TEMPLATE_BY_UNIT'
// 设置存档单位模板的详细数据
export const SET_TEMPLATEFIELD_BY_UNIT = 'SET_TEMPLATEFIELD_BY_UNIT'
// 设置存档单位模板名称
export const SET_TEMPLATENAME_BY_UNIT = 'SET_TEMPLATENAME_BY_UNIT'
// 设置模板模态框的显示状态
export const SET_TEMPLATE_MODAL_STATE = 'SET_TEMPLATE_MODAL_STATE'
// 设置单位表格的数据加载状态
export const SET_UNIT_TABLE_LOADING_STATE = 'SET_UNIT_TABLE_LOADING_STATE'
// 设置当前存档单位id
export const SET_THIS_UNIT_ID = 'SET_THIS_UNIT_ID'
// 通过模板id获取模板详细字段
export const GET_TEMPLATEFILEDS_BY_ID = 'GET_TEMPLATEFILEDS_BY_ID'
// 设置当前模板id
export const SET_THIS_TEMPLATE_ID = 'SET_THIS_TEMPLATE_ID'
// 当前选择的存档单位名称
export const SET_THIS_UNIT_NAME = 'SET_THIS_UNIT_NAME'
// 当前选择的模板的名称
export const SET_THIS_TEMPLATE_NAME = 'SET_THIS_TEMPLATE_NAME'
// 设置模板数据获取状态
export const SET_TEMPLATE_LOADING_STATE = 'SET_TEMPLATE_LOADING_STATE'
// 存档单位列表总条目
export const SET_UNITTABLE_ROWCOUNT = 'SET_UNITTABLE_ROWCOUNT'
// 存档单位页数
export const SET_UNITTABLE_PAGEINDEX = 'SET_UNITTABLE_PAGEINDEX'