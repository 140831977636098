import React, { Component } from 'react'
import { Checkbox, Row, Modal, Col, Card, message } from 'antd'
import Fetch from '../../../fetch/axios'
import actionMap from '../../../assets/scripts/actionMap.json'
import pageMap from '../../../assets/scripts/pageMap.json'
class index extends Component {
  state = {
    actionArr: [],
    pageArr: []
  }
  submit = () => {
    Fetch.updateRole({
      Id: this.props.id,
      roleId: this.props.role,
      action: this.state.actionArr.join(';'),
      page: this.state.pageArr.join(';')
    })
      .then(res => {
        if (res.success === true) {
          message.success('权限修改成功')
          this.props.onCancel()
        } else {
          message.error('权限修改失败')
        }
      })
      .catch(err => {
        console.log(err)
        message.error('权限修改失败')
      })
  }
  componentDidMount() {
    const { action='', page='' } = JSON.parse(JSON.stringify(this.props.data))
    this.setState({
      actionArr: action,
      pageArr: page
    })
  }
  render() {
    const actionPower =
      sessionStorage.getItem('actionPower') === null
        ? []
        : sessionStorage.getItem('actionPower').split(',')
    const pagePower =
      sessionStorage.getItem('pagePower') === null
        ? []
        : sessionStorage.getItem('pagePower').split(',')
    return (
      <Modal
        width={1000}
        title='权限分配'
        visible={this.props.visible}
        onCancel={this.props.onCancel}
        onOk={this.submit}
      >
        <Row gutter={5}>
          <Col span={12}>
            <Card title='操作权限'>
              <div style={{ height: '50vh', overflowY: 'auto' }}>
                {actionPower.map((item, index) => (
                  <div key={index}>
                    <Checkbox
                      checked={this.state.actionArr.indexOf(item) !== -1}
                      onChange={() => {
                        const arr = [...this.state.actionArr]
                        const index = arr.indexOf(item)
                        if (index === -1) {
                          arr.push(item)
                        } else {
                          arr.splice(index, 1)
                        }
                        this.setState({
                          actionArr: arr
                        })
                      }}
                    >
                      {actionMap[item]}
                    </Checkbox>
                  </div>
                ))}
              </div>
            </Card>
          </Col>
          <Col span={12}>
            <Card title='路由权限'>
              <div style={{ height: '50vh', overflowY: 'auto' }}>
                {pagePower.map((item, index) => (
                  <div key={index}>
                    <Checkbox
                      checked={this.state.pageArr.indexOf(item) !== -1}
                      onChange={() => {
                        const arr = [...this.state.pageArr]
                        const index = arr.indexOf(item)
                        if (index === -1) {
                          arr.push(item)
                        } else {
                          arr.splice(index, 1)
                        }
                        this.setState({
                          pageArr: arr
                        })
                      }}
                    >
                      {pageMap[item]}
                    </Checkbox>
                  </div>
                ))}
              </div>
            </Card>
          </Col>
        </Row>
      </Modal>
    )
  }
}
export default index
