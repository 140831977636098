/*
 * Project: project
 * File Created: 2019-09-20
 * Author: zhoud
 * Last Modified: 2019-09-23
 * Modified By: zhoud
 * Desc: 模板字段模态框
 */
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Modal, message } from 'antd'
import EditableCellTable from '../EditableCellTable'
import * as unitActions from '../../../store/unit/actions'
import './index.css'
import Fetch from '../../../fetch/axios'
class index extends Component {
  // 提交表单
  submit = () => {
    if (this.props.title === '新增模板') {
      Fetch.addTemplate({
        ArchivingUnitId: this.props.unitId,
        Name: this.props.templateName,
        fields: this.props.templateDetailList,
        Status: 0
      }).then(res => {
        if (res.code === 0) {
          message.success('模板添加成功')
          this.props.onCancel()
          this.props.getTemplateList(this.props.unitId)
          this.props.setTemplateDetailList([])
        }
      })
    } else {
      Fetch.upadteTemplateFields({
        ArchivingUnitId: this.props.unitId,
        Name: this.props.templateName,
        fields: this.props.templateDetailList,
        Status: 0,
        Id: this.props.templateId
      }).then(res => {
        if (res.code === 0) {
          message.success('模板更新成功')
          this.props.onCancel()
          this.props.getTemplateList(this.props.unitId)
          this.props.setTemplateDetailList([])
        }
      })
    }
  }
  render() {
    return (
      <Fragment>
        {this.props.visible ? (
          <Modal
            visible={this.props.visible}
            width="850px"
            title={this.props.title}
            onOk={this.submit}
            onCancel={() => this.props.onCancel()}
          >
            {this.props.visible ? (
              <EditableCellTable dataModal={this.props.title} />
            ) : null}
          </Modal>
        ) : null}
      </Fragment>
    )
  }
}
const mapStateToProps = state => ({
  // 模板字段详情数据
  templateDetailList: state.unit.templateDetailList,
  // 模板名称
  templateName: state.unit.templateName,
  // 单位id
  unitId: state.unit.unitId,
  // 模板id
  templateId: state.unit.templateId
})

const mapDispatchToProps = dispatch => {
  return {
    // 获取存档单位模板列表
    getTemplateList: id => {
      dispatch(unitActions.getTemplateList({ id }))
    },
    // 模板字段详情
    setTemplateDetailList: data => {
      dispatch(unitActions.setTemplateField(data))
    },
    // 设置模板名称
    setTemplateName: data => {
      dispatch(unitActions.setTemplateName(data))
    }
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(index)
