import * as types from './action-types'
export default (
  state = {
    // 地址列表数据
    addressList: [],
    // 当前选择的地点的信息
    selectedAddressInfo: {},
    // 单元格信息
    cellList: [],
    // 当前选择的档案架的信息
    selectedShelfInfo: {},
    tabKey: '1',
    // 架体单元格容量编辑模态框
    shelfCellEditModalVisible: false,
    // 当前编辑单元格的信息
    editCellInfo: {},
    // 档案架数组
    shelfList: [],
    // 档案架数据总页数
    shelfTableRowCount: -1,
    // 档案架数据当前页数
    shelfTablePageIndex: 1
  },
  action
) => {
  switch (action.type) {
    // 设置地点信息
    case types.SET_ADDRESS_LIST: {
      return { ...state, addressList: action.data }
    }
    // 设置当前选择地点的信息
    case types.SET_SELECTED_ADDRESS_INFO: {
      return { ...state, selectedAddressInfo: action.data }
    }
    // 设置架体列表数据
    case types.SET_SHELF_LIST: {
      return {
        ...state,
        shelfList: action.data
      }
    }
    // 设置单元格信息
    case types.SET_SHELF_CELL_LIST: {
      return {
        ...state,
        cellList: action.data
      }
    }
    // 设置当前选择档案架的信息
    case types.SET_SELECTED_SHELF_INFO: {
      return {
        ...state,
        selectedShelfInfo: action.data
      }
    }
    // 设置tab的key值
    case types.SET_TAB_KEY: {
      return {
        ...state,
        tabKey: action.data
      }
    }
    // 架体单元格容量编辑模态框
    case types.SET_SHELF_CELL_EDIT_MODAL_VISIBLE: {
      return {
        ...state,
        shelfCellEditModalVisible: action.data
      }
    }
    // 设置正在编辑的单元格的信息
    case types.SET_EDITING_SHELF_CELL_INFO: {
      return {
        ...state,
        editCellInfo: action.data
      }
    }
    // 设置总页数
    case types.SET_ROWCOUNT: {
      return {
        ...state,
        shelfTableRowCount: action.data
      }
    }
    // 当前页号
    case types.SET_PAGEINDEX: {
      return {
        ...state,
        shelfTablePageIndex: action.data.pageIndex
      }
    }
    case types.RESET_PAGEINDEX: {
      return {
        ...state,
        shelfTablePageIndex: 1
      }
    }
    default: {
      return state
    }
  }
}
