/*
 * Project: project
 * File Created: 2019-08-06
 * Author: zhoud
 * Last Modified: 2019-08-06
 * Modified By: zhoud
 * Desc:路由承载根组件
 */
import React, { Component } from 'react'
export default class App extends Component {
  render() {
    return (
      <div style={{ width: '100%', height: '100%' }}>{this.props.children}</div>
    )
  }
}
