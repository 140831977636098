/*
 * Project: project
 * File Created: 2019-09-23
 * Author: zhoud
 * Last Modified: 2019-09-23
 * Modified By: zhoud
 * Desc: 地点管理树
 */
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Tree, Button, Card, message, Modal, Icon } from 'antd'
import Fetch from '../../../fetch/axios'
import * as addressActions from '../../../store/address/actions'
import AddressForm from '../AddressForm'
import './index.css'
export class AddressManage extends Component {
  state = {
    // 模态框title
    modalTitle: '',
    // 模态框的开关状态
    showModal: false,
    // 模态框类别,1添加,2编辑
    modalType: -1,
    // 当前选择行对象，用于编辑时回填字段
    itemObj: null,
    // 当前已经选中的树节点的信息数组
    selectedTreeNodes: []
  }
  // 打开新增地点的模态框
  openBasicModal = () => {
    console.log(this.state.selectedTreeNodes[0])
    // 新增根节点
    if (this.state.selectedTreeNodes.length === 0) {
      this.setState({
        modalTitle: '新增地点',
        showModal: true,
        modalType: 1,
        itemObj: this.state.itemObj
      })
    } else if (this.state.selectedTreeNodes.length === 1) {
      if(this.state.selectedTreeNodes[0].Type === 2){//最后一层不能新增
        message.error('不能为库房新增子节点')
        return
      }
      this.setState({
        modalTitle: '新增子地点',
        showModal: true,
        modalType: 1,
        itemObj: {
          addType: '2',
          parentId: this.state.selectedTreeNodes[0].Id,
          type: this.state.selectedTreeNodes[0].Type
        }
      })
    } else {
      message.error('新增地点时不能选择超过两个节点!')
    }
  }
  // 开启编辑模态框
  openEditModal = () => {
    const length = this.state.selectedTreeNodes.length
    if (length === 0) {
      message.error('请选择一个地点进行编辑')
    } else if (length > 1) {
      message.error('不能选择多个地点同时编辑')
    } else {
      this.setState({
        modalTitle: '编辑地点信息',
        showModal: true,
        itemObj: this.state.selectedTreeNodes[0],
        modalType: 2
      })
    }
  }
  // 关闭模态框
  closeModal = () => {
    this.setState({
      modalTitle: '',
      showModal: false,
      modalType: -1,
      itemObj: null
    })
  }
  // 渲染树的逻辑
  renderTreeNode = data => {
    const iconTypeMap = ['apartment', 'home', 'shop']
    if (data) {
      return data.map(item => {
        const icon = iconTypeMap[item.Type]
        if (item.treeNode !== []) {
          return (
            <Tree.TreeNode
              title={item.Name}
              key={item.Id}
              dataRef={item}
              isLeaf={item.Type === 2}
              icon={<Icon type={icon} />}
            >
              {this.renderTreeNode(item.treeNode)}
            </Tree.TreeNode>
          )
        }
        return (
          <Tree.TreeNode
            title={item.Name}
            key={item.Id}
            dataRef={item}
            isLeaf={item.Type === 2}
          />
        )
      })
    }
    return null
  }
  // 提交新增地点
  submit = data => {
    data['SequenceNumber'] = '1.0'
    data['Status'] = 0
    delete data['addType']
    console.log('data: ',data)
    if(null === data.Name || data.Name === ''){
      message.error('请填写地点名称')
      return
    }
    if(null === data.Type || data.Type === ''){
      message.error('请选择地点类型')
      return
    }
    if (this.state.modalType < 2) {
      Fetch.addAddress(data).then(() => {
        this.props.getAddressList()
        this.closeModal()
        this.setState(
          {
            selectedTreeNodes: []
          },
          function() {
            window.location.reload()
          }
        )
      })
    } else {
      Fetch.updateAddress(data).then(() => {
        this.props.getAddressList()
        this.closeModal()
        this.setState(
          {
            selectedTreeNodes: []
          },
          function() {
            window.location.reload()
          }
        )
      })
    }
  }
  // 展开子节点的回调函数
  onLoadData = treeNode =>
    new Promise(resolve => {
      if (treeNode.props.children) {
        resolve()
        return
      }
      Fetch.getAddressList({
        nodeId: treeNode.props.dataRef.Id,
        recursion: false
      }).then(res => {
        treeNode.props.dataRef.treeNode = res.data === [] ? null : res.data
        this.setState({
          treeData: []
        })
        resolve()
      })
    })
  // 选择树节点时的回调函数,存入选择节点的数据
  onTreeSelect = (selectedKeys, e) => {
    if (e.selectedNodes.length === 1) {
      this.props.setSelectedAddressInfo(e.selectedNodes[0]['props']['dataRef'])
    }
    this.setState({
      selectedTreeNodes: e.selectedNodes.map(item => item.props['dataRef'])
    })
  }
  // 删除选定的节点
  deleteNodes = () => {
    const _this = this
    if (this.state.selectedTreeNodes.length > 0) {
      Modal.confirm({
        title: `删除确认`,
        content: `确定删除这${this.state.selectedTreeNodes.length}个节点?`,
        okType: 'danger',
        onOk() {
          Fetch.deleteAddress(
            _this.state.selectedTreeNodes.map(item => item.Id)
          ).then(() => {
            _this.props.getAddressList()
            _this.setState(
              {
                selectedTreeNodes: []
              },
              function() {
                window.location.reload()
              }
            )
          })
        }
      })
    } else {
      message.error('没有选择删除的节点')
    }
  }
  componentDidMount() {
    this.props.getAddressList()
  }
  render() {
    return (
      <Fragment>
        <Card title="地点管理">
          <Tree.DirectoryTree
            multiple
            showIcon
            loadData={this.onLoadData}
            onSelect={this.onTreeSelect}
          >
            {this.renderTreeNode(this.props.addressList)}
          </Tree.DirectoryTree>
          <Button
            type="primary"
            style={{ margin: '10px' }}
            onClick={this.openBasicModal}
          >
            新增
          </Button>
          <Button
            type="primary"
            style={{ margin: '10px' }}
            onClick={this.openEditModal}
          >
            编辑
          </Button>
          <Button
            type="primary"
            style={{ margin: '10px' }}
            onClick={this.deleteNodes}
          >
            删除
          </Button>
        </Card>
        {this.state.showModal ? (
          <AddressForm
            title={this.state.modalTitle}
            visible={this.state.showModal}
            type={this.state.modalType}
            onCancel={this.closeModal}
            onOk={this.submit}
            data={this.state.itemObj}
          />
        ) : null}
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  addressList: state.address.addressList
})

const mapDispatchToProps = dispatch => {
  return {
    // 获取地址列表
    getAddressList: () => {
      dispatch(addressActions.getAddressList())
    },
    // 设置当前选择地点的信息
    setSelectedAddressInfo: info => {
      dispatch(addressActions.setAddressInfo(info))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddressManage)
