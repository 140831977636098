/*
 * Project: project
 * File Created: 2019-08-06
 * Author: zhoud
 * Last Modified: 2019-08-06
 * Modified By: zhoud
 * Desc:Redux状态导出根文件
 */
import { combineReducers, createStore, applyMiddleware, compose } from 'redux'
import { createLogger } from 'redux-logger'
import { persistStore, persistReducer } from 'redux-persist'
import sessionStorage from 'redux-persist/lib/storage/session'
import createSagaMiddleware from 'redux-saga'
import mySaga from './sagas'

import global from './global/reducers'
import user from './user/reducers'
import address from './address/reducers'
import unit from './unit/reducers'
import docinfo from './documentinfo/reducers'
import department from './department/reducers'

const sagaMiddleware = createSagaMiddleware()
const logger = createLogger()
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const rootReducer = combineReducers({
  global,
  user,
  address,
  unit,
  docinfo,
  department
})
const persistConfig = {
  key: 'root',
  storage: sessionStorage
}
const persistedReducer = persistReducer(persistConfig, rootReducer)
let store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware, logger))
)
let persistor = persistStore(store)
export default () => {
  return { store, persistor }
}
sagaMiddleware.run(mySaga)
