import * as types from './action-types'
export default (
  state = {
    // 用户登录状态
    loginState: false,
    // 用户信息显示状态
    userinfoDisplayState: false,
    // 菜单展开收起状态
    menuExpandState: false,
    // 用户信息
    userInfo: {},
    spinning: false
  },
  action
) => {
  switch (action.type) {
    case types.SET_SPINNING: {
      return { ...state, spinning: action.data }
    }
    // 设置登录状态
    case types.SET_LOGIN_STATE: {
      return { ...state, loginState: action.data }
    }
    // 修改用户信息显示状态
    case types.TOGGLE_USERINFO_DISPLAY_STATE: {
      return { ...state, userinfoDisplayState: !state.userinfoDisplayState }
    }
    // 修改菜单展开收起状态
    case types.TOGGLE_MENU_EXPAND_STATE: {
      return { ...state, menuExpandState: !state.menuExpandState }
    }
    // 设置保存用户信息
    case types.SET_USER_INFO: {
      return { ...state, userInfo: action.data }
    }
    case types.SET_REQUEST_COUNT: {
      const userInfo = JSON.parse(JSON.stringify(state.userInfo))
      userInfo.requestCount = action.data
      return { ...state, userInfo }
    }
    default: {
      return state
    }
  }
}
