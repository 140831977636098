import * as types from './action-types'
// 设置存档单位列表
export const setUnitList = data => {
  return {
    type: types.SET_UNIT_LIST,
    data
  }
}
// 获取存档单位列表
export const getUnitList = data => {
  return {
    type: types.GET_UNIT_LIST,
    data
  }
}
// 设置存档单位模板数据
export const setTemplateList = data => {
  return {
    type: types.SET_TEMPLATE_BY_UNIT,
    data
  }
}
// 获取存档单位模板
export const getTemplateList = data => {
  return { type: types.GET_TEMPLATE_BY_UNIT, data }
}
// 切换模板显示状态
export const setTemplateModalState = data => {
  return {
    type: types.SET_TEMPLATE_MODAL_STATE,
    data
  }
}
// 设置表格的loading状态
export const setTableLoadingState = state => {
  return {
    type: types.SET_UNIT_TABLE_LOADING_STATE,
    data: state
  }
}
// 设置模板详情数据
export const setTemplateField = data => {
  return {
    type: types.SET_TEMPLATEFIELD_BY_UNIT,
    data
  }
}
// 设置模板名称
export const setTemplateName = data => {
  return {
    type: types.SET_TEMPLATENAME_BY_UNIT,
    data
  }
}
// 记录当前单位id
export const setUnitId = data => {
  return {
    type: types.SET_THIS_UNIT_ID,
    data
  }
}
// 通过模板id获取模板
export const getTemplateDetail = data => {
  return {
    type: types.GET_TEMPLATEFILEDS_BY_ID,
    data
  }
}
// 设置当前模板的id
export const setTemplateId = data => {
  return {
    type: types.SET_THIS_TEMPLATE_ID,
    data
  }
}
// 设置当前选择存档单位名称
export const setThisUnitName = data => {
  return {
    type: types.SET_THIS_UNIT_NAME,
    data
  }
}
// 设置当前选择模板名称
export const setThisTemplateName = data => {
  return {
    type: types.SET_THIS_TEMPLATE_NAME,
    data
  }
}
// 设置模板数据获取状态
export const setTemplateLoadingState = data => {
  return {
    type: types.SET_TEMPLATE_LOADING_STATE,
    data
  }
}
export const setUnitTableRowCount = data => {
  return {
    type: types.SET_UNITTABLE_ROWCOUNT,
    data
  }
}
export const setUnitTablePageIndex = data => {
  return {
    type: types.SET_UNITTABLE_PAGEINDEX,
    data
  }
}
