/*
 * Project: project
 * File Created: 2019-08-06
 * Author: zhoud
 * Last Modified: 2019-08-06
 * Modified By: zhoud
 * Desc:主菜单组件
 */
import React, {Component} from 'react'
import {connect} from 'react-redux'
import {NavLink} from 'react-router-dom'
import {Icon, Menu} from 'antd'
import userMenu from '../../../fetch/mock/userMenu'
import * as globalAction from '../../../store/global/actions'
import './index.css'
import Fetch from '../../../fetch/axios'

export class index extends Component {
  state = {
    id: null
  }

  UNSAFE_componentWillMount() {
    const menuContentTree = this.renderMenu(userMenu['data'])
    const id = setInterval(() => {
      Fetch.getHandleCount()
        .then(res => {
          this.props.setRequestCount(res.data)
        })
    }, 5000)
    this.setState({
      id
    })
  }

  UNSAFE_componentWillReceiveProps() {
    const menuContentTree = this.renderMenu(userMenu['data'])
    this.setState({menuContentTree})
  }

  componentWillUnmount() {
    clearInterval(this.state.id)
  }

  // 渲染菜单的方法
  renderMenu = (data) => {
    const pagePower =
      sessionStorage.getItem('pagePower') === null
        ? []
        : sessionStorage.getItem('pagePower').split(',')
    // 如果不是数组抛出异常
    if (!Array.isArray(data)) {
      return new Error('菜单数据不是一个数组')
    }
    return data.map((item) => {
      if (item.children) {
        return (
          <Menu.SubMenu
            title={
              <span>
                <Icon type={item.icon}/>
                <span>{item.title}</span>
              </span>
            }
            key={item.key}
          >
            {this.renderMenu(item.children)}
          </Menu.SubMenu>
        )
      }
      if (pagePower.indexOf(item.key) !== -1) {
        return (
          <Menu.Item key={item.key}>
            <NavLink to={item.key}>
              <Icon type={item.icon}/>
              <span>{item.title}</span>
              {item.key === '/index/borrowRequestList' && this.props.requestCount > 0 ? (
                <span
                  style={{
                    display: 'inline-block',
                    color: 'white',
                    border: '1px solid transparent',
                    borderRadius: '8px',
                    width: '32.3px',
                    height: '20px',
                    textAlign: 'center',
                    lineHeight: '18px',
                    marginLeft: '20px',
                    background: 'red',
                    fontWeight: 'bold',
                    fontSize:'12px',
                  }}
                >
                  {this.props.requestCount}
                </span>
              ) : null}
            </NavLink>
          </Menu.Item>
        )
      } else {
        return null
      }
    })
  }

  render() {
    return (
      <div>
        <Menu
          defaultSelectedKeys={['/index/addressmanage']}
          defaultOpenKeys={['sub1']}
          mode="inline"
          theme="dark"
          inlineCollapsed={this.props.menuExpandState}
        >
          {this.renderMenu(userMenu['data'])}
        </Menu>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  // 菜单展开状态
  menuExpandState: state.global.menuExpandState,
  requestCount: state.global.userInfo.requestCount,
})

const mapDispatchToProps = dispatch => ({
  setRequestCount: count => {
    dispatch(globalAction.setRequestCount(count))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(index)
