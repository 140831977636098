/*
 * Project: project
 * File Created: 2019-09-24
 * Author: zhoud
 * Last Modified: 2019-09-24
 * Modified By: zhoud
 * Desc: 编辑和修改档案架信息的模态框
 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Modal, Form, Input, Radio } from 'antd'

class index extends Component {
  componentDidMount() {
    this.props.form.resetFields()
  }
  render() {
    const { getFieldDecorator, getFieldsValue } = this.props.form
    const { Name = '', Plane = 0 } = JSON.parse(JSON.stringify(this.props.data))
    const formItemLayout = {
      labelCol: {
        xs: { span: 2 },
        sm: { span: 2 }
      },
      wrapperCol: {
        xs: { span: 20 },
        sm: { span: 20 }
      }
    }
    return (
      <Modal
        visible={this.props.visible}
        onOk={() => this.props.onOk({ ...getFieldsValue() })}
        onCancel={this.props.onCancel}
        title={this.props.title}
      >
        <Form {...formItemLayout}>
          <Form.Item label="名称">
            {getFieldDecorator('Name', {
              initialValue: Name
            })(<Input placeholder="档案架名称" />)}
          </Form.Item>
          <Form.Item label="AB面">
            {getFieldDecorator('Plane', {
              initialValue: Plane
            })(
              <Radio.Group>
                <Radio value={0}>A面</Radio>
                <Radio value={1}>B面</Radio>
              </Radio.Group>
            )}
          </Form.Item>
        </Form>
      </Modal>
    )
  }
}

const mapStateToProps = state => ({})

const mapDispatchToProps = {}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create({ name: 'shelf-form' })(index))
