import {all, call, put, takeEvery} from 'redux-saga/effects'
import Axios from '../fetch/axios'

// 获取用户列表的请求
function* getUserList(action) {
  try {
    const {page = 1, pageSize = 10} = action.data
    yield put({type: 'SET_TABLE_LOADING_STATE', data: true})
    const res = yield call(Axios.getUserList, {
      pageIndex: page,
      pageSize: pageSize
    })
    yield put({type: 'SET_USER_LIST', data: res.data})
    yield put({type: 'SET_TABLE_LOADING_STATE', data: false})
  } catch (e) {
    console.log(e)
  }
}

// 监听获取用户列表的action
function* watchGetUserList() {
  yield takeEvery('GET_USER_LIST', getUserList)
}

// 获取地址列表
function* getAddressList() {
  try {
    const res = yield call(Axios.getAddressList, {
      nodeId: '',
      recursion: false
    })
    const data = res.data
    yield put({type: 'SET_ADDRESS_LIST', data: data})
  } catch (e) {
    console.log(e)
  }
}

// 监听获取地址列表
function* watchGetAddressList() {
  yield takeEvery('GET_ADDRESS_LIST', getAddressList)
}

// 获取存档单位模板
function* getTemplateList(action) {
  try {
    yield put({type: 'SET_TEMPLATE_LOADING_STATE', data: true})
    const res = yield call(Axios.getTemplateListByUnitId, {
      archivingUnitId: action.data.id
    })
    yield put({type: 'SET_TEMPLATE_LOADING_STATE', data: false})
    const data = res.data
    yield put({type: 'SET_TEMPLATE_BY_UNIT', data: data})
  } catch (e) {
    console.log(e)
  }
}

// 监听获取模板的action
function* watchGetTemplateList() {
  yield takeEvery('GET_TEMPLATE_BY_UNIT', getTemplateList)
}

// 获取存档单位列表
function* getArchivingUnitList(action) {
  try {
    const {pageIndex = 1, pageSize = 20, keyWord = ''} = action.data
    yield put({type: 'SET_UNIT_TABLE_LOADING_STATE', data: true})
    const res = yield call(
      Axios.getUnitList,
      {keyWord},
      {
        pageIndex,
        pageSize
      }
    )
    yield put({type: 'SET_UNIT_TABLE_LOADING_STATE', data: false})
    yield put({type: 'SET_UNITTABLE_ROWCOUNT', data: res.data['rowCount']})
    yield put({type: 'SET_UNIT_LIST', data: res.data})
  } catch (e) {
    console.log(e)
  }
}

// 监听获取存档单位的action
function* watchGetArchivingUnitList() {
  yield takeEvery('GET_UNIT_LIST', getArchivingUnitList)
  yield takeEvery('SET_UNITTABLE_PAGEINDEX', getArchivingUnitList)
}

// 获取档案架数据
function* getShelfList(action) {
  const {Type: addressType, Id: addressId, pageIndex = 1} = {...action.data}
  try {
    const res = yield call(
      Axios.getShelfList,
      {
        addressType,
        addressId
      },
      {
        pageIndex,
        pageSize: 5
      }
    )
    if (action.type === 'SET_SELECTED_ADDRESS_INFO') {
      yield put({type: 'RESET_PAGEINDEX'})
    }
    yield put({type: 'SET_SHELF_LIST', data: res['data']['rows']})
    yield put({type: 'SET_ROWCOUNT', data: res['data']['rowCount']})
  } catch (e) {
    console.log(e)
  }
}

// 监听架体数据获取
function* watchGetShelfList() {
  yield takeEvery('GET_SHELF_LIST', getShelfList)
  yield takeEvery('SET_SELECTED_ADDRESS_INFO', getShelfList)
  yield takeEvery('SET_PAGEINDEX', getShelfList)
}

// 获取单元格信息
function* getShelfCellList(action) {
  try {
    const res = yield call(Axios.getShelfCellList, {
      shelfId: action.data
    })
    yield put({type: 'SET_SHELF_CELL_LIST', data: res.data})
  } catch (e) {
    console.log(e)
  }
}

// 监听单元格信息获取
function* watchGetShelfCellList() {
  yield takeEvery('GET_SHELF_CELL_LIST', getShelfCellList)
}

// 通过模板id获取模板详情
function* getTemplateDetailById(action) {
  try {
    const res = yield call(Axios.getTemplateDetailById, {
      templateId: action.data
    })
    yield put({type: 'SET_TEMPLATEFIELD_BY_UNIT', data: res.data})
  } catch (e) {
    console.log(e)
  }
}

// 监听获取模板详情
function* watchGetTemplateDetial() {
  yield takeEvery('GET_TEMPLATEFILEDS_BY_ID', getTemplateDetailById)
}

// 根据模板id获取档案数据
function* getDocList(action) {
  try {
    const {pageIndex = 1, pageSize = 20} = action.data || {form: {}}
    const res = yield call(
      Axios.getDocList,
      Object.assign(
        action.data['form'],
        {
          templateId: action.data['Id'],
          keyWord: action.data['keyword']
        }
      ),
      {
        pageIndex,
        pageSize
      },
      action.data.setSpinning
    )
    if (action.type === 'GET_DOC_LIST') {
      yield put({type: 'RESET_DOCPAGEINDEX'})
    }
    yield put({type: 'SET_DOC_LIST', data: res.data.rows})
    yield put({type: 'SET_ROWCOUNT', data: res.data.rowCount})
  } catch (e) {
    console.log(e)
  }
}

// 监听档案数据获取
function* watchGetDocList() {
  yield takeEvery(
    ['GET_DOC_LIST', 'SET_SEARCH_FORM', 'SET_DOCPAGEINDEX', 'CLEAR_SEARCHFORM'],
    getDocList
  )
}

// 获取部门列表
function* getDepartmentList(action) {
  try {
    const res = yield call(Axios.getDepartmentList, {
      unitId: action.data
    })
    yield put({type: 'SET_DEPARTMENT_LIST', data: res.data})
  } catch (e) {
    console.log(e)
  }
}

function* watchGetDepartmentList() {
  yield takeEvery('GET_DEPARTMENT_LIST', getDepartmentList)
}

// rootSaga
export default function* rootSaga() {
  yield all([
    watchGetUserList(),
    watchGetAddressList(),
    watchGetArchivingUnitList(),
    watchGetTemplateList(),
    watchGetShelfList(),
    watchGetShelfCellList(),
    watchGetTemplateDetial(),
    watchGetDocList(),
    watchGetDepartmentList()
  ])
}
