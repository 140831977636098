import React from 'react'
import { connect } from 'react-redux'
import { Form, Input, Button, message } from 'antd'
import Fetch from '../../fetch/axios'
const ChangePassForm = (props) => {
  const handleChangePassWord = () => {
    const dataForm = getFieldsValue()
    if (dataForm['password'] !== dataForm['rePassword']) {
      return message.error('密码确认不一致')
    }
    const publicKey = sessionStorage.getItem('publicKey')
    var encrypt = new window.JSEncrypt()
    encrypt.setPublicKey(publicKey)
    const password = encrypt.encrypt(dataForm['password'])
    const oldPassword = encrypt.encrypt(dataForm['oldpassword'])
    Fetch.ChangePassword({
      id: props.userId,
      oldpassword: oldPassword,
      password: password,
    }).then((res) => {
      if (res.code === 0) {
        message.success('修改用户密码成功')
      }
    })
  }
  const { getFieldDecorator, getFieldsValue } = props.form
  const formItemLayout = {
    labelCol: {
      span: 5,
    },
    wrapperCol: {
      span: 15,
    },
  }
  return (
    <>
      <Form {...formItemLayout}>
        <Form.Item label="旧密码">
          {getFieldDecorator('oldpassword')(
            <Input type="password" allowClear />
          )}
        </Form.Item>
        <Form.Item label="新密码">
          {getFieldDecorator('password')(<Input type="password" allowClear />)}
        </Form.Item>
        <Form.Item label="重复新密码">
          {getFieldDecorator('rePassword')(
            <Input type="password" allowClear />
          )}
        </Form.Item>
      </Form>
      <div style={{ textAlign: 'center' }}>
        <Button
          type="primary"
          style={{ width: '70%', margin: '20px 0' }}
          onClick={handleChangePassWord}
        >
          确定
        </Button>
      </div>
    </>
  )
}
const mapStateToProps = (state) => ({
  userId: state.global.userInfo.Id,
})

const mapDispatchToProps = {}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create({ name: 'change-pass-form' })(ChangePassForm))
