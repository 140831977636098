import * as types from './action-types'
// 设置用户列表
export const setUserList = data => {
  return {
    type: types.SET_USER_LIST,
    data
  }
}
// 获取用户列表
export const getUserList = (data = {}) => {
  return {
    type: types.GET_USER_LIST,
    data
  }
}
// 设置用户管理表格的数据加载状态
export const setTableLoadingState = data => {
  return {
    type: types.SET_TABLE_LOADING_STATE,
    data
  }
}
