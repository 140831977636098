import React, { Component } from 'react'
import { Modal, Table } from 'antd'

class index extends Component {
  statusMap = {
    '-6': '作废',
    '-5': '失败',
    '-4': '无法归还',
    '-3': '快递寄送失败',
    '-2': '无法发送快递',
    '-1': '驳回',
    '0': '借阅发起',
    '1': '借阅请求已通过',
    '2': '已发送快递',
    '3': '调阅完成',
    '4': '已归还'
  }
  columns = [
    {
      title: '调阅人',
      dataIndex: 'BorrowUserId',
      key: 'BorrowUserId',
      align: 'center'
    },
    {
      title: '创建时间',
      dataIndex: 'CreateTime',
      key: 'CreateTime',
      align: 'center'
    },
    {
      title: '到期时间',
      dataIndex: 'ExpireDate',
      key: 'ExpireDate',
      align: 'center'
    },
    {
      title: '原因',
      dataIndex: 'Name',
      key: 'Name',
      align: 'center'
    },
    {
      title: '状态',
      render: record => <div>{this.statusMap[record.Status + '']}</div>
    }
  ]
  render() {
    return (
      <Modal
        visible={this.props.visible}
        title='调阅历史'
        onCancel={this.props.onCancel}
        onOk={this.props.onCancel}
        width={800}
      >
        <Table
          dataSource={this.props.data}
          columns={this.columns}
          rowKey={item => item.Id}
          pagination={{
            total: this.props.totalPage,
            pageSize: 20,
            onChange: page => {
              this.props.goToPage(page)
            }
          }}
        />
      </Modal>
    )
  }
}
export default index
