import * as types from './action-types'

export default (
  state = {
    docList: [],
    searchForm: {
      keyWord: '',
      form: {},
      Id: ''
    },
    docTableRowCount: -1,
    docTablePageIndex: 1
  },
  action
) => {
  switch (action.type) {
    // 设置档案数据列表
    case types.SET_DOC_LIST: {
      return {...state, docList: action.data}
    }
    // 搜索对象
    case types.SET_SEARCH_FORM: {
      return {...state, searchForm: action.data}
    }
    case types.SET_ROWCOUNT: {
      return {...state, docTableRowCount: action.data}
    }
    case types.SET_DOCPAGEINDEX: {
      return {...state, docTablePageIndex: action.data.pageIndex}
    }
    case types.RESET_DOCPAGEINDEX: {
      return {...state, docTablePageIndex: 1}
    }
    case types.CLEAR_SEARCHFORM: {
      return {
        ...state, searchForm: {
          keyWord: '',
          form: {},
          Id: ''
        },
      }
    }
    default: {
      return state
    }
  }
}
