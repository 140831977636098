import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Table,
  Input,
  Popconfirm,
  Form,
  Select,
  InputNumber,
  Button,
  message,
} from 'antd'
import './index.css'
import Fetch from '../../../fetch/axios'
import * as unitActions from '../../../store/unit/actions'
const EditableContext = React.createContext()
const typeEnum = {
  int: '整形',
  boolean: '布尔',
  long: '长整形',
  float: '单精度浮点数',
  double: '双精度浮点数',
  date: '日期',
  datetime: '详细时间',
  time: '时间',
  char: '字符',
  varchar: '可变字符',
  blob: '二进制数据',
  text: '文本',
}
class EditableCell extends React.Component {
  getInput = () => {
    if (this.props.inputType === 'Type') {
      return (
        <Select style={{ width: '100%' }}>
          {Object.keys(typeEnum).map((item, index) => (
            <Select.Option value={item} key={index}>
              {typeEnum[item]}
            </Select.Option>
          ))}
        </Select>
      )
    } else if (
      this.props.inputType === 'Length' ||
      this.props.inputType === 'sortNum'
    ) {
      return <InputNumber min={0} />
    } else {
      return <Input />
    }
  }
  renderCell = ({ getFieldDecorator }) => {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      children,
      ...restProps
    } = this.props
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item style={{ margin: 0 }}>
            {getFieldDecorator(dataIndex, {
              rules: [
                {
                  required: true,
                  message: `请输入${title}!`,
                },
              ],
              initialValue: record[dataIndex],
            })(this.getInput())}
          </Form.Item>
        ) : (
            children
          )}
      </td>
    )
  }
  render() {
    return (
      <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
    )
  }
}
export class index extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      editingKey: '',
    }
    this.columns = [
      {
        title: '字段名称',
        dataIndex: 'Name',
        editable: true,
        align: 'center',
      },
      {
        title: '字段类型',
        dataIndex: 'Type',
        editable: true,
        render: (record) => <span>{typeEnum[record]}</span>,
        align: 'center',
      },
      {
        title: '字段长度',
        dataIndex: 'Length',
        editable: true,
        align: 'center',
      },
      {
        title: '排序编号',
        dataIndex: 'sortNum',
        editable: true,
        align: 'center',
      },
      {
        title: '操作',
        dataIndex: 'operation',
        render: (text, record) => {
          const { editingKey } = this.state
          const editable = this.isEditing(record)
          return editable ? (
            <span>
              <EditableContext.Consumer>
                {(form) => (
                  <span
                    onClick={() => this.save(form, record.key)}
                    style={{ marginRight: 8, cursor: 'pointer', color: 'blue' }}
                  >
                    保存
                  </span>
                )}
              </EditableContext.Consumer>
              <span
                style={{ cursor: 'pointer', color: 'blue' }}
                onClick={this.cancel}
              >
                取消
              </span>
            </span>
          ) : (
              <span>
                <span
                  disabled={editingKey !== ''}
                  onClick={() => this.edit(record.key)}
                  style={{ cursor: 'pointer', color: 'blue', marginRight: 8 }}
                >
                  编辑
              </span>
                <Popconfirm
                  title="确定删除么？"
                  onConfirm={() => this.delete(record.key)}
                >
                  <span style={{ cursor: 'pointer', color: 'blue' }}>删除</span>
                </Popconfirm>
              </span>
            )
        },
        align: 'center',
      },
    ]
  }
  // 是否编辑模式
  isEditing = (record) => record.key === this.state.editingKey
  // 退出编辑模式
  cancel = () => {
    this.setState({ editingKey: '' })
  }
  // 删除
  delete = (key) => {
    const data = [...this.state.data]
    const itemData = data[key]
    if (this.props.templateId === '') {
      data.splice(key, 1)
      this.setState({ data, editingKey: '' })
    } else {
      Fetch.deleteTempField({
        idList: [itemData.Id],
        tableName: this.props.templateId,
      }).then((res) => {
        if (res.code === 0) {
          data.splice(key, 1)
          this.setState({ data, editingKey: '' }, function () {
            this.props.setTemplateDetailList(this.state.data)
          })
        } else {
          message.error(res.msg)
        }
      })
    }
  }
  // 保存
  save = (form, key) => {
    form.validateFields((error, row) => {
      if (error) {
        return
      } else {
        const { Name, Type, Length, sortNum } = this.props.form.getFieldsValue()
        const data = [...this.state.data]
        if (data[key].hasOwnProperty('Id')) {
          data[key] = Object.assign(data[key], {
            Name,
            Type,
            Length,
            key,
            sortNum,
          })
          this.setState({ editingKey: '', data }, function () {
            this.props.setTemplateDetailList(this.state.data)
          })
        } else if (this.props.templateId !== '') {
          // 新增
          Fetch.createTempField({
            Name,
            Type,
            Length,
            key,
            sortNum,
            TemplateId: this.props.templateId,
          }).then((res) => {
            if (res.code === 0) {
              data[key] = { Name, Type, Length, key, sortNum, Id: res.data }
              this.setState({ editingKey: '', data }, function () {
                this.props.setTemplateDetailList(this.state.data)
              })
            } else {
              message.error('新增字段失败')
            }
          })
        } else {
          data[key] = { Name, Type, Length, key, sortNum }
          this.setState({ editingKey: '', data }, function () {
            this.props.setTemplateDetailList(this.state.data)
          })
        }
      }
    })
  }
  // 编辑模式
  edit = (key) => {
    this.setState({ editingKey: key })
  }
  // 新增字段
  addField = () => {
    const data = [...this.state.data]
    const key = data.length
    data.push({
      Name: '',
      Type: 'text',
      Length: 32,
      key,
    })
    this.setState({
      editingKey: key,
      data,
    })
  }
  componentDidMount() {
    const data = [...this.props.templateDetailList]
    if (this.props.dataModal === '新增模板') {
      this.setState(
        {
          data: [],
        },
        function () {
          console.log('')
        }
      )
    } else {
      this.setState({
        data: this.props.templateDetailList,
      })
    }
    this.setState({
      data: data.map((item, index) => {
        item['key'] = index
        return item
      }),
    })
  }
  componentWillUnmount() {
    this.props.setTemplateName('')
    this.props.setTemplateDetailList([])
  }
  render() {
    const components = {
      body: {
        cell: EditableCell,
      },
    }
    const columns = this.columns.map((col) => {
      if (!col.editable) {
        return col
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType: col.dataIndex,
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record),
        }),
      }
    })
    return (
      <EditableContext.Provider value={this.props.form}>
        <Form.Item label="模板名称">
          {this.props.form.getFieldDecorator('templateName', {
            initialValue: this.props.templateName,
          })(<Input />)}
        </Form.Item>
        <Button
          type="primary"
          style={{ margin: '15px' }}
          onClick={this.addField}
        >
          新增字段
        </Button>
        <Table
          components={components}
          bordered
          dataSource={this.state.data}
          columns={columns}
          rowClassName="editable-row"
          pagination={{
            hideOnSinglePage: true,
            onChange: this.cancel,
          }}
          rowKey={(item) => item.key}
        />
      </EditableContext.Provider>
    )
  }
}
const mapStateToProps = (state) => ({
  // 模板的字段数据
  templateDetailList: state.unit.templateDetailList,
  // 模板名称
  templateName: state.unit.templateName,
  // 模板id
  templateId: state.unit.templateId,
})

const mapDispatchToProps = (dispatch) => {
  return {
    // 模板字段详情
    setTemplateDetailList: (data) => {
      dispatch(unitActions.setTemplateField(data))
    },
    // 设置当前选择模板名称
    setTemplateName: (data) => {
      dispatch(unitActions.setTemplateName(data))
    },
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Form.create({
    name: 'editable-template-field-table',
    onFieldsChange: function (props, changedFields, allFields) {
      if (changedFields['templateName']) {
        props.setTemplateName(changedFields['templateName']['value'])
      }
    },
  })(index)
)
