/*
 * Project: project
 * File Created: 2019-08-06
 * Author: zhoud
 * Last Modified: 2019-08-06
 * Modified By: zhoud
 * Desc: 登录form表单组件
 */
import React, { Component } from 'react'
import { Form, Input, Icon, Checkbox, Button, message, Card } from 'antd'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Fetch from '../../../fetch/axios'
import * as globalActions from '../../../store/global/actions'
import './index.css'
class Index extends Component {
  state = {
    userInfo: {
      username: '',
      password: '',
      rempassword: false,
    },
    loading: false,
  }
  // 初始化登录表单的值，回填账号密码
  UNSAFE_componentWillMount() {
    const userInfoObj = JSON.parse(localStorage.getItem('userinfo'))
    if (userInfoObj !== null) {
      this.setState({
        userInfo: userInfoObj,
      })
    }
  }
  // 表单提交的回调
  handleSubmit = (e) => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ loading: true }, function () {
          // 获取公钥
          Fetch.getPublicKey().then((res) => {
            if (res.code === 0) {
              const key = res.data
              sessionStorage.setItem('publicKey', key)
              var encrypt = new window.JSEncrypt()
              encrypt.setPublicKey(key)
              const pass = encrypt.encrypt(values['password'])
              // 登录
              Fetch.login({
                Account: values['username'],
                Password: pass,
              })
                .then((res) => {
                  // 如果点击了保存密码存入localstorage，否则清除localstorage中对应字段
                  if (values['rempassword']) {
                    localStorage.setItem('userinfo', JSON.stringify(values))
                  } else {
                    localStorage.removeItem('userinfo')
                  }
                  this.setState({ loading: false })
                  // 保存权限数据到sessionStorage
                  sessionStorage.setItem('actionPower', res.data.actionPower)
                  sessionStorage.setItem('pagePower', res.data.pagePower)
                  this.props.setUserInfo(res.data)
                  // 路由跳转到第一个路由
                  if (res.data.pagePower.length === 0) {
                    message.error('用户没有任何页面权限');
                  } else {
                    this.props.setSpinning(false);
                    this.props.history.push(res.data.pagePower[0]);
                  }
                })
                .catch((e) => {
                  message.error('网络错误，登录失败。');
                  this.setState({ loading: false })
                })
            }
          }).catch(e => {
            message.error('网络错误.');
            this.setState({ loading: false });
          });
        })
      }
    })
  }
  render() {
    const { getFieldDecorator } = this.props.form
    const {
      username = '',
      password = '',
      rempassword = false,
    } = this.state.userInfo
    return (
      <Card
        style={{
          maxWidth: '400px',
          position: 'absolute',
          top: '40%',
          left: '60%',
          transform: 'translateY(-50%) translateX(-50%)',
          padding: '20px',
          width: '80%',
          borderRadius: '10px',
        }}
        className="main"
        title={<span style={{ color: '#1890ff', fontSize: '20px', fontWeight: 'bold' }}>系统登录</span>}>
        <Form onSubmit={this.handleSubmit}>
          {/* 用户名字段 */}
          <Form.Item>
            {getFieldDecorator('username', {
              rules: [{ required: true, message: '请填写用户名' }],
              initialValue: username,
            })(
              <Input
                prefix={
                  <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />
                }
                placeholder="用户名"
              />
            )}
          </Form.Item>
          {/* 密码字段 */}
          <Form.Item>
            {getFieldDecorator('password', {
              rules: [{ required: true, message: '密码不能为空' }],
              initialValue: password,
            })(
              <Input
                prefix={
                  <Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />
                }
                type="password"
                placeholder="密码"
              />
            )}
          </Form.Item>
          {/* 记住密码checkbox */}
          <Form.Item>
            {getFieldDecorator('rempassword', {
              valuePropName: 'checked',
              initialValue: rempassword,
            })(<Checkbox style={{ float: 'right' }}>记住密码</Checkbox>)}
          </Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{
              width: '100%',
              fontWeight: 'bold',
              fontSize: '17px',
              letterSpacing: '20px',
              textIndent: '10px',
            }}
            loading={this.state.loading}
          >
            登录
          </Button>
        </Form>
      </Card>
    )
  }
}
function mapStateToProps(state) {
  return {}
}
function mapDispatchToProps(dispatch) {
  return {
    setUserInfo: (data) => {
      dispatch(globalActions.setUserInfo(data))
    },
    setSpinning: state => {
      dispatch(globalActions.setSpinning(state))
    },
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Form.create({ name: 'login-form' })(Index)))
