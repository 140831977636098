import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { ConfigProvider } from 'antd'
import zhCN from 'antd/es/locale/zh_CN'
import Router from './router'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import storeFun from './store'

import 'normalize.css'
import './index.css'
import './assets/style/index.css'
const { store, persistor } = storeFun()
ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConfigProvider locale={zhCN}>
        <App>
          <Router />
        </App>
      </ConfigProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
)
