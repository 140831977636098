import React from 'react'
import { Form, Modal, Input } from 'antd'
const SuppliesChangeModal = props => {
  const { getFieldDecorator, getFieldsValue } = props.form
  const formItemLayout = {
    labelCol: {
      span: 2
    },
    wrapperCol: {
      span: 20
    }
  }
  return (
    <Modal
      title={props.title}
      visible={props.visible}
      onCancel={props.onCancel}
      onOk={() => {
        props.onOk(getFieldsValue())
      }}
    >
      <Form {...formItemLayout}>
        <Form.Item label="数量">
          {getFieldDecorator('num')(<Input type="number" />)}
        </Form.Item>
        <Form.Item label="原因">
          {getFieldDecorator('reason')(<Input.TextArea />)}
        </Form.Item>
      </Form>
    </Modal>
  )
}
export default Form.create({ name: 'supplies-change-modal' })(
  SuppliesChangeModal
)
