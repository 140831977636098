/*
 * Project: project
 * File Created: 2019-08-26
 * Author: zhoud
 * Last Modified: 2019-08-26
 * Modified By: zhoud
 * Desc: 导航条组件
 */
import React, { Component } from 'react'
import './index.css'
import { withRouter } from 'react-router-dom'
import { Breadcrumb, Icon } from 'antd'
import userMenu from '../../../fetch/mock/userMenu'
export class index extends Component {
  state = {
    // 用户菜单表
    userMenu
  }
  UNSAFE_componentWillMount() {
    const urlMapWithName = {}
    userMenu.data.forEach(item => {
      urlMapWithName[item.key] = [item.title, item.icon]
    })
    this.setState({ urlMapWithName })
  }
  render() {
    const pathname = this.props.location.pathname
    const [name, icon] = this.state.urlMapWithName[pathname]
    return (
      <div style={{ padding: '15px' }}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Icon type={icon} style={{ color: '#1890ff' }} />
            <span style={{ padding: '5px' }}>{name}</span>
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
    )
  }
}

export default withRouter(index)
