import * as types from './action-types'
export default (
  state = {
    // 用户列表
    userList: [],
    // 表格数据加载状态
    loading: false
  },
  action
) => {
  switch (action.type) {
    // 设置用户列表
    case types.SET_USER_LIST: {
      return {
        ...state,
        userList: action.data
      }
    }
    // 设置表格数据加载状态
    case types.SET_TABLE_LOADING_STATE: {
      return {
        ...state,
        loading: action.data
      }
    }
    default: {
      return state
    }
  }
}
