import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Card, List } from 'antd'
import * as unitActions from '../../../store/unit/actions'
import * as docActions from '../../../store/documentinfo/actions'
import * as globalActions from '../../../store/global/actions'
export class index extends Component {
  render() {
    this.props.clearRowCount();
    this.props.setDocList([]);
    this.props.setTemplateId(null);
    this.props.setThisTemplateName(null);
    return (
      <Card title="存档单位模板" style={{ margin: '10px' }}>
        <List>
          {this.props.templateList.map(item => (
            <List.Item
              key={item.Id}
              onClick={() => {
                this.props.setThisTemplateName(item.Name)
                this.props.getDocList(item.Id, this.props.setSpinning);
                this.props.getTemplateDetail(item.Id)
                this.props.setTemplateId(item.Id)
              }}
            >
              <span style={{ cursor: 'pointer', color: 'blue' }}>
                {item.Name}
              </span>
            </List.Item>
          ))}
        </List>
      </Card>
    )
  }
}

const mapStateToProps = state => {
  return {
    // 模板列表
    templateList: state.unit.templateList
  }
}

const mapDispatchToProps = dispatch => {
  return {
    // 设置当前模板名称
    setThisTemplateName: name => {
      dispatch(unitActions.setTemplateName(name))
    },
    // 获取档案数据
    getDocList: (id, setSpinning) => {
      dispatch(docActions.getDocList({ Id: id, keyWord: '', form: {}, setSpinning }));
    },
    // 获取档案数据
    setDocList: (data) => {
      dispatch(docActions.setDocList(data));
    },
    clearRowCount: () => {
      dispatch(docActions.setRowCount(0));
    },
    // 获取模板详情
    getTemplateDetail: id => {
      dispatch(unitActions.getTemplateDetail(id))
    },
    setSpinning: state => {
      dispatch(globalActions.setSpinning(state))
    },
    // 设置当前模板id
    setTemplateId: id => {
      dispatch(unitActions.setTemplateId(id))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(index)