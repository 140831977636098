/*
 * Created Date: Friday April 3rd 2020
 * Author: zhoudailin
 * Last Modified: Fri Apr 03 2020
 * Modified By: zhoudailin
 * Description: 物料管理
 */
import React, { useState, useEffect } from 'react'
import { Button, Table, message, Card, Modal } from 'antd'
import Fetch from '../fetch/axios'
import CreateSuppliesModal from '../components/ASD/CreateSuppliesModal'
import SuppliesChangeModal from '../components/ASD/SuppliesChangeModal'
import SuppliesLogs from '../components/ASD/SuppliesLogs'
const ASD = (props) => {
  // 耗材列表
  const [suppliesList, setSuppliesList] = useState([])
  // 创建耗材模态框
  const [showCreateSuppliesModal, setShowCreateSuppliesModal] = useState(false)
  // 出库入库模态框
  const [showSuppliesChangeModal, setShowSuppliesChangeModal] = useState(false)
  // 出入库模态框title
  const [suppliesChangeModalTitle, setSuppliesChangeModalTitle] = useState('')
  // 出入库模态框需要的数据
  const [suppliesModalData, setSuppliesModalData] = useState({})
  // 展示历史
  const [showLogs, setShowLogs] = useState(false)
  // 触发重新获取列表的标志位
  const [reFetchData, setReFetchData] = useState(true)
  // 总条目
  const [total, setTotal] = useState(0)
  // 当前页
  const [currentPage, setCurrentPage] = useState(1)
  useEffect(() => {
    getSuppliesList()
  }, [reFetchData])
  // 表格结构
  const columns = [
    {
      title: '耗材名称',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
    },
    {
      title: '所在馆库',
      dataIndex: 'archivesName',
      key: 'archivesName',
      align: 'center',
    },
    {
      title: '存量',
      dataIndex: 'stock',
      key: 'stock',
      align: 'center',
    },
    {
      title: '操作',
      render: (record) => (
        <div style={{ display: 'flex' }}>
          <span
            style={{ cursor: 'pointer', color: 'blue', flexGrow: '1' }}
            onClick={() => {
              setSuppliesChangeModalTitle('入库')
              setShowSuppliesChangeModal(true)
              setSuppliesModalData(record)
            }}
          >
            入库
          </span>
          <span
            style={{ cursor: 'pointer', color: 'blue', flexGrow: '1' }}
            onClick={() => {
              setSuppliesChangeModalTitle('出库')
              setShowSuppliesChangeModal(true)
              setSuppliesModalData(record)
            }}
          >
            出库
          </span>
          <span
            style={{ cursor: 'pointer', color: 'blue', flexGrow: '1' }}
            onClick={() => {
              setSuppliesModalData(record)
              setShowLogs(true)
            }}
          >
            详情
          </span>
          <span
            style={{ cursor: 'pointer', color: 'blue', flexGrow: '1' }}
            onClick={() => {
              Modal.confirm({
                title: '确认删除?',
                onOk: () => {
                  Fetch.deleteMateriel({ id: record.Id }).then((res) => {
                    if (res.code === 0) {
                      message.success('删除成功')
                      setReFetchData(!reFetchData)
                    }
                  })
                },
              })
            }}
          >
            删除
          </span>
        </div>
      ),
      width: 200,
      align: 'center',
    },
  ]
  // 获取物料列表
  const getSuppliesList = () => {
    Fetch.getSuppliesList({
      pageIndex: currentPage,
    }).then((res) => {
      if (res.code === 0) {
        setSuppliesList(res.data.rows)
        setTotal(res.data.rowCount)
      }
    })
  }
  // 打开创建物料模态框
  const openCreateSuppliesModal = () => {
    setShowCreateSuppliesModal(true)
  }
  // 关闭创建物料模态框
  const closeCreateSuppliesModal = () => {
    setShowCreateSuppliesModal(false)
  }
  // 提交物料的新增
  const submitCreateSupplies = (data) => {
    Fetch.createSupplies(data)
      .then((res) => {
        if (res.code === 0) {
          message.success('新增物料成功')
          closeCreateSuppliesModal()
          setReFetchData(!reFetchData)
        } else {
          message.error('新增物料失败')
        }
      })
      .catch((err) => {
        console.log(err)
        message.error('请求失败')
      })
  }
  // 关闭出入库模态框
  const closeSuppliesChangeModal = () => {
    setSuppliesChangeModalTitle('')
    setShowSuppliesChangeModal(false)
    setSuppliesModalData({})
  }
  // 处理提交
  const submitChangeSupplies = (data) => {
    const formData = Object.assign(
      data,
      { type: suppliesChangeModalTitle },
      { id: suppliesModalData.Id }
    )
    Fetch.updateSuppliesList(formData)
      .then((res) => {
        if (res.code === 0) {
          message.success('更新成功')
          closeSuppliesChangeModal()
          setReFetchData(!reFetchData)
        } else {
          message.error('更新失败')
        }
      })
      .catch((err) => {
        console.log(err)
        message.error('请求失败')
      })
  }
  // 关闭日志模态框
  const closeLogs = () => {
    setSuppliesModalData({})
    setShowLogs(false)
  }
  // 处理表格的翻页
  const handleTablePageChange = (page, pageSize) => {
    setCurrentPage(page)
    setReFetchData(!reFetchData)
  }
  return (
    <div>
      <div className="toolbar" style={{ margin: '20px 0', padding: '20px 0' }}>
        <Button
          type="primary"
          style={{ float: 'right' }}
          onClick={openCreateSuppliesModal}
        >
          新建耗材种类
        </Button>
      </div>
      <div className="table">
        <Card>
          <Table
            columns={columns}
            dataSource={suppliesList}
            rowKey={(item) => item.Id}
            pagination={{
              total: total,
              pageSize: 20,
              current: 1,
              onChange: handleTablePageChange,
            }}
          />
        </Card>
      </div>
      {showCreateSuppliesModal ? (
        <CreateSuppliesModal
          visible={showCreateSuppliesModal}
          onCancel={closeCreateSuppliesModal}
          onOk={submitCreateSupplies}
        />
      ) : null}
      {showSuppliesChangeModal ? (
        <SuppliesChangeModal
          title={suppliesChangeModalTitle}
          visible={showSuppliesChangeModal}
          onCancel={closeSuppliesChangeModal}
          onOk={submitChangeSupplies}
        />
      ) : null}
      {showLogs ? (
        <SuppliesLogs
          visible={showLogs}
          data={suppliesModalData}
          onCancel={closeLogs}
        />
      ) : null}
    </div>
  )
}
export default ASD
