import React, { useState, useEffect } from 'react'
import { Modal, Form, Input, message, Select, TreeSelect } from 'antd'
import Fetch from '../../../fetch/axios'
const UserFormModal = (props) => {
  // 角色列表
  const [roleList, setRoleList] = useState([])
  // 角色类型映射表
  const [roleIdTypeMap, setRoleIdTypeMap] = useState({})
  // 地点列表
  const [addressList, setAddressList] = useState([])
  // 单位列表
  const [unitList, setUnitList] = useState([])
  // 单位列表过滤关键字
  const [unitListFilterKeyWord, setUnitListFilterKeyWord] = useState('')
  // 当前存档单位id
  const [thisSelectedUnitId, setThisSelectedUnitId] = useState('')
  // 部门列表
  const [departmentList, setDepartmentList] = useState([])
  // 选择的元素
  const [selectedValue, setSelectedValue] = useState([])
  // 当前勾选的元素的父元素id
  const [thisSelectedNodeParentId] = useState(null)
  // id到父元素id的映射
  const [parentIdMap, setParentIdMap] = useState({})
  //  显示单位选择
  const [showUnitItem, setShowUnitItem] = useState(false)
  //   显示部门选择
  const [showDepartmentItem, setShowDepartmentItem] = useState(false)
  //   显示地点选择
  const [showAddressItem, setShowAddressItem] = useState(false)
  // 回填编辑时数据
  const {
    Account = '',
    Name = '',
    Description = '',
    userRoles = [{ Id: '' }],
    Phone = '',
    Telephone = '',
    Email = '',
    userAddress = [],
    unitId = '',
    deptId = '',
    Id = '',
  } = props.data === null ? {} : props.data
  useEffect(() => {
    setSelectedValue(userAddress.map((item) => item.AddressId))
    console.log(unitId)
    setThisSelectedUnitId(unitId)
  }, [])
  // 获取角色列表
  useEffect(() => {
    Fetch.getRoleList({ gel: 0 })
      .then((res) => {
        if (res.success && res.data.rows) {
          const rows = res.data.rows;
          const tmpRoleIdTypeMap = {};
          rows.forEach((item) => {
            tmpRoleIdTypeMap[item.Id] = item.Type
          })
          setRoleIdTypeMap(tmpRoleIdTypeMap);
          setRoleList(rows)
        } else {
          message.error('角色列表获取失败')
          props.close()
        }
      })
      .catch((err) => {
        console.log(err)
        message.error('角色列表获取失败')
        props.close()
      })
  }, [])
  // 角色改变时控制其他item
  useEffect(() => {
    const roleId = props.form.getFieldValue('roleId')
    let roleType
    if (roleId) {
      roleType = roleIdTypeMap[roleId]
      if (roleType === 1) {
        setShowAddressItem(true)
        setShowUnitItem(false)
        setShowDepartmentItem(false)
      }
      if (roleType === 2) {
        setShowUnitItem(true)
        setShowDepartmentItem(false)
        setShowAddressItem(false)
      }
      if (roleType === 3) {
        setShowUnitItem(true)
        setShowDepartmentItem(true)
        setShowAddressItem(false)
      }
    }
  }, [roleIdTypeMap, props.form.getFieldValue('roleId')])
  // 获取地址树并转化为组件需要格式
  useEffect(() => {
    Fetch.getSecondTree()
      .then((res) => {
        if (res.success === true) {
          const data = res.data
          if (Array.isArray(data)) {
            const map = {}
            const list = data.map((item, index) => {
              const node = {}
              node['title'] = item.Name
              node['value'] = item.Id
              node['key'] = index
              if (Array.isArray(item.treeNode)) {
                const children = item.treeNode.map((child, subIndex) => {
                  const node = {}
                  node['title'] = child.Name
                  node['value'] = child.Id
                  node['key'] = index + '-' + subIndex
                  map[child.Id] = item.Id
                  return node
                })
                node['children'] = children
              }
              return node
            })
            setParentIdMap(map)
            setAddressList(list)
          }
        } else {
          message.error('地址列表获取失败')
          props.close()
        }
      })
      .catch((err) => {
        console.log(err)
        message.error('地址列表获取失败')
        props.close()
      })
  }, [])
  // 获取单位列表
  useEffect(() => {
    Fetch.getUnitList({ keyWord: unitListFilterKeyWord })
      .then(async (res) => {
        if (res.code === 0) {
          const baseData = res.data.rows;
          let extData = [];
          try {
            if (unitId != '' && unitListFilterKeyWord == '') {
              const filterRows = res.data.rows.filter((row) => row.Id == unitId);
              if (filterRows == null || filterRows.length <= 0) {
                const response = await Fetch.getUnitList({ unitId });
                if (response.code === 0)
                  extData = response.data.rows;
              }
            }
          } finally {
            setUnitList([...extData, ...baseData]);
          }
        } else {
          message.error('请求单位列表失败')
          props.close()
        }
      })
      .catch((err) => {
        console.log(err)
        message.error('请求单位列表失败')
        props.close()
      })
  }, [unitListFilterKeyWord])
  // 监听选择单位的id
  useEffect(() => {
    const id = props.form.getFieldValue('unitId')
    if (id !== thisSelectedUnitId) {
      setThisSelectedUnitId(id)
      if (showDepartmentItem) {
        props.form.setFieldsValue({ deptId: '' })
      }
    }
  }, [props.form.getFieldValue('unitId')])
  // 获取部门列表
  useEffect(() => {
    console.log(thisSelectedUnitId)
    Fetch.getDepartmentList({ unitId: thisSelectedUnitId })
      .then((res) => {
        if (res.code === 0) {
          setDepartmentList(res.data)
        } else {
          message.error('获取部门列表失败')
          props.close()
        }
      })
      .catch((err) => {
        console.log(err)
        message.error('获取部门列表失败')
        props.close()
      })
  }, [thisSelectedUnitId])
  const formItemLayout = {
    labelCol: {
      span: 4,
    },
    wrapperCol: {
      span: 18,
    },
  }
  const { getFieldDecorator, getFieldsValue } = props.form
  return (
    <Modal
      visible={props.show}
      title={props.title}
      onOk={() => {
        props.submit({
          ...getFieldsValue(),
          addressId: selectedValue.join(','),
          Id,
        })
      }}
      onCancel={props.close}
      confirmLoading={props.confirmLoading}
    >
      <Form {...formItemLayout}>
        {/* 账号 */}
        <Form.Item label="账号">
          {getFieldDecorator('Account', {
            rules: [{ required: true, message: '账号不能为空!' }],
            initialValue: Account,
          })(<Input placeholder="账号" />)}
        </Form.Item>
        {/* 密码，编辑时不显示 */}
        <Form.Item label="密码">
          {getFieldDecorator('Password', {
            rules: [{ required: true, message: '密码不能为空!' }],
          })(<Input type="password" placeholder="密码" />)}
        </Form.Item>
        <Form.Item label="用户名">
          {getFieldDecorator('Name', {
            rules: [{ required: true, message: '用户名不能为空!' }],
            initialValue: Name,
          })(<Input placeholder="用户名" />)}
        </Form.Item>
        <Form.Item label="描述">
          {getFieldDecorator('Description', {
            initialValue: Description,
          })(<Input placeholder="描述" />)}
        </Form.Item>
        <Form.Item label="角色类型">
          {getFieldDecorator('roleId', {
            initialValue: userRoles[0].Id,
          })(
            <Select placeholder="角色类型">
              {roleList.map((item) => (
                <Select.Option value={item.Id} key={item.Id}>
                  {item.Name}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
        {showAddressItem ? (
          <Form.Item label="所属地点">
            <TreeSelect
              treeData={addressList}
              treeCheckable
              value={selectedValue}
              onChange={(value, label) => {
                if (value.length < selectedValue.length) {
                  setSelectedValue(value)
                } else {
                  const parentIds = value.map((item) => parentIdMap[item])
                  const parentIdArr = [...new Set(parentIds)]
                  if (parentIdArr.length !== 1) {
                    message.error('不能跨层级选择')
                  } else {
                    if (
                      thisSelectedNodeParentId === null ||
                      thisSelectedNodeParentId === parentIdArr[0]
                    ) {
                      let copy = [...selectedValue]
                      const copySet = new Set(copy)
                      value.forEach((item) => {
                        if (!copySet.has(item)) {
                          copySet.add(item)
                        }
                      })
                      copy = [...copySet]
                      console.log('copy: ', copy)
                      setSelectedValue(copy)
                    }
                  }
                }
              }}
            />
          </Form.Item>
        ) : null}
        {showUnitItem ? (
          <Form.Item label="所属单位">
            {getFieldDecorator('unitId', {
              initialValue: unitId,
            })(
              <Select placeholder="所属单位" showSearch allowClear
                      filterOption={false}
                      onChange={(value) => { if (value == null) setUnitListFilterKeyWord(''); }}
                      onSearch={(value) => { setUnitListFilterKeyWord(value); }}>
                {unitList.map((item) => (
                  <Select.Option value={item.Id} key={item.Id}>
                    {item.Name}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
        ) : null}
        {showDepartmentItem ? (
          <Form.Item label="所属部门">
            {getFieldDecorator('deptId', {
              initialValue: deptId,
            })(
              <Select placeholder="所属部门">
                {departmentList.map((item) => (
                  <Select.Option value={item.Id} key={item.Id}>
                    {item.Name}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
        ) : null}
        <Form.Item label="手机号">
          {getFieldDecorator('Phone', {
            initialValue: Phone,
          })(<Input placeholder="手机号" />)}
        </Form.Item>
        <Form.Item label="座机号">
          {getFieldDecorator('Telephone', {
            initialValue: Telephone,
          })(<Input placeholder="座机号" />)}
        </Form.Item>
        <Form.Item label="邮箱">
          {getFieldDecorator('Email', {
            rules: [
              {
                pattern: '^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(.[a-zA-Z0-9_-]+)+$',
                message: '邮箱格式错误!',
              },
            ],
            initialValue: Email,
          })(<Input placeholder="邮箱" />)}
        </Form.Item>
      </Form>
    </Modal>
  )
}
export default Form.create({ name: 'user-form-modal' })(UserFormModal)
