import React from 'react'
import { Modal, Form, Upload, message, Icon } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
const Index = props => {
  const { getFieldDecorator, getFieldsValue } = props.form
  const formItemLayout = {
    labelCol: { span: 2 },
    wrapperCol: { span: 22 }
  }
  const normFile = e => {
    console.log('Upload event:', e)
    if (Array.isArray(e)) {
      return e
    }
    return e && e.fileList
  }
  return (
    <Modal
      visible={props.visible}
      title="修改请求"
      onOk={() => {
        props.onOk({
          Id: props.data.Id,
          ExpressInfoId: props.data.ExpressInfoId,
          borrowUserName: props.data.BorrowUserId,
          Status: 0,
          ...getFieldsValue()
        })
      }}
      onCancel={props.onCancel}
    >
      <Form {...formItemLayout}>
        <Form.Item label="说明">
          {getFieldDecorator('Name', {
            initialValue: props.data.Name
          })(<TextArea />)}
        </Form.Item>
        <Form.Item label="附件">
          {getFieldDecorator('filePath', {
            valuePropName: 'fileList',
            getValueFromEvent: normFile
          })(
            <Upload.Dragger
              name="file"
              action={`${window.serverIp}:${window.serverPort}/api/v1/file/upload`}
              listType="picture-card"
              multiple={true}
              withCredentials={true}
              onChange={info => {
                if (info.file.status === 'done') {
                  if (info.file.response.code !== 0) {
                    message.error(info.file.response.message)
                    info.fileList = []
                  } else {
                    message.success(info.file.response.message)
                  }
                }
              }}
            >
              <p className="ant-upload-drag-icon">
                <Icon type="inbox" />
              </p>
              <p className="ant-upload-text">点击或者拖拽文件到此上传</p>
            </Upload.Dragger>
          )}
        </Form.Item>
      </Form>
    </Modal>
  )
}
export default Form.create({ name: 'requestChange-modal' })(Index)
