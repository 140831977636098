import * as types from './action-types'
// 设置当前部门列表
export const setDepartmentList = data => {
  return {
    type: types.SET_DEPARTMENT_LIST,
    data
  }
}
// 获取当前部门列表
export const getDepartmentList = data => {
  return {
    type: types.GET_DEPARTMENT_LIST,
    data
  }
}
